export const SEND_MESSAGE = 'send_message'
export const DELETE_MESSAGE = 'delete_message'
export const EDIT_MESSAGE = 'edit_message'
export const SET_EDIT_MESSAGE = 'set_edit_message'

export const SET_MESSAGES_BY_CHAT = 'set_messages_by_chat'
export const DELETE_MESSAGES_BY_CHAT = 'delete_messages_by_chat'

export const SET_CURRENT_CHAT_ID = 'set_current_chat_id'

export const SET_CHAT_DETAIL = 'set_chat_detail'
export const SET_PARTICIPANTS_DATA_TO_CHAT_DETAIL =
  'set_participant_data_to_chat_detail'
export const DELETE_CHAT = 'delete_chat'

export const CHANGE_MESSAGE_STATUS = 'change_message`s_status'
export const SET_MESSENGER_LOADING = 'set_messenger_loading'

export const SET_NULL_TO_STATE = 'set_null_to_state'
