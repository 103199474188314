import { useEffect, useState } from 'react'
import { Col, Container, Row, PageWrapper } from '@qonsoll/react-design'
import {
  useCollectionData,
  useDocumentData
} from 'react-firebase-hooks/firestore'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { PageLoading } from '~/app/components'
import { COLLECTIONS, STATUSES } from '~/app/constants'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import {
  useBackButton,
  useResponsiveBreakpoints
} from '~/app/contexts/UI/hooks'
import {
  WizardAnswerList,
  WizardUserVotingInSameLocation
} from '~/app/domains/Wizard/components'
import { getCollectionRef, updateDocument } from '~/services/Firebase/firestore'
import ROUTE_PATHS from 'domains/allRoutePath'
import { notificationForCreatedAgreement } from 'domains/BuyingCase/helpers'
import { Alert, message } from 'antd'
import firebase from '~/services/Firebase'
import { useUserContext } from 'app/contexts/User/hooks'

function BuyingCaseShowAnswers() {
  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const history = useHistory()
  const { id } = useParams()
  const state = useUserContext()
  const { t } = useTranslations()

  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })
  const [buyingCaseData, buyingCaseLoading] = useDocumentData(
    getCollectionRef(COLLECTIONS.BUYING_CASES).doc(id)
  )
  const [wizardProcessing, wizardProcessingLoading] = useDocumentData(
    getCollectionRef(COLLECTIONS.WIZARD_PROCESSING).doc(id)
  )
  const [usersData, userDataLoading] = useCollectionData(
    buyingCaseData &&
      getCollectionRef(COLLECTIONS.USERS).where(
        'email',
        'in',
        buyingCaseData?.attendees
      )
  )
  const [votingData, votingDataLoading] = useDocumentData(
    getCollectionRef(COLLECTIONS.VOTING_DATA).doc(id)
  )
  const [form, formLoading] = useCollectionData(
    buyingCaseData &&
      getCollectionRef(COLLECTIONS.FORM).where(
        'buyingTypeId',
        '==',
        buyingCaseData?.buyingTypeId
      )
  )

  // [COMPONENT_STATE_HOOKS]
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([])
  const [questions, setQuestions] = useState()
  const [questionsLoading, setQuestionsLoading] = useState(false)
  const [createAgreementLoading, setCreateAgreementLoading] = useState(false)

  // [CLEAR_FUNCTIONS]
  const onBackToBuyingCase = () => {
    history.replace(
      generatePath(ROUTE_PATHS.BUYING_CASE_SHOW, {
        id
      })
    )
  }

  // TODO change function due to new agreement template
  const onCreateAgreement = async () => {
    setCreateAgreementLoading(true)

    const templateAnswers = await getCollectionRef(
      COLLECTIONS.AGREEMENT_TEMPLATES
    )
      .where('buyingTypeId', '==', wizardProcessing?.buyingTypeId)
      .get()
    const templateAnswersData = templateAnswers.docs.map(
      (item) => item.data()?.questions
    )[0]

    const docContent = {
      content: []
    }

    templateAnswersData.forEach((item) => {
      const chosenAnswer = wizardProcessing?.answers?.[item.id]?.value
      const legalText = item?.answers?.find(
        (current) => current?.answerOption === chosenAnswer
      )
      docContent.content.push({
        text: legalText?.legalText,
        margin: [0, 0, 0, 5]
      })
    })

    try {
      const func = firebase.functions().httpsCallable('createAgreement', {
        timeout: 0
      })
      const agreementDoc = await func({
        docName: buyingCaseData?.name,
        templateId: wizardProcessing?.buyingTypeId,
        content: docContent
      })

      const agreementResultData = agreementDoc?.data
      await notificationForCreatedAgreement(usersData, state, buyingCaseData)
      await updateDocument(COLLECTIONS.WIZARD_PROCESSING, id, {
        status: STATUSES.AGREEMENT_CREATED,
        agreementURL: agreementResultData?.sourceURL,
        agreementName: agreementResultData?.filename
      })
      message.success(t('Agreement was successfully created'))

      history.replace(
        generatePath(ROUTE_PATHS.BUYING_CASE_SHOW, {
          id
        })
      )
    } catch (err) {
      console.log(err)
      message.error(t('Error occurred during agreement document creation'))
    }
    setCreateAgreementLoading(false)
  }

  const onResetProgress = async () => {
    await updateDocument(
      COLLECTIONS.WIZARD_PROCESSING,
      id,
      { answers: null, status: STATUSES.IN_PROGRESS },
      { withoutUndef: false }
    )
    history.replace(
      generatePath(ROUTE_PATHS.WIZARD_SHOW, {
        id: form?.[0]?.id
      }),

      { wizardProcessing: id }
    )
  }
  /*
    If buyer agree with all answers and select "Yes".
   */
  const onAgreeWithAnswers = async () => {
    const votingData = await getCollectionRef(COLLECTIONS.VOTING_DATA)
      .doc(id)
      .get()

    const editedData = votingData.data().voterAnswersInfo.map((item) => {
      if (item.id === state.id) {
        item.answer = true
      }
      return item
    })
    await updateDocument(
      COLLECTIONS.VOTING_DATA,
      id,
      { voterAnswersInfo: editedData },
      { merge: true }
    )
  }
  /*
   If buyer disagree with one of answers and select "No".
  */
  const onDisagreeWithAnswers = async () => {
    const votingData = await getCollectionRef(COLLECTIONS.VOTING_DATA)
      .doc(id)
      .get()

    const editedData = votingData.data().voterAnswersInfo.map((item) => {
      if (item.id === state.id) {
        item.answer = false
      }
      return item
    })
    await updateDocument(
      COLLECTIONS.VOTING_DATA,
      id,
      { voterAnswersInfo: editedData },
      { merge: true }
    )
  }

  // [USE_EFFECTS]
  useEffect(() => {
    const unsubscribe = () => {
      form &&
        getCollectionRef(COLLECTIONS.QUESTIONS)
          .where('formId', '==', form?.[0]?.id)
          .onSnapshot((querySnapshot) => {
            setQuestionsLoading(true)
            let formattedData = []
            querySnapshot.forEach((item) => {
              formattedData.push(item.data())
            })
            setQuestions(formattedData)
            setQuestionsLoading(false)
          })
    }
    return wizardProcessing && unsubscribe && unsubscribe()
  }, [wizardProcessing, form])

  useEffect(() => {
    const editedQuestionsAndAnswers = () => {
      let questionsText = {}
      questions.forEach((item) => {
        questionsText[item?.id] = item?.title
      })
      const answersText = wizardProcessing?.answers
      let resultArray = []
      for (let qa in answersText)
        resultArray.push({
          answer: answersText?.[qa]?.value,
          question: questionsText?.[qa]
        })
      setQuestionsAndAnswers(resultArray)
    }
    questions && wizardProcessing && editedQuestionsAndAnswers()
  }, [questions, wizardProcessing])

  useEffect(() => {
    if (wizardProcessing?.status === STATUSES.IN_PROGRESS) {
      message.info(t('Progress wizard was reset'))
      history.replace(
        generatePath(ROUTE_PATHS.WIZARD_INIT, {
          id
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardProcessing?.status])
  useEffect(() => {
    if (wizardProcessing?.status === STATUSES.AGREEMENT_CREATED) {
      if (state.id !== buyingCaseData?.wasCreatedId) {
        message.success(t('Agreement was created successfully'))
      }

      history.replace(
        generatePath(ROUTE_PATHS.BUYING_CASE_SHOW, {
          id
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardProcessing?.status])

  useEffect(() => {
    if (!wizardProcessing && !wizardProcessingLoading) {
      message.info(t('Progress wizard was reset'))
      history.replace(
        generatePath(ROUTE_PATHS.BUYING_CASE_SHOW, {
          id
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardProcessing])

  const isVotingEnded =
    votingData &&
    votingData?.voterAnswersInfo?.length &&
    votingData?.voterAnswersInfo
      ?.map((item) => item?.answer)
      ?.reduce((acc, curr) => acc && curr)

  return (
    <PageLoading
      loading={
        !questionsAndAnswers ||
        questionsLoading ||
        wizardProcessingLoading ||
        createAgreementLoading ||
        buyingCaseLoading ||
        userDataLoading ||
        votingDataLoading ||
        formLoading
      }
      loadingText={createAgreementLoading && t('Creating agreement')}
      height="inherit">
      <PageWrapper
        isBottomSticky
        headingProps={{
          title: t('Survey answers'),
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}>
        <Container
          height="inherit"
          display="flex"
          style={{
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
          {!isVotingEnded && (
            <Row noGutters v={'center'} h={'center'} pb={3}>
              <Col cw={'auto'} maxWidth="fit-content" mb={2}>
                <Alert
                  message={t(
                    "You can't create agreement, because voting is not ended"
                  )}
                  type="error"
                />
              </Col>
            </Row>
          )}
          <Row noGutters h="center">
            <Col cw={[12, 8]}>
              <WizardAnswerList data={questionsAndAnswers} />
            </Col>
          </Row>
          <WizardUserVotingInSameLocation
            votingData={votingData}
            usersData={usersData}
            onCreateAgreement={onCreateAgreement}
            onResetProgress={onResetProgress}
            onAgreeWithAnswers={onAgreeWithAnswers}
            onDisagreeWithAnswers={onDisagreeWithAnswers}
            onBackToBuyingCase={onBackToBuyingCase}
          />
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default BuyingCaseShowAnswers
