import { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { message } from 'antd'
import {
  Row,
  Box,
  PageWrapper,
  Text,
  Button,
  Divider
} from '@qonsoll/react-design'
import { GoogleOutlined /*FacebookOutlined*/ } from '@ant-design/icons'
import auth, {
  // loginWithFacebook,
  loginWithGoogle
} from '~/services/Firebase/auth'
import { LoginForm } from 'domains/Session/components'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import ROUTE_PATHS from 'domains/allRoutePath'

function Login() {
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const titleSizes = useResponsiveBreakpoints({
    sm: 3,
    md: 2,
    lg: 2,
    xl: 2,
    xxl: 2
  })

  // [CLEAN_FUNCTIONS]
  const onLogin = async (values) => {
    try {
      await auth.signInWithEmailAndPassword(values.email, values.password)
    } catch (error) {
      console.log(error)
      message.error(t('Incorrect password'))
    }
  }

  const onForgotPassword = () => {
    history.push(ROUTE_PATHS.FORGOT_PASSWORD)
  }

  return (
    <PageWrapper
      alignMiddle
      headingProps={{
        title: t('Welcome back'),
        titleSize: titleSizes,
        textAlign: 'center',
        marginBottom: '24px'
      }}
      contentWidth={['100%', '100%', 400]}>
      <LoginForm onFinish={onLogin} onForgotPassword={onForgotPassword} />
      {/*<Box py={24}>*/}
      {/*  <Divider>*/}
      {/*    <Text*/}
      {/*      variant="body2"*/}
      {/*      textTransform="uppercase"*/}
      {/*      style={{ color: 'var(--divider-color)' }}>*/}
      {/*      {t('Or')}*/}
      {/*    </Text>*/}
      {/*  </Divider>*/}
      {/*</Box>*/}
      {/*<Button*/}
      {/*  mb={3}*/}
      {/*  variant="white"*/}
      {/*  icon={<GoogleOutlined />}*/}
      {/*  onClick={() => loginWithGoogle()}*/}
      {/*  block>*/}
      {/*  {t('Login with Google')}*/}
      {/*</Button>*/}
      {/*<Button*/}
      {/*  bg={theme.color.facebook}*/}
      {/*  color={theme.color.white.default}*/}
      {/*  icon={<FacebookOutlined />}*/}
      {/*  onClick={() => loginWithFacebook()}*/}
      {/*  block>*/}
      {/*  {t('Login with facebook')}*/}
      {/*</Button>*/}
      <Row h="center" mt={48}>
        <Text type="secondary">{t('Dont have account?')} </Text>
        <Link to={ROUTE_PATHS.SIGNUP}>{t('Sign up')}</Link>
      </Row>
    </PageWrapper>
  )
}

export default Login
