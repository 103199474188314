import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import { InView } from 'react-intersection-observer'
import {
  LoadingOutlined,
  WarningOutlined,
  EllipsisOutlined
} from '@ant-design/icons'
import { Row, Col, Box, Text } from '@qonsoll/react-design'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import { useMessengerContext } from '~/app/contexts/Messenger/hooks'
import { Dropdown } from '~/app/components'
import { ReadIcon } from 'domains/Chat/components/Lib'
import { useUserContext } from 'app/contexts/User/hooks'

const STATUS = {
  sending: (
    <LoadingOutlined
      style={{
        fontSize: 'var(--ql-font-size-h5)',
        color: 'var(--white-transparent-1)'
      }}
    />
  ),
  error: (
    <WarningOutlined
      style={{
        fontSize: 'var(--ql-font-size-h5)',
        color: 'var(--white-transparent-1)'
      }}
    />
  )
}

function MessageSimpleView(props) {
  const { messageData, onEditClick, onDeleteFinish, setRead } = props

  const {
    text,
    createdBy,
    createdAt,
    isEdit,
    messageId,
    status,
    chatId,
    seenBy,
    isRead
  } = messageData

  // [ADDITIONAL HOOKS]
  const state = useUserContext()
  const { t } = useTranslations()
  const { messengerState, messengerLoading } = useMessengerContext()
  // [COMPONENT_STATE_HOOKS]
  const [prevMessageData, setPrevMessageData] = useState({})
  const [isMessageHover, setIsMessageHover] = useState(false)

  // [COMPUTED_PROPERTIES]
  const currentUser = createdBy === state.id
  const { chatsData } = messengerState
  const formattedTime = moment(createdAt).format('HH:mm')
  const isLastMessageInList =
    messageId === _.last(Object.keys(chatsData?.[chatId]))

  const menu = [
    {
      name: 'edit',
      onItemClick: () =>
        onEditClick({ messageId, chatId, text, isLastMessageInList }),
      Component: (
        <Text fontSize="var(--ql-font-size-caption1)">{t('Edit')}</Text>
      )
    },
    {
      name: 'delete',
      onItemClick: () =>
        onDeleteFinish({
          isReadDeletedMessage: isRead,
          messageId,
          chatId,
          isLastMessageInList,
          prevMessageData,
          seenByCurrentMessage: seenBy
        }),
      Component: (
        <Text
          fontSize="var(--ql-font-size-caption1)"
          color="var(--ql-color-accent1)">
          {t('Delete')}
        </Text>
      )
    }
  ]

  // [USE_EFFECTS]

  useEffect(() => {
    let isComponentMounted = true

    const getPrevMessageData = () => {
      const indexCurrentMessage = _.indexOf(
        Object.keys(chatsData?.[chatId]),
        messageId
      )
      const chatMessages = Object.values(chatsData?.[chatId])

      setPrevMessageData(chatMessages?.[indexCurrentMessage - 1] || false)
    }
    isComponentMounted && isLastMessageInList && getPrevMessageData()

    return () => {
      isComponentMounted = false
    }
  }, [isLastMessageInList, chatId, chatsData, messageId])

  return (
    <Dropdown
      trigger="contextMenu"
      menuItems={menu}
      placement="bottomRight"
      disabled={!currentUser}
      style={{ minWidth: '100px', padding: 0 }}>
      <InView
        as={Row}
        onChange={(inView) =>
          inView &&
          !seenBy?.[state?.id] &&
          !currentUser &&
          !messengerLoading &&
          setRead(chatId, messageId)
        }
        borderRadius="var(--ql-border-radius-8)"
        p={2}
        display="flex"
        bg={currentUser ? 'var(--message-primary-bg)' : 'var(--message-bg)'}
        onMouseOver={() => setIsMessageHover(true)}
        onMouseLeave={() => setIsMessageHover(false)}>
        <Col display="block" px="8px" py={0}>
          <Text
            wordBreak="break-word"
            whiteSpace="pre-line"
            color={
              currentUser
                ? 'var(--message-primary-color)'
                : 'var(--message-color)'
            }
            fontSize="var(--ql-font-size-h5)">
            {text}
          </Text>

          <Row mt={2}>
            <Col>
              {isEdit && (
                <Text
                  color={
                    currentUser
                      ? 'var(--message-info-primary-color)'
                      : 'var(--message-info-color)'
                  }
                  variant="caption1">
                  {t('edited')}
                </Text>
              )}
            </Col>
            <Col cw="auto" pr={0}>
              <Text
                color={
                  currentUser
                    ? 'var(--message-info-primary-color)'
                    : 'var(--message-info-color)'
                }
                variant="caption1">
                {formattedTime}
              </Text>
            </Col>
          </Row>
        </Col>
        {currentUser && (
          <Col cw="auto" v="between" p={0}>
            <Box>
              {currentUser && isMessageHover && (
                <Dropdown
                  placement="topRight"
                  trigger="click"
                  menuItems={menu}
                  style={{ width: '100px', padding: 0 }}>
                  <EllipsisOutlined
                    style={{ color: 'var(--message-info-primary-color)' }}
                  />
                </Dropdown>
              )}
            </Box>
            {(currentUser && STATUS?.[status]) || (
              <ReadIcon
                isRead={seenBy && Object.values(seenBy).includes(true)}
                alt="status"
                color="light"
                size="var(--ql-line-height-caption1)"
              />
            )}
          </Col>
        )}
      </InView>
    </Dropdown>
  )
}

MessageSimpleView.propTypes = {
  messageData: PropTypes.shape({
    text: PropTypes.string.isRequired,
    createdBy: PropTypes.string.isRequired,
    createdAt: PropTypes.number,
    isEdit: PropTypes.bool,
    messageId: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    chatId: PropTypes.string.isRequired,
    status: PropTypes.string
  }).isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteFinish: PropTypes.func.isRequired,
  setRead: PropTypes.func.isRequired
}

export default React.memo(MessageSimpleView)
