import { useEffect, useState } from 'react'
import { useMessengerContext } from 'app/contexts/Messenger/hooks/index'
import { useUserContext } from 'app/contexts/User/hooks'

const useGetCountParticipantsInChat = (chatId) => {
  // [ADDITIONAL_HOOKS]
  const state = useUserContext()
  const { messengerState } = useMessengerContext()

  const { chatsDetail } = messengerState

  // [COMPONENT_STATE_HOOKS]
  const [countParticipantsInChat, setCountParticipantsInChat] = useState(0)
  const [currentChatParticipants, setCurrentChatParticipants] = useState({})
  const [loading, setLoading] = useState(false)

  // [USE EFFECTS]
  useEffect(() => {
    let isComponentMounted = true

    isComponentMounted &&
      chatsDetail?.[chatId]?.chatParticipants &&
      setCurrentChatParticipants(chatsDetail?.[chatId]?.chatParticipants)

    return () => {
      isComponentMounted = false
    }
  }, [chatsDetail?.[chatId]?.chatParticipants, chatId])

  useEffect(() => {
    let isComponentMounted = true

    const getCount = () => {
      setLoading(true)

      const number = Object.keys(currentChatParticipants)?.reduce(
        (sum, currentId) =>
          currentChatParticipants[currentId] !== 'isDeleted' ? sum + 1 : sum,
        0
      )
      setCountParticipantsInChat(number)

      setLoading(false)
    }

    isComponentMounted && currentChatParticipants && getCount()

    return () => {
      isComponentMounted = false
    }
  }, [chatsDetail, state, chatId, currentChatParticipants])

  return {
    countParticipants: countParticipantsInChat || 0,
    loadingCount: loading
  }
}

export default useGetCountParticipantsInChat
