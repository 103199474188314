import React from 'react'
import { Row, Col, PageWrapper, Link } from '@qonsoll/react-design'
import { message } from 'antd'
import { ForgotPasswordForm } from 'domains/Session/components'
import { LeftOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import ROUTE_PATHS from 'domains/allRoutePath'
import auth from 'services/Firebase/auth'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'

function ForgotPassword() {
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const titleSizes = useResponsiveBreakpoints({
    sm: 3,
    md: 2,
    lg: 2,
    xl: 2,
    xxl: 2
  })

  // [CLEAN FUNCTIONS]
  const onBack = () => {
    history.push(ROUTE_PATHS.LOGIN)
  }
  const onFinish = async (values) => {
    try {
      await auth.sendPasswordResetEmail(values.email)
      message.success(t('Reset password email was sent'))
      history.push(ROUTE_PATHS.LOGIN)
    } catch (err) {
      console.log(err)
      message.error(t("User with such email doesn't exist"))
    }
  }

  return (
    <PageWrapper
      alignMiddle
      headingProps={{
        title: t('Restore password'),
        titleSize: titleSizes,
        titleMarginBottom: 2,
        subTitle: t('Enter the email address associated with your account'),
        textAlign: 'center',
        marginBottom: '24px'
      }}
      contentWidth={['100%', '100%', 400]}>
      <ForgotPasswordForm onFinish={onFinish} />
      <Row noGutters v="center" h="center" mt={48}>
        <Col cw="auto" mr={1} color="var(--ql-color-accent1)">
          <LeftOutlined />
        </Col>
        <Col cw="auto">
          <Link type="link" onClick={onBack}>
            {t('Back to login')}
          </Link>
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default ForgotPassword
