import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { message } from 'antd'
import {
  Row,
  Box,
  Text,
  PageWrapper,
  Divider,
  Button
} from '@qonsoll/react-design'
import auth, { sendVerifyEmail } from '~/services/Firebase/auth'
import { updateDocument } from '~/services/Firebase/firestore'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { PageLoading } from '~/app/components/PageLoading'
import { SignupForm } from 'domains/Session/components'
import ROUTE_PATHS from 'domains/allRoutePath'
import { COLLECTIONS } from '~/app/constants'

function Signup() {
  // [COMPONENT STATE HOOKS]
  const [loading, setLoading] = useState(false)

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const titleSizes = useResponsiveBreakpoints({
    sm: 3,
    md: 2,
    lg: 2,
    xl: 2,
    xxl: 2
  })

  // [CLEAN_FUNCTIONS]
  const onFinish = async (values) => {
    setLoading(true)
    try {
      const { user } = await auth.createUserWithEmailAndPassword(
        values.email,
        values.password
      )
      await updateDocument(COLLECTIONS.USERS, user.uid, {
        id: user.uid,
        role: 'YHB',
        email: values.email,
        isActivated: false,
        themeMode: 'light'
      })
      sendVerifyEmail()
      history.push(ROUTE_PATHS.EMAIL_CONFIRM)
    } catch (err) {
      console.log(err)
      message.error(t('User with such email already exists'))
      setLoading(false)
    }
  }

  return (
    <PageLoading loading={loading} height="inherit">
      <PageWrapper
        alignMiddle
        headingProps={{
          title: t('Sign up'),
          titleSize: titleSizes,
          textAlign: 'center',
          marginBottom: '24px'
        }}
        contentWidth={['100%', '100%', 400]}>
        <SignupForm onFinish={onFinish} />
        {/*<Box py={24}>*/}
        {/*  <Divider>*/}
        {/*    <Text*/}
        {/*      variant="body2"*/}
        {/*      textTransform="uppercase"*/}
        {/*      style={{ color: 'var(--divider-color)' }}>*/}
        {/*      Or*/}
        {/*    </Text>*/}
        {/*  </Divider>*/}
        {/*</Box>*/}
        {/*<Button*/}
        {/*  variant="white"*/}
        {/*  icon={<GoogleOutlined />}*/}
        {/*  onClick={() => {*/}
        {/*    localStorage.setItem('googleSignup', true)*/}
        {/*    loginWithGoogle()*/}
        {/*  }}*/}
        {/*  block>*/}
        {/*  {t('Sign up with Google')}*/}
        {/*</Button>*/}
        <Row h="center" mt={48}>
          <Text type="secondary">{t('Already have account?')} </Text>
          <Link to={ROUTE_PATHS.LOGIN}>{t('Login')}</Link>
        </Row>
      </PageWrapper>
    </PageLoading>
  )
}

export default Signup
