import React from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  Container,
  Img,
  Text,
  Button,
  Title
} from '@qonsoll/react-design'
import {
  // HeartOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  MailOutlined
} from '@ant-design/icons'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { CustomCarousel } from 'app/components'

const config = { sm: 240, md: 320, lg: 400, xl: 400, xxl: 400 }
const mobileMap = {
  sm: true,
  md: true,
  lg: false,
  xl: false,
  xxl: false
}

function OfferAdvancedView(props) {
  const {
    description,
    phone,
    email,
    location,
    currency,
    resizedImages,
    price,
    fixedPrice,
    fixedDiscount,
    fixedDiscountPercentage,
    fixedPercentage
  } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const responsive = useResponsiveBreakpoints(config)
  const isMobile = useResponsiveBreakpoints(mobileMap)

  // [CLEAN FUNCTIONS]
  // const onLike = () => {}
  const info = (
    <Row noGutters>
      <Col cw={12} mb={3}>
        <Text whiteSpace="pre-line">{description}</Text>
      </Col>
      {price && (
        <Col
          cw={12}
          mb={3}
          style={{ alignItems: 'baseline' }}
          flexDirection="row">
          <Title level={2} mr={2}>
            {price}
          </Title>
          <Text type="secondary">{currency || t('NOK')}</Text>
        </Col>
      )}
      {fixedPrice && (
        <Col cw={12} mb={3} style={{ alignItems: 'baseline' }}>
          <Text>
            {t('Fixed price:')} {fixedPrice}
            {currency || t('NOK')}
          </Text>
        </Col>
      )}
      {fixedDiscount && (
        <Col cw={12} mb={3} style={{ alignItems: 'baseline' }}>
          <Text>
            {t('Fixed price:')} {fixedDiscount}
            {currency || t('NOK')}
          </Text>
        </Col>
      )}
      {fixedDiscountPercentage && (
        <Col cw={12} mb={3} style={{ alignItems: 'baseline' }}>
          <Text>
            {t('Fixed discount percentage:')} {fixedDiscountPercentage}%
          </Text>
        </Col>
      )}
      {fixedPercentage && (
        <Col cw={12} mb={3} style={{ alignItems: 'baseline' }}>
          <Text>
            {t('Fixed percentage:')} {fixedPercentage}%
          </Text>
        </Col>
      )}
      <Col cw={12} mb={3} flexDirection="row">
        <EnvironmentOutlined
          style={{ fontSize: 'var(--ql-font-size-h3)', width: 'fit-content' }}
        />
        <Text ml={3}>{location}</Text>
      </Col>
      <Col cw={12} mb={3} flexDirection="row">
        <MailOutlined
          style={{ fontSize: 'var(--ql-font-size-h3)', width: 'fit-content' }}
        />
        <Text ml={3}>{email}</Text>
      </Col>
      <Col cw={12} mb={4} flexDirection="row">
        <PhoneOutlined
          style={{ fontSize: 'var(--ql-font-size-h3)', width: 'fit-content' }}
        />
        <Text ml={3}>{phone}</Text>
      </Col>
    </Row>
  )
  return isMobile ? (
    <Container
      height="inherit"
      display="flex"
      style={{
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}>
      <Row noGutters mb={3}>
        <Col
          cw={12}
          minWidth={0}
          style={{ position: 'relative', height: 'fit-content' }}>
          <CustomCarousel noOuterGutters data={resizedImages}>
            {resizedImages?.map((item, index) => (
              <Img
                key={index}
                src={item}
                alt={item}
                height={responsive}
                objectFit="cover"
              />
            ))}
          </CustomCarousel>
        </Col>
      </Row>
      {info}
      {phone && (
        <Button
          type="primary"
          href={`tel:${phone}`}
          icon={<PhoneOutlined />}
          block
          mt="auto">
          {t('Make a call')}
        </Button>
      )}
    </Container>
  ) : (
    <Container
      display="flex"
      style={{
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}>
      <Row
        noOuterGutters
        mt={28}
        style={{ alignItem: 'baseline' }}
        height="100%">
        <Col cw={6} style={{ position: 'relative', height: 'fit-content' }}>
          <CustomCarousel data={resizedImages}>
            {resizedImages?.map((item, index) => (
              <Img
                key={index}
                src={item}
                alt={item}
                height={responsive}
                objectFit="cover"
              />
            ))}
          </CustomCarousel>
        </Col>
        <Col cw={6}>
          <Row noGutters height="100%" v="between">
            <Col cw={12} display={'block'}>
              {info}
            </Col>
            <Col cw={12}>
              {phone && (
                <Button
                  type="primary"
                  href={`tel:${phone}`}
                  icon={<PhoneOutlined />}
                  block
                  mt="auto">
                  {t('Make a call')}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

OfferAdvancedView.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  resizedImages: PropTypes.array.isRequired,
  price: PropTypes.number,
  fixedPrice: PropTypes.number,
  fixedDiscount: PropTypes.number,
  fixedDiscountPercentage: PropTypes.number,
  fixedPercentage: PropTypes.number
}

export default OfferAdvancedView
