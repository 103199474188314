import { TYPES } from './constants'

const messengerReducer = (state, action) => {
  /**
   * STRUCTURE OF STATE
   * state: PropTypes.shape({
   *  chatsData: PropTypes.shape({
   *    [chatId]: PropTypes.array({
   *        [messageId]: PropTypes.shape({
   *          chatId: PropTypes.string.isRequired,
   *          createdAt: PropTypes.number.isRequired,
   *          createdBy: PropTypes.string.isRequired,
   *          date: PropTypes.string.isRequired,
   *          editedAt: PropTypes.oneOfType([
   *            PropTypes.string,
   *            PropTypes.bool
   *          ]).isRequired,
   *          isRead: PropType.bool.isRequired,
   *          isEdit: PropType.bool.isRequired,
   *          messageId: PropTypes.string.isRequired,
   *          receiver: PropTypes.string.isRequired,
   *          text: PropTypes.string.isRequired,
   *          status: PropTypes.string
   *        }),
   *      }),
   *  }),
   *  currentChatId: PropTypes.string,
   *  messengerLoading: PropTypes.bool,
   *  editMessage: PropTypes.oneOfType([
   *    PropTypes.bool,
   *    PropTypes.shape({
   *      messageId: PropTypes.string.isRequired,
   *      chatId: PropTypes.string.isRequired
   *      date: PropTypes.string.isRequired,
   *      text: PropTypes.string.isRequired,
   *      isLastMessage: PropTypes.bool.isRequired
   *    })
   *  ]).isRequired
   * })
   */

  switch (action.type) {
    case TYPES.SEND_MESSAGE: {
      /**
       *  action.payload: PropTypes.shape({
       *   chatId: PropTypes.string.isRequired,
       *   createdBy: PropTypes.string.isRequired,
       *   editedAt: PropTypes.bool.isRequired,
       *   isRead: PropType.bool.isRequired,
       *   isEdit: PropType.bool.isRequired,
       *   messageId: PropTypes.string.isRequired,
       *   receiverId: PropTypes.string.isRequired,
       *   text: PropTypes.string.isRequired,
       *   status: PropTypes.string.isRequired
       *  })
       */
      const { chatId, messageId } = action?.payload
      /**
       * add data (message`s data to messenger state by chatId and messageId)
       * action.payload has all fields for new message
       */
      return {
        ...state,
        chatsData: {
          ...state?.chatsData,
          [chatId]: {
            ...state?.chatsData?.[chatId],
            [messageId]: { ...action?.payload, status: 'sending' }
          }
        },
        messengerLoading: true
      }
    }

    case TYPES.DELETE_MESSAGE: {
      /**
       *  action.payload: PropTypes.shape({
       *    messageId: PropTypes.string.isRequired,
       *    chatId: PropTypes.string.isRequired
       *  })
       */
      const { messageId, chatId } = action?.payload
      /**
       * make a copy of chatData by chatId
       */
      const newChatData = {
        ...state.chatsData[chatId]
      }
      /**
       * delete message by id from new chatData
       */
      delete newChatData?.[messageId]

      return {
        ...state,
        chatsData: {
          ...state.chatsData,
          [chatId]: newChatData
        },
        editMessage: false,
        messengerLoading: true
      }
    }

    case TYPES.EDIT_MESSAGE: {
      /**
       *  action.payload: PropTypes.shape({
       *    originMessagesData: PropTypes.shape({
       *      messageId: PropTypes.string.isRequired,
       *      chatId: PropTypes.string.isRequired
       *    }).isRequired,
       *    text: PropTypes.string.isRequired
       *   })
       */
      const { text, originMessagesData } = action?.payload

      /**
       * set new text for edited message
       */
      const { messageId, chatId } = originMessagesData

      return {
        ...state,
        chatsData: {
          ...state.chatsData,
          [chatId]: {
            ...state.chatsData[chatId],
            [messageId]: {
              ...state.chatsData[chatId][messageId],
              text,
              isEdit: true
            }
          }
        },
        editMessage: false,
        messengerLoading: true
      }
    }

    case TYPES.SET_EDIT_MESSAGE: {
      /**
       *  action.payload: PropTypes.shape({
       *    messageId: PropTypes.string.isRequired,
       *    chatId: PropTypes.string.isRequired
       *    date: PropTypes.string.isRequired,
       *    text: PropTypes.string.isRequired,
       *    isLastMessage: PropTypes.bool.isRequired
       *  })
       */
      /**
       * set edited message`s data
       */
      return { ...state, editMessage: action.payload }
    }

    case TYPES.SET_MESSAGES_BY_CHAT: {
      /**
       *  action.payload: PropTypes.shape({
       *    chatId: PropTypes.string.isRequired,
       *      data: PropTypes.array({
       *          [messageId]: PropTypes.shape({
       *          chatId: PropTypes.string.isRequired,
       *          createdAt: PropTypes.number.isRequired,
       *          createdBy: PropTypes.string.isRequired,
       *          date: PropTypes.string.isRequired,
       *          editedAt: PropTypes.oneOfType([
       *            PropTypes.string,
       *            PropTypes.bool
       *          ]).isRequired,
       *          isRead: PropType.bool.isRequired,
       *          isEdit: PropType.bool.isRequired,
       *          messageId: PropTypes.string.isRequired,
       *          receiver: PropTypes.string.isRequired,
       *          text: PropTypes.string.isRequired
       *      }),
       *  })
       */
      const { chatId, data } = action?.payload

      return {
        ...state,
        chatsData: {
          ...state.chatsData,
          [chatId]: data
        }
      }
    }

    case TYPES.SET_CURRENT_CHAT_ID: {
      /**
       *  action.payload: PropTypes.oneOfType([
       *    PropTypes.string,
       *    PropTypes.bool
       *   ]).isRequired
       */
      /**
       * set current chat id
       */
      return {
        ...state,
        currentChatId: action.payload,
        messengerLoading: true
      }
    }

    case TYPES.SET_CHAT_DETAIL: {
      const { chatId } = action?.payload
      /** action.payload: PropTypes.shape({
       *    avatarURL: PropTypes.string.isRequired,
       *    chatId: PropTypes.string.isRequired,
       *    countUnreadMessages: PropTypes.number,
       *    createdAt: PropTypes.number,
       *    createdBy: PropTypes.string,
       *    displayName: PropTypes.string.isRequired,
       *    isRead: PropType.bool,
       *    messageId: PropTypes.string,
       *    participant-ids: PropTypes.shape({
       *      [participantId]: PropTypes.string
       *      })
       *    }),
       *    participantId: PropTypes.string.isRequired,
       *    phone: PropTypes.string.isRequired,
       *    receiverId: PropTypes.string,
       *    text: PropTypes.string,
       *    userId: PropTypes.string
       *  })
       */

      return {
        ...state,
        chatsDetail: {
          ...state?.chatsDetail,
          [chatId]: {
            ...state?.chatsDetail[chatId],
            ...action?.payload
          }
        }
      }
    }

    case TYPES.SET_PARTICIPANTS_DATA_TO_CHAT_DETAIL: {
      /**
       *  action.payload: PropTypes.shape({
       *    chatId: PropTypes.string.isRequired,
       *    participantData: PropTypes.object.isRequired
       *  })
       */
      const { chatId, participantsData } = action?.payload

      return {
        ...state,
        chatsDetail: {
          ...state?.chatsDetail,
          [chatId]: {
            ...state?.chatsDetail?.[chatId],
            participantsData
          }
        }
      }
    }
    case TYPES.DELETE_CHAT: {
      /**
       *  action.payload: PropTypes.shape({
       *    chatId: PropTypes.string.isRequired
       *  })
       */
      const chatId = action?.payload

      const newChatsDetail = state?.chatsDetail
      const newChatsData = state?.chatsData

      delete newChatsDetail?.[chatId]
      delete newChatsData?.[chatId]

      return {
        ...state,
        chatsDetail: newChatsDetail,
        chatsData: newChatsData
      }
    }

    case TYPES.CHANGE_MESSAGE_STATUS: {
      /**
       *  action.payload: PropTypes.shape({
       *    status: PropTypes.string.isRequired,
       *    chatId: PropTypes.string.isRequired,
       *    messageId: PropTypes.string.isRequired,
       *  })
       */
      const { status, messageId, chatId } = action?.payload
      /**
       * set status message for display
       * an error was occurred or message was sent successfully
       */
      return {
        ...state,
        chatsData: {
          ...state.chatsData,
          [chatId]: {
            ...state.chatsData[chatId],
            [messageId]: {
              ...state.chatsData[chatId][messageId],
              status
            }
          }
        }
      }
    }

    case TYPES.SET_MESSENGER_LOADING: {
      /**
       *  action.payload: PropTypes.bool.isRequired
       */
      return {
        ...state,
        messengerLoading: action.payload
      }
    }

    case TYPES.SET_NULL_TO_STATE: {
      return {
        ...state,
        chatsData: {},
        chatsDetail: {},
        participantIds: [],
        messengerLoading: false,
        currentChatId: '',
        editMessage: false
      }
    }

    default: {
      return state
    }
  }
}

export default messengerReducer
