import React from 'react'
import { message } from 'antd'
import { Col, Container, Row, PageWrapper } from '@qonsoll/react-design'
import { ResetPasswordForm } from 'domains/Session/components'
import firebase from 'services/Firebase/init'
import auth from 'services/Firebase/auth'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useHistory, generatePath } from 'react-router-dom'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { useUserContext } from 'app/contexts/User/hooks'

function ResetPassword() {
  // [ADDITIONAL HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const history = useHistory()
  const { t } = useTranslations()
  const state = useUserContext()
  const titleSizes = useResponsiveBreakpoints({
    sm: 3,
    md: 2,
    lg: 2,
    xl: 2,
    xxl: 2
  })

  // [CLEAN FUNCTIONS]
  const reauthenticate = (currentPassword) => {
    const user = auth.currentUser
    const cred = firebase.auth.EmailAuthProvider.credential(
      state.email,
      currentPassword
    )
    return user.reauthenticateWithCredential(cred)
  }

  const onFinish = async (values) => {
    try {
      await reauthenticate(values.oldPassword)
    } catch (err) {
      console.log(err)
      message.error(t('Incorrect old password'))
      return
    }
    try {
      await auth.currentUser.updatePassword(values.newPassword)
      message.success(t('Your password was successfully changed'))
    } catch (err) {
      console.log(err)
      message.error(t('Error occurred during password change'))
    }
    history.push(generatePath(ROUTE_PATHS.USER_SHOW, { id: state.id }))
  }

  return (
    <PageWrapper
      isBottomSticky
      headingProps={{
        title: t('Reset password'),
        titleSize: titleSizes,
        titleMarginBottom: 2,
        subTitle: t(
          'Enter your current password before start entering new one'
        ),
        textAlign: 'left',
        marginBottom: '24px'
      }}
      onBack={goBack}>
      <Container height="inherit" display="grid">
        <Row noGutters h="center">
          <Col cw={[12, 10, 8, 7, 6]} display="grid">
            <ResetPasswordForm onFinish={onFinish} />
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  )
}

export default ResetPassword
