import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { Row, Col, Box, Text } from '@qonsoll/react-design'
import { StarOutlined } from '@ant-design/icons'
import { useHistory, generatePath } from 'react-router-dom'
import { AvatarWithOnline } from '~/app/components'
import { checkOnline } from 'domains/Chat/helpers'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import ROUTE_PATHS from 'domains/allRoutePath'

const ParticipantItem = (props) => {
  const { lastSeen, avatarURL, displayName, userId, isAdmin } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()

  // [COMPONENT_STATE_HOOKS]
  const [statusOnline, setStatusOnline] = useState()

  // [USE_EFFECTS]
  useEffect(() => {
    checkOnline(lastSeen, setStatusOnline, t)

    const timer = setInterval(
      () => checkOnline(lastSeen, setStatusOnline, t),
      180000
    )

    return () => clearInterval(timer)
  }, [lastSeen])

  // [CLEAN_FUNCTIONS]
  const onParticipantClick = () =>
    history.push(generatePath(ROUTE_PATHS.USER_SHOW, { id: userId }))

  return (
    <Row
      noGutters
      pb={1}
      pt={1}
      onClick={onParticipantClick}
      style={{ cursor: 'pointer' }}
      minWidth={0}>
      <Col cw="auto">
        <AvatarWithOnline
          isOnline={statusOnline === 'online'}
          avatarURL={avatarURL}
        />
      </Col>
      <Col mt={1} flexDirection="column">
        <Row>
          <Col>
            <Box
              style={{
                minWidth: 0,
                maxWidth: '150px',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}>
              <Text isEllipsis color="var(--ql-text-color-primary)">
                {displayName}
              </Text>
            </Box>
          </Col>
          {isAdmin && (
            <Col cw="auto" p={0} v="center">
              <Tooltip title={t('Admin')}>
                <StarOutlined style={{ color: 'var(--ql-color-accent1)' }} />
              </Tooltip>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <Text
              color="var(--ql-text-color-secondary)"
              fontSize="var(--ql-font-size-caption1)">
              {statusOnline}
            </Text>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

ParticipantItem.propTypes = {
  avatarURL: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  lastSeen: PropTypes.number.isRequired
}

export default ParticipantItem
