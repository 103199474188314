import { useHistory } from 'react-router-dom'
import { Button, Result } from 'antd'
import { Row, Col, Box, PageWrapper } from '@qonsoll/react-design'
import { useTranslations } from 'app/contexts/Translation/hooks'
import ROUTE_PATHS from 'domains/allRoutePath'

function NotFound() {
  // [ADDITIONAL_HOOKS]
  const history = useHistory()
  const { t } = useTranslations()

  // [CLEAN_FUNCTIONS]
  const onClickBack = () => {
    history.goBack()
  }
  const onClickHome = () => {
    history.replace(ROUTE_PATHS.BUYING_CASES_ALL)
  }

  return (
    <PageWrapper>
      <Row h="center" v="center">
        <Col cw="auto">
          <Result
            status="404"
            title="404"
            subTitle={t('Sorry, the page you visited does not exist')}
            extra={
              <Box display="flex" justifyContent="space-evenly">
                <Button type="primary" onClick={onClickBack}>
                  {t('Back')}
                </Button>
                <Button type="primary" onClick={onClickHome}>
                  {t('Home')}
                </Button>
              </Box>
            }
          />
        </Col>
      </Row>
    </PageWrapper>
  )
}

NotFound.propTypes = {}

export default NotFound
