import React from 'react'
import PropTypes from 'prop-types'
import { Button, Form } from 'antd'
import { Input } from '@qonsoll/react-design'
import { useTranslations } from '~/app/contexts/Translation/hooks'

function ForgotPasswordForm(props) {
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  return (
    <Form {...props}>
      <Form.Item
        name="email"
        rules={[
          {
            type: 'email',
            required: true,
            message: t('Please enter valid email')
          }
        ]}>
        <Input
          autoFocus
          placeholder={t('Enter your account email')}
          textAlign="center"
        />
      </Form.Item>

      <Button block htmlType="submit" type="primary">
        {t('Restore Password')}
      </Button>
    </Form>
  )
}

ForgotPasswordForm.propTypes = {
  onFinish: PropTypes.func.isRequired
}

export default ForgotPasswordForm
