import { getReferenceChild } from 'services/Firebase/database'
import { COLLECTIONS } from 'app/constants'

/**
 *
 * @param {string} chatId            chat`s id for deleting from database
 * @param {string} stateId           current user`s id
 * @param {bool}   isGroup           chat is group or not
 * @param {string} participantId     participant`s id
 * @param {array}  participantIds    participant ids
 */

const deleteChatFromDb = async ({
  chatId,
  stateId,
  isGroup,
  participantId,
  participantIds
}) => {
  /**
   * removing chatId for participant and current user
   * if chat if personal
   */
  if (!isGroup) {
    await getReferenceChild(
      `${COLLECTIONS.CHATS}/${COLLECTIONS.PARTICIPANTS}/${stateId}/${COLLECTIONS.CHATS_BY_PARTICIPANTS}/${participantId}`
    ).remove()
    await getReferenceChild(
      `${COLLECTIONS.CHATS}/${COLLECTIONS.PARTICIPANTS}/${participantId}/${COLLECTIONS.CHATS_BY_PARTICIPANTS}/${stateId}`
    ).remove()
  } else {
    /**
     * if chat is group? delete chat id for all participants
     *
     */
    const deleteGroupChatId = async (id) => {
      await getReferenceChild(
        `${COLLECTIONS.CHATS}/${COLLECTIONS.PARTICIPANTS}/${id}/${COLLECTIONS.GROUP_CHAT_IDS}/${chatId}`
      ).remove()
    }
    participantIds.forEach((id) => {
      deleteGroupChatId(id)
    })
  }
  /**
   * removing chat information (chat-details, chat-participants and last-massage-data) for chat
   */
  await getReferenceChild(
    `${COLLECTIONS.CHATS}/${COLLECTIONS.CHATS_BY_ID}/${chatId}`
  ).remove()
  /**
   * removing chat`s messages
   */
  await getReferenceChild(
    `${COLLECTIONS.CHATS}/${COLLECTIONS.MESSAGES_BY_CHAT}/${chatId}`
  ).remove()
}

export default deleteChatFromDb
