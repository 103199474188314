import { useState, useEffect, useMemo } from 'react'

/**
 * @info useDocumentData (06 Apr 2021) // CREATION DATE
 *
 * @comment useDocumentData - Hook that using to get array of objects from specific collection.
 *
 * @since 06 Apr 2021 ( v.0.0.2 ) // LAST-EDIT DATE
 *
 * @param {DocumentReference<DocumentData>}     query     					DocumentReference instance that refers to the document in
 * 																																	specified collection.
 * @param {object}															[options]
 * @param {string} 															options.idField			Name of id field of fetched document.
 *
 * @return {[object[], boolean]}
 */

const useDocumentData = (query, options = {}) => {
  // [INTERFACES]
  const { idField } = options

  // [STATE_HOOKS]
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  // [USE_EFFECTS]
  useEffect(() => {
    let isComponentMounted = true
    const unsubscribe =
      isComponentMounted &&
      query.onSnapshot((snapshot) => {
        const snapshotData = snapshot.data()
        if (idField) {
          snapshotData[idField] = snapshot.id
        }
        setData(snapshotData)
        setLoading(false)
      })
    return () => {
      isComponentMounted = false
      unsubscribe?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo(() => [data, loading], [data, loading])
}

export default useDocumentData
