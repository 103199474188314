import { useReducer } from 'react'
import { BuyingCaseContext } from '../../contexts'
import CacheReducer from '../../reducer'

function BuyingCaseCacheProvider(props) {
  const { children, ...rest } = props

  // [COMPONENT_STATE_HOOKS]
  const [state, dispatch] = useReducer(CacheReducer, {})

  return (
    <BuyingCaseContext.Provider value={{ state, dispatch }} {...rest}>
      {children}
    </BuyingCaseContext.Provider>
  )
}

export default BuyingCaseCacheProvider
