import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Avatar, Spin, Upload, message } from 'antd'
import { UserOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons'
import { AvatarLabel } from 'app/components/AvatarLabel'
import PropTypes from 'prop-types'
import { Col, Row, Box } from '@qonsoll/react-design'
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { deleteURL, storageRef } from '~/services/Firebase/storage'
import firebase from '~/services/Firebase'
import { avatarRestrictionCheck } from '~/helpers'

const STORAGE_URL = 'images/'

const sizeMap = {
  small: { buttonSize: '30px', avatarSize: 60, icon: '30px', btnIcon: '16px' },
  medium: {
    buttonSize: '50px',
    avatarSize: 100,
    icon: '40px',
    btnIcon: '20px'
  },
  large: { buttonSize: '60px', avatarSize: 140, icon: '60px', btnIcon: '24px' }
}

const CircleSpin = styled(Box)`
  & > .ant-spin-nested-loading > .ant-spin-container {
    border-radius: 50%;
  }
`

const AvatarUploader = (props) => {
  const {
    shape,
    size,
    imageUrl,
    setLoading,
    Icon = UserOutlined,
    children,
    onChange,
    value,
    ...rest
  } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  //[COMPONENT STATE HOOKS]
  const [isUploading, setIsUploading] = useState(false)

  // [CLEAN FUNCTIONS]

  const onUpload = (data) => {
    setIsUploading(true)
    setLoading?.(true)
    const { file } = data
    const imagePath = STORAGE_URL + uuidv5(file.name, uuidv4())
    const imageRef = storageRef({ path: imagePath })
    const uploadTask = imageRef.put(file)

    uploadTask.on(
      'state_changed',
      () => {},
      (error) => {
        setIsUploading(false)
        setLoading?.(false)
        switch (error.code) {
          case 'storage/unauthorized': {
            message.error(t("You don't have permissions"))
            break
          }
          default: {
            message.error(t('Error occurred during upload'))
            console.log(error)
          }
        }
      },
      async () => {
        const materialURL = await uploadTask.snapshot.ref.getDownloadURL()
        const func = await firebase
          .functions()
          .httpsCallable('getFaceFromImage', { timeout: 0 })
        const responseData = await func({
          url: materialURL,
          fileName: file.name
        })
        const faceURL = responseData.data.sourceURL
        onChange?.(faceURL)

        //delete original image from storage
        await deleteURL(materialURL).catch((error) => {
          console.log(error.message)
        })
        setIsUploading(false)
        setLoading?.(false)
      }
    )
  }

  // [USE_EFFECTS]
  useEffect(
    () => onChange(value || ''), // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <Row h="center">
      <Col cw={'auto'} display="block">
        <CircleSpin>
          <Spin spinning={isUploading} style={{ width: '100%' }}>
            <Avatar
              className={value && 'animate__animated animate__zoomIn'}
              size={sizeMap[size].avatarSize || sizeMap['middle'].avatarSize}
              shape={shape || 'circle'}
              icon={<Icon style={{ fontSize: sizeMap[size].icon }} />}
              src={value}
            />
          </Spin>
        </CircleSpin>
        <AvatarLabel size={size}>
          {!isUploading &&
            (value ? (
              <EditOutlined
                style={{ fontSize: sizeMap[size].btnIcon }}
                className="animate__animated  animate__zoomIn"
              />
            ) : (
              <PlusOutlined
                style={{ fontSize: sizeMap[size].btnIcon }}
                className="animate__animated  animate__zoomIn"
              />
            ))}
          <Upload
            customRequest={onUpload}
            beforeUpload={(data) => avatarRestrictionCheck(data, t)}
            showUploadList={false}
            accept="image/*"
            {...rest}>
            {children}
          </Upload>
        </AvatarLabel>
      </Col>
    </Row>
  )
}

export default AvatarUploader

AvatarUploader.defaultProps = {
  size: 'medium'
}

AvatarUploader.propTypes = {
  shape: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  imageUrl: PropTypes.string,
  setLoading: PropTypes.func,
  Icon: PropTypes.node,
  onChange: PropTypes.func,
  value: PropTypes.any
}
