import { TYPES } from '~/app/contexts/Messenger/constants'
import { COLLECTIONS } from 'app/constants'
import { getReferenceChild } from 'services/Firebase/database'
import firebase from '~/services/Firebase/init'

const editMessageFinish = async (
  values,
  chatId,
  messengerState,
  messengerDispatch
) => {
  const { editMessage } = messengerState
  /**
   * checking for original text
   */
  if (
    values.text !== editMessage.text &&
    values.text.split(' ').join('') !== ''
  ) {
    messengerDispatch({
      type: TYPES.EDIT_MESSAGE,
      payload: {
        text: values.text,
        originMessagesData: editMessage
      }
    })
    localStorage.setItem(chatId, '')
    /**
     * updating edited message in database
     */
    await getReferenceChild(
      `${COLLECTIONS.CHATS}/${COLLECTIONS.MESSAGES_BY_CHAT}/${editMessage.chatId}/${editMessage.messageId}`
    ).update({
      text: values.text,
      isEdit: true,
      editedAt: firebase.database.ServerValue.TIMESTAMP
    })
    if (editMessage?.isLastMessageInList) {
      /**
       * if message was the last one in list of messages
       * chat details (chat view) will be changed
       */
      await getReferenceChild(
        `${COLLECTIONS.CHATS}/${COLLECTIONS.CHATS_BY_ID}/${editMessage.chatId}/${COLLECTIONS.LAST_MESSAGE_DATA}`
      ).update({
        text: values.text
      })
      localStorage.setItem(chatId, '')
    }
  }
  messengerDispatch({ type: TYPES.SET_EDIT_MESSAGE, payload: false })
  localStorage.setItem(chatId, '')
}

export default editMessageFinish
