import styled from 'styled-components'
import PropTypes from 'prop-types'

const sizeMap = {
  small: { buttonSize: '30px', avatarSize: 60, icon: '30px', btnIcon: '16px' },
  medium: {
    buttonSize: '50px',
    avatarSize: 100,
    icon: '40px',
    btnIcon: '20px'
  },
  large: { buttonSize: '60px', avatarSize: 140, icon: '60px', btnIcon: '24px' }
}

const AvatarLabel = styled.label`
  width: ${({ size }) => sizeMap[size].width || sizeMap[size].buttonSize};
  height: ${({ size }) => sizeMap[size].height || sizeMap[size].buttonSize};
  background-color: var(--ql-color-dark);
  border: var(--ql-border-width-2) var(--ql-border-style-default)
    var(--ql-color-white-t-lighten1);
  border-radius: var(--ql-border-radius-full);
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
  color: white;
  left: 65%;
  top: -25%;
`

export default AvatarLabel

AvatarLabel.defaultProps = {
  size: 'medium'
}

AvatarLabel.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  children: PropTypes.node.isRequired
}
