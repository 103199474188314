import { message } from 'antd'
import { useEffect, useState } from 'react'
import WebViewContext from 'app/contexts/WebViewContext/context'

const WebViewProvider = (props) => {
  const [state, setState] = useState({})
  useEffect(() => {
    function handleEvent(data) {
      if (data.data?.safeAreaInsets) {
        const safeAreaInsets = data.data.safeAreaInsets
        // Config antd message component top indent in web-view app.
        setState({ safeAreaInsets })
        message.config({ top: safeAreaInsets.top })
      } else if (data.data.deviceId) {
        const deviceId = data.data.deviceId
        localStorage.setItem('DeviceId', deviceId)
      }
    }
    document.addEventListener('message', handleEvent)
    return () => document.removeEventListener('message', handleEvent)
  }, [])

  return <WebViewContext.Provider value={{ ...state, setState }} {...props} />
}

export default WebViewProvider
