import { Col, Img, Row, Text } from '@qonsoll/react-design'
import { useSessionContext } from 'app/contexts/Session/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import ROUTE_PATHS from 'domains/allRoutePath'
import { GDPRPopup } from 'domains/App/components'
import { Link } from 'react-router-dom'
import cookie from '~/assets/cookie.png'

const Gdpr = () => {
  const state = useSessionContext()
  const { t } = useTranslations()
  const getLineBreak = useResponsiveBreakpoints({
    sm: true,
    md: false,
    lg: true,
    xl: false,
    xxl: false
  })
  return (
    <GDPRPopup whenToShow={state?.email}>
      <Row noOuterGutters v="center">
        <Col cw="auto">
          <Img src={cookie} height="var(--ql-font-size-h2)" alt="cookie" />
        </Col>
        <Col>
          <Text variant="body2">
            {t('Buy2gether uses cookies to personalize content')}.{' '}
            {t(
              'By continuing of using this site, you agree with our cookie policy'
            )}
            . {getLineBreak && <br />}
            {t('Click')} <Link to={ROUTE_PATHS.GDPR_PAGE}>{t('here')}</Link>{' '}
            {t('to learn more')}.
          </Text>
        </Col>
      </Row>
    </GDPRPopup>
  )
}

export default Gdpr
