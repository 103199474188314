import PropTypes from 'prop-types'
import {
  Box,
  Col,
  Row,
  Avatar,
  Button,
  Card,
  Text
} from '@qonsoll/react-design'

import {
  CheckOutlined,
  CloseOutlined,
  QuestionOutlined
} from '@ant-design/icons'
import { Badge, List } from 'antd'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import { UserOutlined } from '@ant-design/icons'
import { useUserContext } from 'app/contexts/User/hooks'

function WizardUserVotingInSameLocation(props) {
  const {
    usersData,
    votingData,
    onCreateAgreement,
    onResetProgress,
    onAgreeWithAnswers,
    onDisagreeWithAnswers,
    onBackToBuyingCase
  } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const state = useUserContext()

  // [COMPUTED_PROPERTIES]
  const isMainUser = votingData?.mainUserInfo?.id === state.id
  const currentVotedUser = votingData?.voterAnswersInfo?.find(
    (item) => item.id === state.id
  )
  // Counter of approved answers. Need to display "Start survey" button
  const isVotingEnded =
    votingData &&
    votingData?.voterAnswersInfo?.length &&
    votingData?.voterAnswersInfo
      ?.map((item) => item?.answer)
      ?.reduce((acc, curr) => acc && curr)
  return (
    <>
      {/*  Display voting list for main user and all user that voted  */}

      <Row noGutters h="center">
        <Col cw={[12, 8]}>
          <Box
            display="flex"
            justifyContent="start"
            flex={1}
            alignItems="center"
            mt={2}
            mb={3}>
            <Text variant="h5">{t('Voting results')}</Text>
          </Box>
        </Col>
        <Col cw={[12, 8]}>
          <List
            style={{ flex: 1 }}
            dataSource={votingData?.voterAnswersInfo}
            renderItem={(votedUser, index) => (
              <Card
                key={index}
                mb={3}
                size="small"
                bordered={false}
                shadowless
                style={{ cursor: 'pointer' }}>
                <Row noGutters h="center" v="center">
                  <Col cw="auto" mr={3}>
                    <Box
                      height="fit-content"
                      border="var(--outline-width) var(--ql-border-style-default)var(--avatar-border-color)"
                      borderRadius="var(--ql-border-radius-full)">
                      <Avatar
                        m="2px"
                        icon={<UserOutlined />}
                        src={
                          usersData?.find((user) => votedUser.id === user.id)
                            ?.avatarURL
                        }
                        alt="avatar"
                      />
                    </Box>
                  </Col>
                  <Col minWidth={0}>
                    <Text isEllipsis>{`${
                      usersData?.find((user) => votedUser.id === user.id)
                        ?.firstName
                    } ${
                      usersData?.find((user) => votedUser.id === user.id)
                        ?.secondName
                    }`}</Text>
                  </Col>
                  <Col cw="auto" ml={3}>
                    <Badge
                      count={
                        votedUser?.answer === null ? (
                          <QuestionOutlined />
                        ) : votedUser?.answer ? (
                          <CheckOutlined />
                        ) : (
                          <CloseOutlined />
                        )
                      }
                      style={{
                        color:
                          typeof votedUser?.answer === 'boolean'
                            ? votedUser?.answer
                              ? '#52c41a'
                              : '#f5222d'
                            : ''
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            )}
          />
        </Col>
      </Row>
      {/*  Display main user agreement for all users that still don't answer  */}
      {!isMainUser && currentVotedUser?.answer === null && (
        <Row noGutters h="center" mt={'auto'}>
          <Col cw={[12, 8]} mb={3}>
            <Box display="flex" flex={1} justifyContent="center">
              <Text>{t('Do you agree with answers:')}</Text>
            </Box>
          </Col>
          <Col cw={[12, 8, 6]}>
            <Row style={{ flex: 1 }} noOuterGutters>
              <Col>
                <Button type="primary" block onClick={onAgreeWithAnswers}>
                  {t('Yes')}
                </Button>
              </Col>
              <Col>
                <Button type="text" block onClick={onDisagreeWithAnswers}>
                  {t('No')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {!isMainUser && currentVotedUser?.answer !== null && (
        <Row noGutters mt={'auto'}>
          <Col cw={12} mb={2}>
            <Button
              size="large"
              block
              type={'primary'}
              onClick={onBackToBuyingCase}
              disabled={isVotingEnded && isMainUser}>
              {t('Back to buying case')}
            </Button>
          </Col>
        </Row>
      )}
      {isMainUser && (
        <Row noGutters mt={3} h="center">
          <Col cw={[12, 8]} mb={2}>
            <Button
              size="large"
              block
              type={'primary'}
              onClick={onCreateAgreement}
              disabled={!isVotingEnded && isMainUser}>
              {t('Create agreement')}
            </Button>
          </Col>
          <Col cw={[12, 8]} mb={3}>
            <Button block type="text" onClick={onResetProgress}>
              {t('Reset progress')}
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}
WizardUserVotingInSameLocation.propTypes = {
  onCreateAgreement: PropTypes.func.isRequired,
  onResetProgress: PropTypes.func.isRequired,
  onAgreeWithAnswers: PropTypes.func.isRequired,
  onDisagreeWithAnswers: PropTypes.func.isRequired,
  onBackToBuyingCase: PropTypes.func.isRequired,
  usersData: PropTypes.array.isRequired,
  votingData: PropTypes.array.isRequired
}
export default WizardUserVotingInSameLocation
