import React from 'react'
import PropTypes from 'prop-types'
import { Box, Col, Row, Text } from '@qonsoll/react-design'

const DateLabel = (props) => (
  <Row noGutters mb={2}>
    <Col>
      <Box
        display="flex"
        bg="var(--date-label-bg)"
        borderRadius="var(--date-label-border-radius)"
        px={12}
        py={1}>
        <Text
          color="var(--date-label-color)"
          variant="caption1"
          fontWeight="var(--ql-font-weight-medium)"
          textTransform="uppercase">
          {props.text}
        </Text>
      </Box>
    </Col>
  </Row>
)

DateLabel.propTypes = {
  text: PropTypes.string.isRequired
}

export default DateLabel
