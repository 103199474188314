import React, { useEffect, useState } from 'react'
import { Form, Empty } from 'antd'
import {
  Container,
  Row,
  Col,
  Button,
  PageWrapper,
  Title,
  Input,
  Text
} from '@qonsoll/react-design'
import { useHistory } from 'react-router-dom'
import { PageLoading } from '~/app/components'
import {
  useBackButton,
  useResponsiveBreakpoints
} from '~/app/contexts/UI/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { ChatFormCreate } from 'domains/Chat/components'
import ROUTE_PATHS from 'domains/allRoutePath'
import { COLLECTIONS } from '~/app/constants'
import { getCollectionRef } from '~/services/Firebase/firestore'
import { useCollectionData } from '~/hooks/firestore'
import _ from 'lodash'
import { goToChat, logLastActivity } from 'domains/Chat/helpers'
import { useUserContext } from 'app/contexts/User/hooks'

function ChatCreate() {
  // [COMPONENT_STATE_HOOKS]
  const [countSelectedParticipant, setCountSelectedParticipant] = useState(0)
  const [buyersData, setBuyersData] = useState()
  const [loading, setLoading] = useState(false)
  const [sortingBuyers, setSortingBuyers] = useState()

  // [ADDITIONAL_HOOKS]
  const [form] = Form.useForm()
  const history = useHistory()
  const { t } = useTranslations()
  const state = useUserContext()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const [collectionData, loadingBuyersData] = useCollectionData(
    getCollectionRef(COLLECTIONS.USERS)
      .where('role', '==', 'YHB')
      .where('isActivated', '==', true)
      .where('isVisible', '==', true)
    // .where('locationId', '==', state?.locationId)
    // .where('buyingTypeId', '==', state?.buyingTypeId)
  )

  // [CLEAN_FUNCTIONS]
  const onFinish = async (values) => {
    const { selectedParticipantIds } = values

    const { dispatch, ...myUserData } = state
    /**
     * getting participant data from array from db
     */
    const selectedParticipantsData = selectedParticipantIds.map((id) =>
      _.find(buyersData, (data) => data.id === id)
    )

    if (selectedParticipantIds.length > 1) {
      history.push({
        pathname: ROUTE_PATHS.CHAT_GROUP_CREATE,
        state: [...selectedParticipantsData, myUserData]
      })
    } else {
      goToChat(state, selectedParticipantsData?.[0], setLoading, history)
    }
    logLastActivity(state?.id)
  }
  const onBuyerSearch = (event) => {
    const filteredBuyers = buyersData.filter(
      (item) =>
        item.id !== state.id &&
        (item.firstName
          ?.toLowerCase()
          .startsWith(event.target.value.toLowerCase()) ||
          item.secondName
            ?.toLowerCase()
            .startsWith(event.target.value.toLowerCase()))
    )
    setSortingBuyers(filteredBuyers)
  }

  // [USE_EFFECTS]
  useEffect(() => {
    let isComponentMounted = true

    isComponentMounted &&
      !loading &&
      collectionData.length &&
      setBuyersData(collectionData.filter((item) => item.id !== state.id))

    return () => {
      isComponentMounted = false
    }
  }, [collectionData, loading, state?.id])

  useEffect(() => {
    let isComponentMounted = true

    const sortingBuyersList = () => {
      setLoading(true)

      const actualParticipantTemp = collectionData.filter(
        (data) => data.id !== state?.id
      )
      setSortingBuyers(actualParticipantTemp)
      setLoading(false)
    }

    isComponentMounted && !loadingBuyersData && sortingBuyersList()

    return () => {
      isComponentMounted = false
    }
  }, [loadingBuyersData, collectionData])

  return (
    <PageLoading loading={loadingBuyersData || loading}>
      <PageWrapper
        headingProps={{
          title: t('Create chat'),
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}
        isBottomSticky>
        <Container display="grid" height="inherit">
          <Row noGutters h="center" minWidth={0}>
            <Col
              flexDirection="column"
              h="center"
              minWidth={0}
              cw={[12, 10, 8, 7, 6]}>
              <Row height="100%" noGutters>
                <Col flexDirection="column" h="between" minWidth={0}>
                  <Title
                    level={titleSizes + 1}
                    style={{ marginBottom: 'var(--margin-sm)' }}>
                    {t('Select one or more buyers')}
                  </Title>
                  <Input
                    placeholder={t('Enter name here')}
                    allowClear
                    onChange={onBuyerSearch}
                    marginBottom="var(--margin-lg)"
                  />
                  {!!sortingBuyers?.length ? (
                    <>
                      <ChatFormCreate
                        form={form}
                        buyersData={sortingBuyers}
                        onFinish={onFinish}
                        setCountSelectedParticipant={
                          setCountSelectedParticipant
                        }
                      />
                      <Button
                        mt={3}
                        onClick={() => form.submit()}
                        loading={loading}
                        block
                        type="primary"
                        disabled={!countSelectedParticipant}>
                        {countSelectedParticipant < 2
                          ? t('Start chatting')
                          : t('Create group')}
                      </Button>
                    </>
                  ) : (
                    <Empty
                      description={
                        <Text type="secondary">
                          {t('There are not any buyers for chatting')}
                        </Text>
                      }
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default ChatCreate
