import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { Col, Container, Row, PageWrapper, Button } from '@qonsoll/react-design'
import { PageLoading } from '~/app/components'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { useMessengerContext } from '~/app/contexts/Messenger/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { ChatGroupFormEdit } from 'domains/Chat/components'
import { COLLECTIONS } from '~/app/constants'
import { getReferenceChild } from '~/services/Firebase/database'

function ChatGroupEdit() {
  // [ADDITIONAL HOOKS]
  const { id } = useParams()
  const [form] = Form.useForm()
  const history = useHistory()
  const { t } = useTranslations()
  const { messengerState, messengerLoading } = useMessengerContext()
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  // [COMPUTED_PROPERTIES]
  const { chatsDetail } = messengerState

  // [COMPONENT_STATE_HOOKS]
  const [initialValues, setInitialValues] = useState()
  const [loading, setLoading] = useState(false)

  const onFinish = async (values) => {
    setLoading(true)
    const { name, avatarURL } = initialValues

    if (values.name !== name || values?.avatarURL !== avatarURL) {
      await getReferenceChild(
        `${COLLECTIONS.CHATS}/${COLLECTIONS.CHATS_BY_ID}/${id}/${COLLECTIONS.CHAT_DETAILS}`
      ).update({ name: values.name, avatarURL: values?.avatarURL })
    }

    setLoading(false)
    history.goBack()
  }

  // [USE_EFFECTS]
  useEffect(() => {
    let isComponentMounted = true
    const settingInitialValues = () => {
      setLoading(true)
      setInitialValues(chatsDetail?.[id])
      setLoading(false)
    }
    isComponentMounted && settingInitialValues()

    return () => {
      isComponentMounted = false
    }
  }, [id, chatsDetail?.[id]])

  return (
    <PageLoading loading={loading || messengerLoading || !initialValues}>
      <PageWrapper
        isBottomSticky
        headingProps={{
          title: t('Edit group chat'),
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}>
        <Container height="inherit" display="grid">
          <Row noGutters h="center" minWidth={0}>
            <Col
              flexDirection="column"
              h="center"
              minWidth={0}
              cw={[12, 10, 8, 7]}>
              <Row height="100%" width="100%" noGutters>
                <Col flexDirection="column" h="between" minWidth={0}>
                  <ChatGroupFormEdit
                    form={form}
                    initialValues={initialValues}
                    onFinish={onFinish}
                  />
                  <Button
                    mt={3}
                    block
                    htmlType="submit"
                    type="primary"
                    loading={loading}
                    onClick={form.submit}>
                    {t('Save changes')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default ChatGroupEdit
