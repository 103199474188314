import { useMessengerContext } from '~/app/contexts/Messenger/hooks'
import { useObjectVal } from 'react-firebase-hooks/database'
import { getReferenceChild } from '~/services/Firebase/database'
import { TYPES } from '~/app/contexts/Messenger/constants'
import { useEffect } from 'react'
import { COLLECTIONS } from 'app/constants'

const useGetMessages = (chatId) => {
  // [ADDITIONAL_HOOKS]
  const { messengerDispatch } = useMessengerContext()
  const [messagesData, loadingMessagesData] = useObjectVal(
    getReferenceChild(
      `${COLLECTIONS.CHATS}/${COLLECTIONS.MESSAGES_BY_CHAT}/${chatId}`
    )
  )

  // [USE EFFECTS]
  useEffect(() => {
    let isComponentMounted = true
    const setMessagesByChat = () => {
      messengerDispatch({
        type: TYPES.SET_MESSAGES_BY_CHAT,
        payload: {
          chatId,
          data: messagesData
        }
      })
    }

    isComponentMounted && !loadingMessagesData && setMessagesByChat()

    return () => {
      isComponentMounted = false
    }
  }, [messagesData, chatId, loadingMessagesData, messengerDispatch])

  return loadingMessagesData
}

export default useGetMessages
