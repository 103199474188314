export const BUYING_CASES = 'buyingCases'
export const USERS = 'users'
export const OFFERS = 'offers'
export const TRANSLATIONS = 'translations'
export const OFFER_TYPES = 'offerTypes'
export const LOCATIONS = 'locations'
export const DOCUMENTS = 'documents'
export const WIZARD_PROCESSING = 'wizardProcessing'
export const VOTING_DATA = 'votingData'
export const CHATS = 'chats'
export const CHATS_BY_ID = 'chats-by-id'
export const MESSAGES_BY_CHAT = 'messages-by-chat'
export const PARTICIPANTS = 'participants'
export const CHAT_PARTICIPANTS = 'chat-participants'
export const CHATS_BY_PARTICIPANTS = 'chats-by-participant'
export const GROUP_CHAT_IDS = 'group-chat-ids'
export const CHAT_DETAILS = 'chat-details'
export const LAST_MESSAGE_DATA = 'last-message-data'
export const PARTICIPANT_IDS = 'participant-ids'
export const FORM = 'forms'
export const BUYING_CASE_TYPES = 'buyingCaseTypes'
export const QUESTIONS = 'questions'
export const USER_DEVICES = 'userDevices'
export const NOTIFICATIONS = 'notifications'
export const AGREEMENT_TEMPLATES = 'agreementTemplates'
