import { useState, useEffect } from 'react'
import { message } from 'antd'
import { useMessengerContext } from '.'
import { COLLECTIONS } from 'app/constants'
import { getReferenceChild } from '~/services/Firebase/database'
import { TYPES } from '../constants'

const useGetChatsDetail = (state) => {
  //[COMPONENT STATE HOOKS]
  const [participantAndChatIds, setParticipantAndChatIds] = useState({})
  const [groupChatIds, setGroupChatIds] = useState([])

  // [ADDITIONAL_HOOKS]
  // const state = useUserContext()
  const { messengerDispatch } = useMessengerContext()

  // [USE_EFFECTS]
  // getting personal chatIds for current user
  useEffect(() => {
    let isComponentMounted = true

    const getParticipantAndChatIds = () => {
      const refParticipantIds = `${COLLECTIONS.CHATS}/${COLLECTIONS.PARTICIPANTS}/${state?.id}/${COLLECTIONS.CHATS_BY_PARTICIPANTS}`

      messengerDispatch({ type: TYPES.SET_MESSENGER_LOADING, payload: true })
      getReferenceChild(refParticipantIds).on('value', (snapshot) => {
        snapshot.val()
          ? setParticipantAndChatIds(snapshot.val())
          : setParticipantAndChatIds({})
      })
      messengerDispatch({ type: TYPES.SET_MESSENGER_LOADING, payload: false })
    }

    isComponentMounted && state?.id && getParticipantAndChatIds()

    return () => {
      isComponentMounted = false
    }
  }, [state?.id, messengerDispatch])

  // getting group chat for current user
  useEffect(() => {
    let isComponentMounted = true

    const getGroupChatIds = () => {
      const refGroupChatIds = `${COLLECTIONS.CHATS}/${COLLECTIONS.PARTICIPANTS}/${state?.id}/${COLLECTIONS.GROUP_CHAT_IDS}`

      getReferenceChild(refGroupChatIds).on('value', (snapshot) => {
        snapshot.val()
          ? setGroupChatIds(Object.keys(snapshot.val()))
          : setGroupChatIds([])
      })
    }

    isComponentMounted && state?.id && getGroupChatIds()

    return () => {
      isComponentMounted = false
    }
  }, [state?.id, messengerDispatch])

  // get group chat details
  useEffect(() => {
    let isComponentMounted = true

    const getGroupChatDetails = () => {
      try {
        groupChatIds.forEach((chatId) => {
          getReferenceChild(
            `${COLLECTIONS.CHATS}/${COLLECTIONS.CHATS_BY_ID}/${chatId}`
          ).on('value', (snapshot) => {
            const setChatDetail = () => {
              messengerDispatch({
                type: TYPES.SET_CHAT_DETAIL,
                payload: {
                  chatId,
                  ...snapshot.val()?.[COLLECTIONS.CHAT_DETAILS],
                  ...snapshot.val()?.[COLLECTIONS.LAST_MESSAGE_DATA],
                  chatParticipants:
                    snapshot.val()?.[COLLECTIONS.CHAT_PARTICIPANTS]
                }
              })
            }

            snapshot.val() && state?.id && setChatDetail()
          })
        })
      } catch (error) {
        message.error(error.message)
      }
    }

    isComponentMounted && groupChatIds.length && getGroupChatDetails()

    return () => {
      isComponentMounted = false
    }
  }, [groupChatIds, messengerDispatch, state?.id])

  // getParticipantsData
  useEffect(() => {
    let isComponentMounted = true

    const getParticipantsData = () => {
      messengerDispatch({ type: TYPES.SET_MESSENGER_LOADING, payload: true })

      Object.keys(participantAndChatIds).forEach((id) => {
        getReferenceChild(
          `${COLLECTIONS.CHATS}/${COLLECTIONS.PARTICIPANTS}/${id}`
        ).on('value', (snapshot) => {
          snapshot.val() &&
            messengerDispatch({
              type: TYPES.SET_CHAT_DETAIL,
              payload: {
                chatId: participantAndChatIds[id],
                ...snapshot.val()
              }
            })
        })
      })
      messengerDispatch({ type: TYPES.SET_MESSENGER_LOADING, payload: false })
    }

    isComponentMounted && state?.id && getParticipantsData()

    return () => {
      isComponentMounted = false
    }
  }, [messengerDispatch, participantAndChatIds, state?.id])

  // get personal chat details
  useEffect(() => {
    let isComponentMounted = true

    const getChatsDetail = () => {
      try {
        messengerDispatch({ type: TYPES.SET_MESSENGER_LOADING, payload: true })
        Object.values(participantAndChatIds).forEach((chatId) => {
          getReferenceChild(
            `${COLLECTIONS.CHATS}/${COLLECTIONS.CHATS_BY_ID}/${chatId}`
          ).on('value', (snapshot) => {
            const setChatDetail = () => {
              const chatParticipantIds = Object.keys(
                snapshot.val()?.[COLLECTIONS.CHAT_PARTICIPANTS]
              )

              const participantId =
                chatParticipantIds?.[0] === state?.id
                  ? chatParticipantIds?.[1]
                  : chatParticipantIds?.[0]
              messengerDispatch({
                type: TYPES.SET_CHAT_DETAIL,
                payload: {
                  chatId,
                  participantId,
                  ...snapshot.val()?.[COLLECTIONS.CHAT_DETAILS],
                  ...snapshot.val()?.[COLLECTIONS.LAST_MESSAGE_DATA],
                  chatParticipants:
                    snapshot.val()?.[COLLECTIONS.CHAT_PARTICIPANTS]
                }
              })
            }

            snapshot.val() && state?.id && setChatDetail()
          })
        })
        messengerDispatch({
          type: TYPES.SET_MESSENGER_LOADING,
          payload: false
        })
      } catch (error) {
        console.log(message.error)
      }
    }

    isComponentMounted && state?.id && getChatsDetail()

    return () => {
      isComponentMounted = false
    }
  }, [messengerDispatch, participantAndChatIds, state?.id])
}

export default useGetChatsDetail
