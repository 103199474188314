import { Modal, Form } from 'antd'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import { InputNumber } from 'app/components'

function SSNCodeModalForm(props) {
  const { visible, onFinish, onCancel } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()
  const [form] = Form.useForm()

  return (
    <Modal
      destroyOnClose
      title={t('Approve agreement signing')}
      onOk={form.submit}
      onCancel={onCancel}
      visible={visible}>
      <Form
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        layout="vertical">
        <Form.Item
          initialValue={''}
          name="code"
          label={t('SSN code')}
          rules={[
            { required: true, message: t('Please, enter ssn code') },
            { len: 11, message: t('SSN code should be 11-digits number') }
          ]}>
          <InputNumber
            placeholder={t('Enter SSN code')}
            allowClear
            maxLength={11}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default SSNCodeModalForm
