import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { useParams, useHistory } from 'react-router-dom'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { PageWrapper, Box } from '@qonsoll/react-design'
import { getCollectionRef } from '~/services/Firebase/firestore'
import useBackButton from '~/app/contexts/UI/hooks/useBackButton'
import { PageLoading } from '~/app/components'
import { OfferAdvancedView } from '~/app/domains/Offer/components'
import { COLLECTIONS } from '~/app/constants'
import ROUTE_PATHS from 'domains/allRoutePath'

function OfferShow() {
  // [ADDITIONAL_HOOKS]
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const { id } = useParams()
  const history = useHistory()
  const [offerData, loading] = useDocumentData(
    getCollectionRef(COLLECTIONS.OFFERS).doc(id)
  )

  const resizedImages = useResponsiveBreakpoints({
    sm: offerData?.images_500,
    md: offerData?.images_500,
    lg: offerData?.images_1000,
    xl: offerData?.images_1000,
    xxl: offerData?.images_1000
  })

  if (!offerData && !loading) {
    history.replace(ROUTE_PATHS[404])
  }

  return (
    <PageLoading loading={loading}>
      <PageWrapper
        isBottomSticky
        headingProps={{
          title: <Box textAlign="initial">{offerData?.title}</Box>,
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}>
        <OfferAdvancedView {...offerData} resizedImages={resizedImages} />
      </PageWrapper>
    </PageLoading>
  )
}

export default OfferShow
