import { default as AppRoutes } from './App/__constants__/routes'
import { default as BuyerRoutes } from './Buyer/__constants__/routes'
import { default as BuyingCaseRoutes } from './BuyingCase/__constants__/routes'
import { default as OfferRoutes } from './Offer/__constants__/routes'
import { default as SessionRoutes } from './Session/__constants__/routes'
import { default as UserRoutes } from './User/__constants__/routes'
import { default as Buyers } from './Buyer/__constants__/routes'
import { default as ChatRoutes } from './Chat/__constants__/routes'
import { default as WizardRoutes } from './Wizard/__constants__/routes'

const authorizedRoutes = [
  ...AppRoutes,
  ...BuyerRoutes,
  ...BuyingCaseRoutes,
  ...OfferRoutes,
  ...SessionRoutes,
  ...UserRoutes,
  ...Buyers,
  ...ChatRoutes,
  ...WizardRoutes
]

const unauthorizedRoutes = [
  ...SessionRoutes.filter((route) => !route.user)
  // ...AppRoutes.filter(({ path }) => path === ROUTE_PATHS.ACCESS_DENIED)
]

export default authorizedRoutes

export { authorizedRoutes, unauthorizedRoutes }
