import { useUserContext } from 'app/contexts/User/hooks'
import PropTypes from 'prop-types'
import { useHistory, generatePath } from 'react-router-dom'
import { Badge } from 'antd'
import {
  Row,
  Col,
  Box,
  AppHeaderWrapper,
  Img,
  Avatar,
  Button
} from '@qonsoll/react-design'
import {
  ArrowLeftOutlined,
  UserOutlined,
  MessageOutlined
} from '@ant-design/icons'
import { useUI } from 'app/contexts/UI/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useUnreadMessagesCount } from '~/app/contexts/Messenger/hooks'
import ROUTE_PATHS from 'domains/allRoutePath'

const THEME_LOGO = {
  dark: 'light',
  light: 'dark'
}

const AppHeader = (props) => {
  const { appLoading } = props
  // [ADDITIONAL_HOOKS]
  const session = useUserContext()
  const UI = useUI()
  const history = useHistory()
  const { t } = useTranslations()
  const countUnreadMessages = useUnreadMessagesCount()

  // [CLEAN_FUNCTIONS]
  const goHome = () => {
    history.push(ROUTE_PATHS.BUYING_CASES_ALL)
  }
  const goToProfile = () => {
    history.push(generatePath(ROUTE_PATHS.USER_SHOW, { id: session.id }))
  }
  const goToChats = () => {
    history.push(ROUTE_PATHS.CHATS_ALL)
  }

  return (
    <AppHeaderWrapper
      mt={UI.state?.safeAreaInsets?.top}
      py={1}
      px={3}
      minHeight={56}
      maxHeight={56}>
      {!appLoading && session?.isActivated ? (
        <Row noGutters v="center" minHeight="inherit">
          <Col>
            {UI.state.onBack && (
              <Button
                type="text"
                icon={<ArrowLeftOutlined />}
                onClick={UI.state.onBack}
              />
            )}
          </Col>
          <Col cw="auto" style={{ cursor: 'pointer' }}>
            <Img
              height="var(--avatar-size-base)"
              src={`/logo/logo-symbol-${
                THEME_LOGO[session?.themeMode || 'light']
              }.svg`}
              alt={t('Buy together logo')}
              onClick={goHome}
            />
          </Col>
          <Col
            h="center"
            v="bottom"
            style={{ cursor: 'pointer', flexDirection: 'row' }}>
            <Button
              bg="transparent"
              color="var(--dark)"
              icon={
                <Badge count={countUnreadMessages || 0} size="small">
                  <MessageOutlined
                    style={{ fontSize: 'var(--ql-font-size-h3)' }}
                  />
                </Badge>
              }
              onClick={goToChats}
            />
            <Box
              ml={2}
              height="fit-content"
              border="var(--outline-width) var(--ql-border-style-default) var(--avatar-border-color)"
              borderRadius="var(--ql-border-radius-full)"
              onClick={goToProfile}>
              <Avatar
                width={30}
                height={30}
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontSize="var(--ql-font-size-h5) !important"
                m="2px"
                icon={<UserOutlined />}
                src={session?.avatarURL}
              />
            </Box>
          </Col>
        </Row>
      ) : (
        <Row minHeight="inherit" v="center" h="center">
          <Col cw="auto">
            <Img
              height="var(--avatar-size-base)"
              src={`/logo/logo-symbol-${
                THEME_LOGO[
                  session?.themeMode || localStorage.getItem('theme') || 'light'
                ]
              }.svg`}
              alt={t('Buy together logo')}
            />
          </Col>
        </Row>
      )}
    </AppHeaderWrapper>
  )
}
AppHeader.propTypes = {
  session: PropTypes.oneOfType([PropTypes.bool, PropTypes.object])
}
export default AppHeader
