import { useState, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { Carousel } from 'antd'
import { Col, Row, Text, Button, Card, Img } from '@qonsoll/react-design'
import { HeartOutlined, HeartFilled } from '@ant-design/icons'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import styled from 'styled-components'

const config = { sm: 220, md: 120, lg: 120, xl: 150, xxl: 210 }

const StyledImage = styled(Img)`
  &&& {
    border-radius: var(--card-radius) var(--card-radius) 0 0;
  }
`

function OfferView(props) {
  const { withLike, onCardClick, title, category, images_500, images_1000 } =
    props

  const images = useResponsiveBreakpoints({
    sm: images_500,
    md: images_500,
    lg: images_1000,
    xl: images_1000,
    xxl: images_1000
  })

  // [COMPONENT STATE HOOKS]
  const [isLiked, setIsLiked] = useState(false)
  const responsive = useResponsiveBreakpoints(config)
  // [CLEAN FUNCTIONS]
  const onLike = (e) => {
    e.stopPropagation()
    setIsLiked(!isLiked)
  }

  return (
    <Card
      style={{
        cursor: 'pointer',
        width: images?.length < 2 && '-webkit-fill-available'
      }}
      onClick={onCardClick}
      mb={3}
      bordered={false}
      shadowless
      size="small"
      overflow="hidden"
      cover={
        <Row noGutters>
          <Col minWidth={0}>
            <Carousel
              appendDots={(dots) => (
                <ul>
                  {dots.map((item) =>
                    cloneElement(item, {
                      onClick: (e) => e.stopPropagation()
                    })
                  )}
                </ul>
              )}>
              {images?.map((item) => (
                <StyledImage
                  key={item}
                  src={item}
                  alt={item}
                  height={responsive}
                  objectFit="cover"
                />
              ))}
            </Carousel>
          </Col>
        </Row>
      }>
      <Row noGutters>
        <Col minWidth={0} mr={withLike && 3}>
          <Row noGutters minWidth={0}>
            <Col cw={12}>
              <Text
                variant="h5"
                fontWeight="var(--ql-font-weight-medium)"
                isEllipsis>
                {title}
              </Text>
            </Col>
            <Col cw={12}>
              <Text>{category}</Text>
            </Col>
          </Row>
        </Col>
        {withLike && (
          <Col cw="auto" mr={-3}>
            <Button
              type="text"
              icon={
                isLiked ? (
                  <HeartFilled style={{ color: 'var(--ql-color-accent1)' }} />
                ) : (
                  <HeartOutlined />
                )
              }
              onClick={onLike}
            />
          </Col>
        )}
      </Row>
    </Card>
  )
}

OfferView.propTypes = {
  withLike: PropTypes.bool,
  images: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  onCardClick: PropTypes.func.isRequired
}

export default OfferView
