import React from 'react'
import { Button, Empty } from 'antd'
import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { PlusOutlined } from '@ant-design/icons'
import { BuyingCasesList } from 'domains/BuyingCase/components'
import { useHistory } from 'react-router-dom'
import { getCollectionRef } from '~/services/Firebase/firestore'
import { COLLECTIONS } from 'app/constants'
import { useUserContext } from 'app/contexts/User/hooks'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { useBuyingCaseCache } from '~/app/contexts/Cache/hooks'
import useCache from '~/hooks/cache/useCache'
import InfiniteCachedList from '~/app/components/InfiniteCachedList'
import _ from 'lodash'

function BuyingCasesAll() {
  // [CUSTOM HOOKS]
  const state = useUserContext()

  // [ADDITIONAL_HOOKS]
  const history = useHistory()
  const { t } = useTranslations()

  const cachedBuyingCases = useBuyingCaseCache()
  useCache(
    getCollectionRef(COLLECTIONS.BUYING_CASES)
      .where('attendees', 'array-contains', state?.email)
      .orderBy('id', 'asc')
      .limit(25),
    cachedBuyingCases.state,
    cachedBuyingCases.dispatch
  )

  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  return (
    <PageWrapper
      headingProps={{
        title: t('Buying cases'),
        titleSize: titleSizes,
        marginBottom: '24px'
      }}
      action={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() =>
            history.push(ROUTE_PATHS.BUYING_CASE_CREATE, {
              isCreate: false
            })
          }
        />
      }>
      {!_.isEmpty(cachedBuyingCases?.state?.cachedCollectionData) ? (
        <InfiniteCachedList
          limit={15}
          query={getCollectionRef(COLLECTIONS.BUYING_CASES).where(
            'attendees',
            'array-contains',
            state.email
          )}
          // collectionName={COLLECTIONS.BUYING_CASES}
          cache={cachedBuyingCases.state}
          setCache={cachedBuyingCases.dispatch}
          order={{ field: 'id', type: 'asc' }}>
          {(buyingCases) => <BuyingCasesList data={buyingCases} />}
        </InfiniteCachedList>
      ) : (
        <Row height="100%">
          <Col v="center">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        </Row>
      )}
    </PageWrapper>
  )
}

export default BuyingCasesAll
