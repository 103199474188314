import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from 'antd'
import { TeamOutlined } from '@ant-design/icons'
import { AvatarUploader } from 'app/components'
import { useTranslations } from 'app/contexts/Translation/hooks'

const ChatGroupFormCreate = (props) => {
  const { buyersData, ...rest } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  return (
    <Form
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0
      }}
      requiredMark={false}
      layout="vertical"
      {...rest}>
      <Form.Item name="avatarURL">
        <AvatarUploader size="large" Icon={TeamOutlined} />
      </Form.Item>
      <Form.Item
        label={t('Name of group')}
        name="name"
        rules={[
          { required: true, message: t('Enter name please') },
          {
            max: 75,
            message: t('Name should be less than 75 character')
          }
        ]}
        style={{ width: '100%' }}>
        <Input autoFocus placeholder={t('Enter name of group')} width="100%" />
      </Form.Item>
    </Form>
  )
}

ChatGroupFormCreate.propTypes = {
  onFinish: PropTypes.func.isRequired
}

export default ChatGroupFormCreate
