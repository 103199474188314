import React from 'react'
import PropTypes from 'prop-types'
import { Box, Avatar } from '@qonsoll/react-design'
import { UserOutlined } from '@ant-design/icons'

const AvatarWithOnline = (props) => {
  const { avatarURL, isOnline, size = 42, isGroup, children } = props

  return (
    <Box
      position="relative"
      height="fit-content"
      border="var(--outline-width) var(--ql-border-style-default) var(--avatar-border-color)"
      borderRadius="var(--ql-border-radius-full)">
      <Avatar
        m="2px"
        alt="avatar"
        icon={!isGroup && <UserOutlined />}
        src={avatarURL}
        size={size}>
        {children}
      </Avatar>
      {isOnline && !isGroup && (
        <Box
          borderRadius="50%"
          position="absolute"
          bg="#32CD32"
          width={11}
          height={11}
          bottom="2%"
          right="2%"
        />
      )}
    </Box>
  )
}
AvatarWithOnline.propTypes = {
  avatarURL: PropTypes.string.isRequired,
  isOnline: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.number,
  isGroup: PropTypes.bool
}
export default AvatarWithOnline
