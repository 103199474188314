import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import { Col, Container, Row, PageWrapper } from '@qonsoll/react-design'
import { BuyingCaseEditForm } from 'domains/BuyingCase/components'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import ROUTE_PATHS from 'domains/allRoutePath'
import {
  getCollectionRef,
  getDocumentData,
  updateDocument
} from '~/services/Firebase/firestore'
import { COLLECTIONS } from 'app/constants'
import { PageLoading } from 'app/components'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import _ from 'lodash'
import {
  useCollectionData,
  useDocumentData
} from 'react-firebase-hooks/firestore'

function BuyingCaseEdit() {
  // [ADDITIONAL HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const { id } = useParams()
  const history = useHistory()
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })
  const [buyingCase, loading] = useDocumentData(
    getCollectionRef(COLLECTIONS.BUYING_CASES).doc(id)
  )
  const [buyingCaseTypes, loadingBuyingCaseTypes] = useCollectionData(
    getCollectionRef(COLLECTIONS.BUYING_CASE_TYPES)
  )
  const [wizardProcessing, loadingWizardProcessing] = useCollectionData(
    buyingCase &&
      getCollectionRef(COLLECTIONS.WIZARD_PROCESSING).where(
        'id',
        '==',
        buyingCase?.id
      )
  )
  //[STATE HOOKS]
  const [loadingForm, setLoadingForm] = useState(false)
  const [buyingTypeId, setBuyingTypeId] = useState({})

  // [USE_EFFECTS]
  useEffect(() => {
    let isComponentMount = true
    setLoadingForm(true)
    const fetchData = async () => {
      // Fetch request
      const typeBuyingCase = await getDocumentData(
        COLLECTIONS.BUYING_CASE_TYPES,
        buyingCase?.buyingTypeId
      )
      setBuyingTypeId(typeBuyingCase)
      setLoadingForm(false)
    }

    isComponentMount && fetchData()

    return () => {
      isComponentMount = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // [CLEAN FUNCTIONS]
  const onFinish = async (values, type) => {
    setLoadingForm(true)
    try {
      await updateDocument(COLLECTIONS.BUYING_CASES, id, {
        ...values,
        description: values.description?.trim(),
        buyingTypeId: type?.id,
        links: _.without(values?.links, undefined)
      })
      const wizardProcessing = await getDocumentData(
        COLLECTIONS.WIZARD_PROCESSING,
        id
      )
      wizardProcessing !== undefined &&
        (await updateDocument(COLLECTIONS.WIZARD_PROCESSING, id, {
          buyingTypeId: type.id
        }))
      const toBuyingCaseShow = generatePath(ROUTE_PATHS.BUYING_CASE_SHOW, {
        id
      })
      message.success(t(`Buying case was edited`))
      history.push(toBuyingCaseShow)
    } catch (error) {
      console.log(error)
      message.error(t(`Can't create Buying case`))
    }
    setLoadingForm(false)
  }
  return (
    <PageLoading
      loading={
        loading ||
        loadingForm ||
        loadingBuyingCaseTypes ||
        loadingWizardProcessing
      }>
      <PageWrapper
        isBottomSticky
        headingProps={{
          title: t('Edit buying case'),
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}>
        <Container height="inherit" display="grid">
          <Row noGutters h="center">
            <Col cw={[12, 10, 8, 7, 6]} display="grid">
              <BuyingCaseEditForm
                wizardProcessing={wizardProcessing}
                onFinish={onFinish}
                initialValues={buyingCase}
                buyingCaseTypes={buyingCaseTypes}
                initialType={buyingTypeId}
              />
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default BuyingCaseEdit
