import { useEffect, useState } from 'react'
import { useMessengerContext } from 'app/contexts/Messenger/hooks/index'
import _ from 'lodash'
import { useUserContext } from 'app/contexts/User/hooks'

const useUnreadMessagesCount = () => {
  // [ADDITIONAL_HOOKS]
  const state = useUserContext()
  const { messengerState } = useMessengerContext()

  // [COMPONENT_STATE_HOOKS]
  const [countUnreadMessages, setCountUnreadMessages] = useState(0)

  // [USE EFFECTS]
  useEffect(() => {
    let isComponentMounted = true

    isComponentMounted &&
      messengerState?.chatsDetail &&
      setCountUnreadMessages(
        _.sumBy(
          Object.values(messengerState?.chatsDetail),
          (chat) => chat?.chatParticipants?.[state?.id]
        )
      )

    return () => {
      isComponentMounted = false
    }
  }, [messengerState?.chatsDetail, state])
  return countUnreadMessages || 0
}

export default useUnreadMessagesCount
