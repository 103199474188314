import React from 'react'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { Col, Row } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { Alert } from 'antd'
import _ from 'lodash'
import { STATUSES } from 'app/constants'

function BuyingCaseShowStatuses(props) {
  const {
    agreementTemplate,
    buyingCaseData,
    form,
    usersData,
    wizardProcessingData
  } = props
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  return (
    <Row noGutters>
      {agreementTemplate?.length < 1 && (
        <Col cw={'auto'} mr={3} maxWidth="fit-content" mb={3}>
          <Alert
            message={`${t(
              'No corresponding agreement template for your buying case type'
            )}. ${t('Contact to administrator or wait')}`}
            type="warning"
          />
        </Col>
      )}
      {buyingCaseData?.attendees?.length <= 1 && (
        <Col cw={'auto'} mr={3} maxWidth="fit-content" mb={3}>
          <Alert
            message={t('To start wizard should be at least 2 buyers')}
            type="error"
          />
        </Col>
      )}
      {form?.length < 1 && (
        <Col cw={'auto'} mr={3} maxWidth="fit-content" mb={3}>
          <Alert
            message={`${t(
              'No corresponding wizard for your buying case type'
            )}. ${t('Contact to administrator or wait')}`}
            type="error"
          />
        </Col>
      )}
      {usersData?.length !== buyingCaseData?.attendees?.length && (
        <Col cw={'auto'} mr={3} maxWidth="fit-content" mb={3}>
          <Alert
            type="error"
            message={`${_.xor(
              usersData?.map(({ email }) => email),
              buyingCaseData?.attendees
            )}
            - ${t("haven't activated yet")}.`}
          />
        </Col>
      )}
      {buyingCaseData?.agreementId?.length !== 1 && (
        <Col cw={'auto'} mr={3} maxWidth="fit-content" mb={3}>
          <Alert message={t('Agreement was signed')} type="success" />
        </Col>
      )}
      {wizardProcessingData?.status === STATUSES.AGREEMENT_CREATED && (
        <Col cw={'auto'} mr={3} maxWidth="fit-content" mb={3}>
          <Alert message={t('Agreement was created')} type="success" />
        </Col>
      )}
      {wizardProcessingData?.isSameLocation === true &&
        wizardProcessingData?.status !== STATUSES.AGREEMENT_CREATED && (
          <Col cw={'auto'} mr={3} mb={3} maxWidth="fit-content">
            <Alert message={t('Users in same location')} type="warning" />
          </Col>
        )}
      {wizardProcessingData?.isSameLocation === false &&
        wizardProcessingData?.status !== STATUSES.AGREEMENT_CREATED && (
          <Col cw={'auto'} mr={3} mb={2} maxWidth="fit-content">
            <Alert message={t('Users in different location')} type="warning" />
          </Col>
        )}
      {wizardProcessingData?.status === STATUSES.IN_PROGRESS && (
        <>
          <Col cw={'auto'} mr={3} mb={2} maxWidth="fit-content">
            <Alert message={t('Wizard is in progress')} type="info" />
          </Col>
          {wizardProcessingData?.answers === null && (
            <Col cw={'auto'} mr={3} mb={2} maxWidth="fit-content">
              <Alert message={t('There are no answers yet')} type="info" />
            </Col>
          )}
        </>
      )}
    </Row>
  )
}

BuyingCaseShowStatuses.propTypes = {
  buyingCaseData: PropTypes.array.isRequired,
  form: PropTypes.array.isRequired,
  usersData: PropTypes.array.isRequired,
  agreementTemplate: PropTypes.array.isRequired,
  wizardProcessingData: PropTypes.object.isRequired
}

export default BuyingCaseShowStatuses
