import { useUserContext } from 'app/contexts/User/hooks'
import { useState, useEffect } from 'react'
import { message, Switch } from 'antd'
import { updateDocument } from '~/services/Firebase/firestore'
import { COLLECTIONS } from '~/app/constants'

const THEMES = { true: 'dark', false: 'light' }

const ThemeSwitch = () => {
  // [ADDITIONAL_HOOKS]
  const state = useUserContext()

  //[COMPONENT STATE HOOKS]
  const [defaultChecked, setDefaultChecked] = useState()

  // [CLEAN FUNCTIONS]
  const setTheme = (theme) => {
    try {
      updateDocument(COLLECTIONS.USERS, state.id, {
        themeMode: theme
      })
    } catch (error) {
      message.error(error.message)
    }
  }

  useEffect(() => {
    setDefaultChecked(THEMES['true'] === state?.themeMode)
  }, [state?.themeMode])

  return (
    <Switch
      checked={defaultChecked}
      onChange={(value) => setTheme(THEMES[value])}
    />
  )
}

export default ThemeSwitch
