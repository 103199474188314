import { message } from 'antd'
import { COLLECTIONS } from '~/app/constants'
import firebase from '~/services/Firebase/init'
import { getReferenceChild } from 'services/Firebase/database'
import {
  addGroupIdToParticipantData,
  createParticipantData
} from 'domains/Chat/helpers'
import { generatePath } from 'react-router-dom'
import ROUTE_PATHS from 'domains/allRoutePath'

const createGroupChat = async (
  values,
  setLoading,
  state,
  history,
  location
) => {
  setLoading(true)
  try {
    const refCurrentChat = `${COLLECTIONS.CHATS}/${COLLECTIONS.CHATS_BY_ID}`
    const chatId = await getReferenceChild(`${refCurrentChat}`).push().key
    const participantIds = {}

    /**
     * location.state has array of participants
     */

    location?.state?.forEach((data) => {
      if (!data?.isParticipant) {
        /**
         *  if participant has not participant data, add it to collection
         */
        createParticipantData(data, chatId, true)
      } else {
        /**
         * if participant is added to collection with participant data
         * just need add chat id to his data
         */
        addGroupIdToParticipantData(data?.id, chatId)
      }
      /**
       * variable for unread messages
       */
      participantIds[data?.id] = 0
    })
    /**
     * creating children, adding chat-details, chat-participants, last-message-data
     */
    await getReferenceChild(
      `${COLLECTIONS.CHATS}/${COLLECTIONS.CHATS_BY_ID}/${chatId}`
    ).update({
      [COLLECTIONS.CHAT_DETAILS]: {
        name: values.name,
        avatarURL: values?.avatarURL || ' ',
        isGroup: true,
        chatCreatedBy: state?.id,
        chatId
      },
      [COLLECTIONS.CHAT_PARTICIPANTS]: participantIds,
      [COLLECTIONS.LAST_MESSAGE_DATA]: {
        text: 'new group',
        createdAt: firebase.database.ServerValue.TIMESTAMP
      }
    })

    history.push(generatePath(ROUTE_PATHS.CHAT_SHOW, { id: chatId }))
  } catch (error) {
    message.error(error.message)
  }
  setLoading(false)
}

export default createGroupChat
