import {
  Box,
  Col,
  Row,
  Avatar,
  Button,
  Card,
  Text
} from '@qonsoll/react-design'
import { CheckOutlined, CloseOutlined, UserOutlined } from '@ant-design/icons'
import { Badge, List } from 'antd'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import { useEffect } from 'react'
import { useUserContext } from 'app/contexts/User/hooks'

function WizardMainUserVoting(props) {
  const {
    votedData,
    usersData,
    onYesClick,
    onNoClick,
    onSurveyStart,
    setHeader
  } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const state = useUserContext()

  // [USE_EFFECTS]
  useEffect(
    () => {
      setHeader(t('Choose main user'))
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  // [COMPUTED_PROPERTIES]
  const currentVotedUser = votedData?.voterUsersInfo?.find(
    (item) => item.id === state.id
  )
  const isMainUser = votedData?.mainUserInfo?.id === state.id

  // Counter of approved answers. Need to display "Start survey" button
  const isVotingEnded =
    votedData &&
    votedData?.voterUsersInfo?.length &&
    votedData?.voterUsersInfo
      ?.map((item) => item?.agreeWithMainUser?.answer)
      ?.reduce((acc, curr) => acc && curr)
  return (
    <>
      {/*  Display voting list for main user and all user that voted  */}
      {(isMainUser ||
        (currentVotedUser?.wantBeMainUser?.voted &&
          currentVotedUser?.agreeWithMainUser?.voted)) && (
        <>
          <Box justifyContent="center" alignItems="center" mt={5}>
            <List
              dataSource={votedData?.voterUsersInfo}
              renderItem={(votedUser, index) => (
                <Card
                  key={index}
                  mb={3}
                  size="small"
                  bordered={false}
                  shadowless
                  style={{ cursor: 'pointer' }}>
                  <Row noGutters h="center" v="center">
                    <Col cw="auto" mr={3}>
                      <Box
                        height="fit-content"
                        border="var(--outline-width) var(--ql-border-style-default)var(--avatar-border-color)"
                        borderRadius="var(--ql-border-radius-full)">
                        <Avatar
                          m="2px"
                          icon={<UserOutlined />}
                          src={
                            usersData?.find((user) => votedUser.id === user.id)
                              ?.avatarURL
                          }
                          alt="avatar"
                        />
                      </Box>
                    </Col>
                    <Col minWidth={0}>
                      <Text isEllipsis>{`${
                        usersData?.find((user) => votedUser.id === user.id)
                          ?.firstName
                      } ${
                        usersData?.find((user) => votedUser.id === user.id)
                          ?.secondName
                      }`}</Text>
                    </Col>
                    <Col cw="auto" ml={3}>
                      <Badge
                        count={
                          votedUser?.agreeWithMainUser?.answer ? (
                            <CheckOutlined />
                          ) : (
                            <CloseOutlined />
                          )
                        }
                        style={{
                          color: votedUser?.agreeWithMainUser?.answer
                            ? '#52c41a'
                            : '#f5222d'
                        }}
                      />
                    </Col>
                  </Row>
                </Card>
              )}
            />
          </Box>
        </>
      )}
      {/*  Display main user agreement for all users that still don't answer  */}
      {isMainUser ||
        (!currentVotedUser?.wantBeMainUser?.answer &&
          !currentVotedUser?.agreeWithMainUser?.answer && (
            <Row noGutters v="center" h="center">
              <Col cw={12}>
                <Box display="flex" flexDirection="column" flex={1}>
                  <Box display="flex" flex={1} justifyContent="center" mb={3}>
                    <Text>
                      {votedData?.mainUserInfo?.name
                        ? `${t('Do you agree to choose as main user:')} ${
                            votedData?.mainUserInfo?.name
                          }`
                        : t(
                            'Please wait, until someone will want to be main user in wizard processing'
                          )}
                    </Text>
                  </Box>
                  <Box display="flex" flex={1} justifyContent="center">
                    <Col cw="auto">
                      <Button
                        type="primary"
                        onClick={onYesClick}
                        disabled={!votedData?.mainUserInfo?.name}>
                        {t('Yes')}
                      </Button>
                    </Col>
                    <Col cw="auto">
                      <Button onClick={onNoClick}>{t('No')}</Button>
                    </Col>
                  </Box>
                </Box>
              </Col>
            </Row>
          ))}
      {isVotingEnded && isMainUser && (
        <Box display="flex" alignSelf="flex-end" flex={1}>
          <Button size="large" block type={'primary'} onClick={onSurveyStart}>
            {t('Start survey')}
          </Button>
        </Box>
      )}
    </>
  )
}

export default WizardMainUserVoting
