import { useState, useEffect } from 'react'
import { Empty } from 'antd'
import { Row, Col, PageWrapper, Input } from '@qonsoll/react-design'
import { getCollectionRef } from '~/services/Firebase/firestore'
import { useCollectionData } from '~/hooks/firestore'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { BuyersList } from 'app/domains/Buyer/components'
import { PageLoading } from '~/app/components'
import { COLLECTIONS } from '~/app/constants'
import { useHistory, generatePath } from 'react-router-dom'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useUserContext } from 'app/contexts/User/hooks'

function BuyersAll() {
  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const history = useHistory()
  const state = useUserContext()
  const [collectionData, loading] = useCollectionData(
    getCollectionRef(COLLECTIONS.USERS)
      .where('role', '==', 'YHB')
      .where('isActivated', '==', true)
      .where('isVisible', '==', true)
      .where('locationId', '==', state?.locationId)
      .where('buyingTypeId', '==', state?.buyingTypeId)
  )
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  // [COMPONENT STATE HOOKS]
  const [buyersData, setBuyersData] = useState([])

  // [CLEAN FUNCTIONS]
  const onBuyerSearch = (event) => {
    const filteredBuyers = collectionData.filter(
      (item) =>
        item.id !== state.id &&
        (item.firstName
          .toLowerCase()
          .startsWith(event.target.value.toLowerCase()) ||
          item.secondName
            .toLowerCase()
            .startsWith(event.target.value.toLowerCase()))
    )
    setBuyersData(filteredBuyers)
  }
  const onBuyerClick = (id) =>
    history.push(generatePath(ROUTE_PATHS.USER_SHOW, { id }))

  // [USE EFFECTS]
  useEffect(() => {
    setBuyersData(collectionData.filter((item) => item.id !== state.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionData])

  return (
    <PageLoading loading={loading} height="inherit">
      <PageWrapper
        headingProps={{
          title: t('Buyers'),
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}
        isBottomSticky>
        {buyersData?.length ? (
          <Row noGutters>
            <Col mb="24px">
              <Input
                placeholder={t('Enter name here')}
                allowClear
                onChange={onBuyerSearch}
              />
            </Col>
            <Col minWidth={0} cw={12}>
              <BuyersList data={buyersData} onBuyerClick={onBuyerClick} />
            </Col>
          </Row>
        ) : (
          <Row height="100%">
            <Col v="center">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Col>
          </Row>
        )}
      </PageWrapper>
    </PageLoading>
  )
}

export default BuyersAll
