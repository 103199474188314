import { Input } from 'antd'

function InputNumber(props) {
  const { onChange, ...rest } = props

  const onChangeWithFilterOnlyNumber = (e) => {
    const { value } = e.target
    const reg = /^-?\d*(\\d*)?$/
    if (!isNaN(value) && reg.test(value)) {
      onChange(value)
    }
  }

  return <Input onChange={onChangeWithFilterOnlyNumber} {...rest} />
}
export default InputNumber
