const theme = {}

theme.customBreakpointsObject = {
  sm: 0,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600
}

export default theme
