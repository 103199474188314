import React, { useRef } from 'react'
import { Form, message } from 'antd'
import { Row, Col, Text } from '@qonsoll/react-design'
import { Spinner } from '~/app/components'
import { MessageForm, MessagesList } from 'app/domains/Chat/components'
import { editMessageFinish, onEditClick } from 'domains/Chat/helpers'
import {
  useGetMessages,
  useMessengerContext
} from '~/app/contexts/Messenger/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { TYPES } from '~/app/contexts/Messenger/constants'
import { getReferenceChild } from 'services/Firebase/database'
import { COLLECTIONS } from 'app/constants'
import firebase from '~/services/Firebase/init'
import { createDocument } from 'services/Firebase/firestore'
import moment from 'moment'
import logLastActivity from '../../helpers/logLastActivity'
import { useUserContext } from 'app/contexts/User/hooks'

function PersonalChat(props) {
  const { currentChatDetail } = props

  const { chatId, participantId } = currentChatDetail

  // [ADDITIONAL_HOOKS]
  const messagesLoading = useGetMessages(chatId)
  const [form] = Form.useForm()
  const inputRef = useRef(null)
  const { t } = useTranslations()
  const state = useUserContext()
  const { messengerState, messengerDispatch, messengerLoading } =
    useMessengerContext()

  // [COMPUTED_PROPERTIES]
  const { chatsData } = messengerState

  // [CLEAN_FUNCTIONS]
  const setRead = async (chatId, messageId) => {
    const chatByIdRef = `${COLLECTIONS.CHATS}/${COLLECTIONS.CHATS_BY_ID}/${chatId}`

    await getReferenceChild(
      `${COLLECTIONS.CHATS}/${COLLECTIONS.MESSAGES_BY_CHAT}/${chatId}/${messageId}`
    ).update({
      isRead: true,
      seenBy: { [state?.id]: true }
    })
    await getReferenceChild(
      `${chatByIdRef}/${COLLECTIONS.CHAT_PARTICIPANTS}`
    ).update({ [state?.id]: 0 })
    await getReferenceChild(
      `${chatByIdRef}/${COLLECTIONS.LAST_MESSAGE_DATA}`
    ).update({ isRead: true })
    await logLastActivity(state?.id)
  }
  const onDeleteFinish = async (messageData) => {
    await messengerDispatch({
      type: TYPES.DELETE_MESSAGE,
      payload: messageData
    })

    const {
      messageId,
      chatId,
      isReadDeletedMessage,
      isLastMessageInList,
      prevMessageData
    } = messageData

    try {
      /**
       * deleting message by id from collection
       */

      getReferenceChild(
        `${COLLECTIONS.CHATS}/${COLLECTIONS.MESSAGES_BY_CHAT}/${chatId}/${messageId}`
      ).remove()
      /**
       * if the deleted message was last message in list of messages
       * chat view and chat detail will be updated
       */
      if (isLastMessageInList) {
        /**
         *  if message is last in list
         *  message has maximum index
         */
        if (prevMessageData) {
          /**
           *  if message is no first element in array
           */
          await getReferenceChild(
            `${COLLECTIONS.CHATS}/${COLLECTIONS.CHATS_BY_ID}/${chatId}/${COLLECTIONS.LAST_MESSAGE_DATA}`
          ).update({
            createdAt: prevMessageData?.createdAt,
            createdBy: prevMessageData?.createdBy,
            isRead: prevMessageData?.isRead,
            messageId: prevMessageData?.messageId,
            text: prevMessageData?.text
          })
          message.success(t('Message was deleted successfully'))
        }
        if (
          !prevMessageData &&
          Object.keys(chatsData?.[chatId])[0] === messageId &&
          Object.keys(chatsData?.[chatId]).length < 2
        ) {
          /**
           * if message is last in list (list has only it message)
           */
          await getReferenceChild(
            `${COLLECTIONS.CHATS}/${COLLECTIONS.CHATS_BY_ID}/${chatId}/${COLLECTIONS.LAST_MESSAGE_DATA}`
          ).update({
            text: 'no messages'
          })
          message.success(t('Message was deleted successfully'))
        }
      }
      if (!isReadDeletedMessage) {
        await getReferenceChild(
          `${COLLECTIONS.CHATS}/${COLLECTIONS.CHATS_BY_ID}/${chatId}/${COLLECTIONS.CHAT_PARTICIPANTS}`
        ).update({
          [participantId]:
            currentChatDetail?.chatParticipants?.[participantId] - 1
        })
      }
    } catch (error) {
      message.error(error.message)
    }
  }
  const onFinishSend = async (values) => {
    if (values?.text && values?.text.split(' ').join('') !== '') {
      localStorage.setItem(chatId, '')

      /**
       * get message id
       */
      const refCurrentChat = `${COLLECTIONS.CHATS}/${COLLECTIONS.MESSAGES_BY_CHAT}/${chatId}`
      const messageId = getReferenceChild(`${refCurrentChat}`).push().key
      const lastMessageData = {
        chatId: chatId,
        messageId,
        text: values.text,
        createdBy: state.id,
        date: moment().format('DD-MM-YYYY')
      }
      /**
       * set message to chat context
       */
      messengerDispatch({
        type: TYPES.SEND_MESSAGE,
        payload: lastMessageData
      })

      try {
        /**
         * set online for current user
         */
        await logLastActivity(state?.id)

        /**
         * set chat-details
         */
        await getReferenceChild(
          `${COLLECTIONS.CHATS}/${COLLECTIONS.CHATS_BY_ID}/${chatId}/${COLLECTIONS.LAST_MESSAGE_DATA}`
        ).update({
          ...lastMessageData,
          createdAt: firebase.database.ServerValue.TIMESTAMP,
          isRead: false
        })

        await getReferenceChild(
          `${COLLECTIONS.CHATS}/${COLLECTIONS.CHATS_BY_ID}/${chatId}/${COLLECTIONS.CHAT_PARTICIPANTS}`
        ).update({
          [currentChatDetail?.participantId]:
            currentChatDetail?.chatParticipants?.[participantId] + 1 || 1
        })

        /**
         * set message to collection messages-by-chat
         */
        await getReferenceChild(`${refCurrentChat}/${messageId}`).set({
          ...lastMessageData,
          createdAt: firebase.database.ServerValue.TIMESTAMP,
          seenBy: {
            [currentChatDetail?.participantId]: false
          },
          isRead: false
        })
        /**
         * object for notification
         */
        const notificationData = {
          text: values.text,
          title: `${state?.firstName} ${state?.secondName}`,
          senderAvatarUrl: state?.avatarURL || ' ',
          userId: currentChatDetail.participantId
        }
        /**
         * creating document fot notification
         */
        await createDocument(COLLECTIONS.NOTIFICATIONS, notificationData)
      } catch (error) {
        message.error(error.message)
        /**
         * changing message`s status if error was occurred
         */
        messengerDispatch({
          type: TYPES.CHANGE_MESSAGE_STATUS,
          payload: {
            chatId: chatId,
            messageId: messageId,
            status: 'error'
          }
        })
      }
    }
  }

  return (
    <>
      {!chatsData[chatId] || messagesLoading ? (
        <Row height="100%" h="center" v="center">
          <Col cw="auto">
            {messagesLoading ? (
              <Spinner />
            ) : (
              <Text
                color="var(--message-color)"
                fontSize="var(--ql-font-size-h5)">
                {t('Start a chat')}
              </Text>
            )}
          </Col>
        </Row>
      ) : (
        <MessagesList
          setRead={setRead}
          chatId={chatId}
          onEditClick={(messageData) =>
            onEditClick(messageData, messengerDispatch, inputRef)
          }
          onDeleteFinish={onDeleteFinish}
        />
      )}
      <MessageForm
        disabled={messagesLoading && chatsData[chatId]}
        onEditFinish={(values) =>
          editMessageFinish(values, chatId, messengerState, messengerDispatch)
        }
        onFinishSend={onFinishSend}
        chatId={chatId}
        form={form}
        inputRef={inputRef}
        loading={messengerLoading}
      />
    </>
  )
}

export default PersonalChat
