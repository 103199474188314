import {
  ChatsAll,
  ChatShow,
  ChatCreate,
  ChatGroupCreate,
  ChatGroupEdit
} from '../routes'
import ROUTE_PATHS from 'domains/allRoutePath'

export default [
  {
    name: 'ChatsAll',
    path: ROUTE_PATHS.CHATS_ALL,
    exact: true,
    component: ChatsAll
  },
  {
    name: 'ChatShow',
    path: ROUTE_PATHS.CHAT_SHOW,
    exact: true,
    component: ChatShow
  },
  {
    name: 'ChatCreate',
    path: ROUTE_PATHS.CHAT_CREATE,
    exact: true,
    component: ChatCreate
  },
  {
    name: 'ChatGroup',
    path: ROUTE_PATHS.CHAT_GROUP_CREATE,
    exact: true,
    component: ChatGroupCreate
  },
  {
    name: 'ChatGroupEdit',
    path: ROUTE_PATHS.CHAT_GROUP_EDIT,
    exact: true,
    component: ChatGroupEdit
  }
]
