import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import { Box, Button, TextArea } from '@qonsoll/react-design'
import { SendOutlined } from '@ant-design/icons'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import styled from 'styled-components'
import { useMessengerContext } from '~/app/contexts/Messenger/hooks'
import { TYPES } from '~/app/contexts/Messenger/constants'

const ChatFooter = styled(Box)`
  display: flex;
  padding: 12px;
  margin: -12px;
  margin-top: ${({ borderTopShow }) => (borderTopShow ? 0 : '12px')};
  border-top: ${({ borderTopShow }) =>
    borderTopShow &&
    `var(--ql-border-width-default) var(--ql-border-style-default) var(--dark-transparent-8)`};
`

const MessageForm = (props) => {
  const {
    onFinishSend,
    onEditFinish,
    loading,
    inputRef,
    chatId,
    form,
    disabled,
    ...rest
  } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()
  const { messengerState, messengerDispatch } = useMessengerContext()
  const showBorder = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  // [COMPUTED_PROPERTIES]
  const { editMessage } = messengerState

  // [CLEAN_FUNCTIONS]
  const onSubmit = async () => {
    const onFinish = editMessage ? onEditFinish : onFinishSend
    await onFinish({ text: form.getFieldsValue().text.trim() })
    messengerDispatch({ type: TYPES.SET_MESSENGER_LOADING, payload: false })
    form.resetFields()
  }

  const onKeyPress = async (event) => {
    if (event.key === 'Enter' && !event.shiftKey && !loading) {
      event.preventDefault()
      await onSubmit()
    }
  }

  const setToLocalStorage = (event) =>
    localStorage.setItem(chatId, event.target.value)

  const getFromLocalStorage = useCallback(
    () => localStorage.getItem(chatId),
    [chatId]
  )

  // [USE EFFECTS]
  useEffect(() => {
    let isComponentMounted = true

    isComponentMounted &&
      form.setFieldsValue({
        text: messengerState?.editMessage?.text || getFromLocalStorage()
      })

    return () => {
      isComponentMounted = false
    }
  }, [messengerState, form, getFromLocalStorage])

  return (
    <Form form={form} onFinish={onSubmit} {...rest} autoComplete="off">
      <ChatFooter borderTopShow={showBorder}>
        <Form.Item name="text" style={{ width: '100%', marginBottom: 0 }}>
          <TextArea
            disabled={disabled}
            onChange={setToLocalStorage}
            placeholder={t('Enter message')}
            autoSize={{ maxRows: 6 }}
            autoFocus
            onKeyPress={onKeyPress}
            ref={inputRef}
          />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            ml={1}
            disabled={disabled}
            type="text"
            icon={<SendOutlined />}
            htmlType="submit"
            loading={loading}
          />
        </Form.Item>
      </ChatFooter>
    </Form>
  )
}
MessageForm.propTypes = {
  chatId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  inputRef: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
}

export default React.memo(MessageForm)
