import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Row, Col } from '@qonsoll/react-design'
import { DateLabel } from '~/app/components'
import {
  MessageSimpleView,
  MessageAdvancedView
} from '~/app/domains/Chat/components'
import { useMessengerContext } from '~/app/contexts/Messenger/hooks'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import _ from 'lodash'
import moment from 'moment'
import { useUserContext } from 'app/contexts/User/hooks'

const MessagesList = (props) => {
  const { chatId, isGroup, ...rest } = props

  // [COMPONENT_STATE_HOOKS]
  const [sortedMessagesByDate, setSortedMessagesByDate] = useState({})
  const [dates, setDates] = useState([])

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const { messengerState } = useMessengerContext()
  const state = useUserContext()
  const { chatsData } = messengerState

  // [COMPUTED_PROPERTIES]
  const DAY_DIFF_MAP = {
    0: t('today'),
    1: t('yesterday')
  }

  // [ADDITIONAL_HOOKS]
  const messagesEndRef = useRef(null)

  // [USE_EFFECTS]
  useEffect(() => {
    messagesEndRef?.current?.scrollIntoView(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedMessagesByDate])

  useEffect(() => {
    /**
     * grouping messages by date
     */
    const messagesByDate = _.groupBy(Object.values(chatsData[chatId]), 'date')

    const now = moment(
      moment(new Date()).format('DD-MM-YYYY').split('-').reverse()
    )
    const dates = Object.keys(messagesByDate).map((date) => {
      const newDate = moment(date.split('-').reverse())

      return {
        date,
        title: DAY_DIFF_MAP?.[now.diff(newDate, 'days')] || date,
        createdAt: date.valueOf()
      }
    })
    setDates(dates)
    setSortedMessagesByDate(messagesByDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatsData, chatId])

  return (
    <Box flex="1 1 auto" overflowY="auto" height={0} pt={12} pr="4px">
      {dates.map(({ title, date }, index) => (
        <Box mb={12} key={index}>
          <Row h="center" width="100%" noGutters mb={1}>
            <Col cw="auto">
              <DateLabel text={title} />
            </Col>
          </Row>
          {sortedMessagesByDate[date].map((messageData, index) => (
            <Row noGutters mb={1} key={index}>
              <Col
                maxWidth="70%"
                minWidth="20%"
                cw="auto"
                ml={messageData?.createdBy === state?.id && 'auto'}
                style={{
                  justifyContent:
                    messageData?.createdBy === state?.id && 'flex-end'
                }}>
                {isGroup ? (
                  <MessageAdvancedView messageData={messageData} {...rest} />
                ) : (
                  <MessageSimpleView messageData={messageData} {...rest} />
                )}
              </Col>
            </Row>
          ))}
        </Box>
      ))}
      <div ref={messagesEndRef} />
    </Box>
  )
}

MessagesList.propTypes = {
  chatId: PropTypes.string.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteFinish: PropTypes.func.isRequired,
  setRead: PropTypes.func.isRequired
}

export default MessagesList
