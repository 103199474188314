import React from 'react'
import { Empty } from 'antd'
import { Row, Col, PageWrapper } from '@qonsoll/react-design'
//import { FilterOutlined } from '@ant-design/icons'
import { OffersList } from 'domains/Offer/components'
import { getCollectionRef } from '~/services/Firebase/firestore'
import { COLLECTIONS } from 'app/constants'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import useOffersCache from '~/app/contexts/Cache/hooks/useOffersCache'
import useCache from '~/hooks/cache/useCache'
import _ from 'lodash'
import InfiniteCachedList from '~/app/components/InfiniteCachedList'

function OffersAll() {
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  const { state, dispatch } = useOffersCache()
  useCache(
    getCollectionRef(COLLECTIONS.OFFERS).orderBy('id', 'asc').limit(25),
    state,
    dispatch
  )

  // [CLEAN FUNCTIONS]

  // TODO add search mechanism for cached list
  // !!!
  //  const onFilter = () => {}
  // const onOfferSearch = (event) => {
  //   const filteredOffers = collectionData.filter((item) =>
  //     item.title.toLowerCase().startsWith(event.target.value.toLowerCase())
  //   )
  //   setOffersData(filteredOffers)
  // }

  return (
    <PageWrapper
      headingProps={{
        title: t('Offers'),
        titleSize: titleSizes,
        marginBottom: '24px'
      }}
      isBottomSticky
      // action={
      //   <Button type="text" icon={<FilterOutlined />} onClick={onFilter} />
      // }
    >
      {!_.isEmpty(state?.cachedCollectionData) ? (
        <Row noGutters>
          {/* <Col mb="24px">
            <Input
              placeholder={t('Enter name here')}
              allowClear
              onChange={onOfferSearch}
            />
          </Col> */}
          <Col cw={12}>
            <InfiniteCachedList
              limit={15}
              collectionName={COLLECTIONS.OFFERS}
              cache={state}
              setCache={dispatch}
              order={{ field: 'id', type: 'asc' }}>
              {(offers) => <OffersList data={offers} />}
            </InfiniteCachedList>
          </Col>
        </Row>
      ) : (
        <Row height="100%">
          <Col v="center">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        </Row>
      )}
    </PageWrapper>
  )
}

export default OffersAll
