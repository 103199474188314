import { useCallback, useEffect } from 'react'
import { COLLECTIONS } from '~/app/constants'
import { getCollectionRef, updateDocument } from 'services/Firebase/firestore'

const useDeviceId = (userData) => {
  // Function that binds device id to current user
  const addDevice = useCallback(async () => {
    // Get all device ids binded to current user
    const getUserDevices = await getCollectionRef(COLLECTIONS.USER_DEVICES)
      .doc(userData.id)
      .get()
    // If user has device ids, then we need to check whether user device ids array doesn't contain
    // device id that we want to add
    if (getUserDevices.data()) {
      const userDevicesData = getUserDevices.data()
      const currentDeviceId = localStorage.getItem('DeviceId')
      // If user device ids doesn't contain device id that we want to add
      // then we just add device id to array of device ids
      // and push it to database
      // else we won't change device ids data in the database
      if (
        currentDeviceId &&
        !userDevicesData?.deviceIds.includes(currentDeviceId)
      ) {
        const arrayWithNewDevice = [
          ...userDevicesData.deviceIds,
          currentDeviceId
        ]
        await updateDocument(
          COLLECTIONS.USER_DEVICES,
          userData.id,
          {
            deviceIds: arrayWithNewDevice
          },
          { merge: true }
        )
      }
      // else if user doesn't have device ids
      // then we need to create new document in database that will store
      // user device ids
    } else {
      const currentDeviceId = localStorage.getItem('DeviceId')
      if (currentDeviceId) {
        await updateDocument(COLLECTIONS.USER_DEVICES, userData.id, {
          id: userData.id,
          deviceIds: [currentDeviceId]
        })
      }
    }
  }, [userData])

  // UseEffect to listen to user changes and add device id only
  // when there is user data, so there is user logged into application
  useEffect(() => {
    userData && addDevice()
  }, [userData])
}

export default useDeviceId
