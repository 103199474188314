import React, { useEffect, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import {
  Container,
  Row,
  Col,
  PageWrapper,
  Title,
  Button
} from '@qonsoll/react-design'
import { useParams, useHistory } from 'react-router-dom'
import ROUTE_PATHS from 'domains/allRoutePath'
import {
  useMessengerContext,
  useGetCountParticipantsInChat
} from 'app/contexts/Messenger/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import {
  useBackButton,
  useChatShowPage,
  useResponsiveBreakpoints
} from 'app/contexts/UI/hooks'
import { TYPES } from '~/app/contexts/Messenger/constants'
import { ChatsList, PersonalChat, GroupChat } from 'app/domains/Chat/components'
import { ChatHeader } from '~/app/components'
import { logLastActivity } from 'domains/Chat/helpers'
import { useUserContext } from 'app/contexts/User/hooks'

function ChatShow() {
  // [ADDITIONAL_HOOKS]
  const history = useHistory()
  const state = useUserContext()
  const screen = useResponsiveBreakpoints({
    sm: true,
    md: false,
    lg: false,
    xl: false,
    xxl: false
  })
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })
  useBackButton({
    showBackButton: screen
  })
  useChatShowPage()
  const { id } = useParams() //get chat id
  const { t } = useTranslations()
  const { loadingCount } = useGetCountParticipantsInChat(id)
  //context hooks
  const { messengerState, messengerDispatch } = useMessengerContext()

  // [ADDITIONAL_HOOKS]
  const showChatList = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })

  // [COMPUTED_PROPERTIES]
  const { chatsDetail } = messengerState

  // [COMPONENT_STATE_HOOKS]
  const [currentChatDetail, setCurrentChatDetail] = useState()

  // [CLEAN_FUNCTIONS]
  const onCreateChatClick = () => history.push(ROUTE_PATHS.CHAT_CREATE)

  // [USE_EFFECTS]
  useEffect(() => {
    let isComponentMounted = true

    // set current chat id and details
    const setChatInfo = () => {
      messengerDispatch({
        type: TYPES.SET_CURRENT_CHAT_ID,
        payload: id
      })
      setCurrentChatDetail(chatsDetail?.[id])
    }

    isComponentMounted && chatsDetail?.[id] && setChatInfo()

    return () => {
      isComponentMounted = false
    }
  }, [id, messengerDispatch, chatsDetail])

  useEffect(() => {
    let isComponentMounted = true
    isComponentMounted && logLastActivity(state?.id)

    return () => {
      isComponentMounted = false
    }
  }, [state?.id, id])

  return (
    <PageWrapper isBottomSticky firstLevelHidden>
      <Container height="inherit" display="grid">
        <Row noOuterGutters minWidth={0}>
          {showChatList && (
            <Col cw={[12, 12, 6, 5, 5]} flexDirection="column">
              <Row noGutters h="between" marginBottom="24px">
                <Col v="center">
                  <Title level={titleSizes} style={{ marginBottom: 0 }}>
                    {t('Chats')}
                  </Title>
                </Col>
                <Col cw="auto">
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={onCreateChatClick}
                  />
                </Col>
              </Row>
              <ChatsList />
            </Col>
          )}
          {currentChatDetail && !loadingCount && (
            <Col
              flexDirection="column"
              h="center"
              p={!screen && '12px'}
              pr={!screen && '12px !important'}
              borderRadius="var(--ql-border-radius-16)"
              bg={showChatList && 'var(--chat-active-bg)'}
              minWidth={0}>
              <Row height="100%" noGutters width="100%">
                <Col cw={12} h="between" minWidth={0}>
                  <ChatHeader />
                  {currentChatDetail?.isGroup ? (
                    <GroupChat currentChatDetail={currentChatDetail} />
                  ) : (
                    <PersonalChat currentChatDetail={currentChatDetail} />
                  )}
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Container>
    </PageWrapper>
  )
}

export default ChatShow
