import {
  Box,
  Button,
  Col,
  Container,
  PageWrapper,
  Paragraph,
  Row
} from '@qonsoll/react-design'
import { useTranslations } from 'app/contexts/Translation/hooks'
import {
  useResponsiveBreakpoints,
  useShowActions,
  useUI
} from 'app/contexts/UI/hooks'
import IntroVideo from 'assets/Trond intro video.mp4'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useHistory } from 'react-router-dom'
import { Player } from 'video-react'

import 'video-react/dist/video-react.css' // import css

const IntroPage = () => {
  useShowActions()
  const { t } = useTranslations()
  const history = useHistory()
  const UI = useUI()
  const addPadding = useResponsiveBreakpoints({
    sm: true,
    md: false,
    lg: false,
    xl: false,
    xxl: false
  })
  return (
    <PageWrapper firstLevelHidden alignMiddle>
      <Container height="inherit" display="grid">
        <Row noGutters h="center" pb={UI?.state?.safeAreaInsets?.bottom}>
          <Col cw={[12, 10, 8, 7, 6]} display="grid">
            <Row>
              <Col cw={[12, 6]} pb={addPadding && 4} h="center">
                <video
                  width="320"
                  height="auto"
                  poster={'/poster/Intro.png'}
                  controls>
                  <source src={IntroVideo} type="video/mp4" />
                </video>
              </Col>
              <Col cw={[12, 6]}>
                <Box>
                  <Paragraph mb="1rem !important">
                    Vi har gjort det raskt og enkelt å bli enig om alt som er
                    viktig å være enige om når dere kjøper sammen.
                  </Paragraph>
                  <Paragraph mb="1rem !important">
                    Velg hva du ønsker å kjøpe sammen med noen, og så inviterer
                    du de andre.
                  </Paragraph>
                  <Paragraph mb="1rem !important">
                    Så svarer dere på enkle spørsmål. Når dere er enige lager vi
                    en kontrakt som dere signerer.
                  </Paragraph>
                  <Paragraph mb="1rem !important">
                    Enkelt, smart og trygt.
                  </Paragraph>
                  <Paragraph mb="1rem !important">
                    Lykke til med å kjøpe sammen!
                  </Paragraph>
                </Box>
                <Button
                  type="primary"
                  block
                  mt="auto"
                  onClick={() => history.replace(ROUTE_PATHS.USER_CREATE)}>
                  {t('Continue')} →
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  )
}

export default IntroPage
