const ROUTE_PATHS = {
  404: '/404',
  SETTINGS: '/settings',
  BUYERS_ALL: '/buyers',
  ACCESS_DENIED: '/403',
  LANGUAGES_ALL: '/languages',
  BUYING_CASE_CREATE: '/buying-case/new',
  BUYING_CASE_INVITE_ATTENDEES: '/buying-cases/:id/invite',
  BUYING_CASE_INVITE_ATTENDEES_EDIT: '/buying-cases/:id/invite/edit',
  BUYING_CASE_SHOW: '/buying-cases/:id',
  BUYING_CASES_ALL: '/buying-cases',
  BUYING_CASE_EDIT: '/buying-cases/:id/edit',
  BUYING_CASE_SHOW_ANSWERS: '/buying-cases/:id/answers',
  BUYING_CASE_SHOW_WIZARD_RESULT: '/buying-cases/:id/wizard-result',
  BUYING_CASE_SHOW_BUYER_ANSWERS: '/buying-cases/:id/wizard-result/:buyerId',
  OFFER_SHOW: '/offers/:id',
  OFFERS_ALL: '/offers',
  FORGOT_PASSWORD: '/forgot-password',
  LOGIN: '/login',
  SIGNUP: '/sign-up',
  RESET_PASSWORD: '/reset-password',
  SET_PASSWORD: '/set-password',
  EMAIL_CONFIRM: '/confirm-email',
  USER_CREATE: '/user/new',
  USER_EDIT: '/users/:id/edit',
  USER_SHOW: '/users/:id',
  CHATS_ALL: '/chats',
  CHAT_SHOW: '/chats/:id',
  CHAT_CREATE: '/chat/new',
  CHAT_GROUP_CREATE: '/chat/new/group',
  CHAT_GROUP_EDIT: '/chats/:id/edit',
  WIZARD_SHOW: '/wizards/:id',
  WIZARD_INIT: '/wizards/init/:id',
  GDPR_PAGE: '/cookie-policy',
  INTRO_PAGE: '/intro'
}
export default ROUTE_PATHS
