import React, { useEffect, useState } from 'react'
import { message, Form, Input } from 'antd'
import { Col, Container, Row, PageWrapper, Button } from '@qonsoll/react-design'
import { PageLoading } from 'app/components'
import { useAPIRequestContext } from 'app/contexts/APIRequest/hooks'
import { POSTRequestOptions } from 'domains/User/__constants__/requestOptions'
import firebase from 'services/Firebase/init'
import auth, { loginWithGoogle } from 'services/Firebase/auth'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useHistory, generatePath } from 'react-router-dom'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { useUserContext } from 'app/contexts/User/hooks'

function SetPassword() {
  // [ADDITIONAL HOOKS]
  const requester = useAPIRequestContext()
  const [form] = Form.useForm()
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const history = useHistory()
  const { t } = useTranslations()
  const state = useUserContext()
  const titleSizes = useResponsiveBreakpoints({
    sm: 3,
    md: 2,
    lg: 2,
    xl: 2,
    xxl: 2
  })

  // [COMPONENT_STATE_HOOKS]
  const [loading, setLoading] = useState(false)

  // [CLEAN_FUNCTIONS]
  const onFinish = async (values) => {
    setLoading(true)
    try {
      const credential = firebase.auth.EmailAuthProvider.credential(
        state.email,
        values.newPassword
      )
      await auth.currentUser.linkWithCredential(credential)
      message.success(t('Your password was successfully changed'))
      localStorage.removeItem('form')
      history.push(generatePath(ROUTE_PATHS.USER_SHOW, { id: state.id }))
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        const responseData = await crypto(
          values.newPassword,
          'https://buy2gether-dev.herokuapp.com/rest/data/encrypt'
        )

        localStorage.setItem('form', responseData)
        loginWithGoogle()
      } else {
        message.error(t('Error occurred during password change'))
      }
    }
  }

  const crypto = async (value, url) => {
    const token = await auth.currentUser.getIdToken()
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', token)
    myHeaders.append('Accept', '*')
    const body = JSON.stringify({
      id: state.id,
      data: value
    })
    const response = await requester(url, POSTRequestOptions(myHeaders, body))
    return response.text()
  }

  // [USE_EFFECTS]
  useEffect(
    () =>
      auth.getRedirectResult().then(async () => {
        if (localStorage.getItem('form')) {
          const responseData = await crypto(
            localStorage.getItem('form'),
            'https://buy2gether-dev.herokuapp.com/rest/data/decrypt'
          )
          onFinish({ newPassword: responseData })
        }
      }),
    []
  )
  return (
    <PageLoading loading={localStorage.getItem('form')}>
      <PageWrapper
        isBottomSticky
        headingProps={{
          title: t('Set password'),
          titleSize: titleSizes,
          titleMarginBottom: 2,
          subTitle: t('You need to reauthenticate into your current account'),
          textAlign: 'left',
          marginBottom: '24px'
        }}
        onBack={goBack}>
        <Container height="inherit" display="grid">
          <Row noGutters h="center">
            <Col cw={[12, 10, 8, 7, 6]} display="grid">
              <Form
                form={form}
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
                onFinish={onFinish}>
                <Form.Item
                  name="newPassword"
                  hasFeedback
                  rules={[
                    { required: true, message: t('Incorrect password') },
                    { min: 6, message: t('At least 6 symbols') }
                  ]}>
                  <Input.Password placeholder={t('Password')} />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  dependencies={['newPassword']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: t('Please confirm your password!')
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('newPassword') === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(
                            t(
                              'The two passwords that you entered do not match!'
                            )
                          )
                        )
                      }
                    })
                  ]}>
                  <Input.Password placeholder={t('Confirm password')} />
                </Form.Item>
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  loading={loading}
                  mt="auto">
                  {t('Set my password')}
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default SetPassword
