import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useUI from './useUI'

/**
 * @info useBackButton (14 Mar 2021) // CREATION DATE
 *
 * @comment useBackButton - hook that add back button in header.
 *
 * @since 11 Jun 2021 ( v.0.0.4 ) // LAST-EDIT DATE
 *
 * @param {Object}  [options]
 * @param {Function}   [options.callback]             Additional function that can be run on button click.
 * @param {boolean}    [options.goBack]               Should go back on button click.
 * @param {boolean}    [options.showBackButton]       Used to hide back button on specific screen size.
 * @param {boolean}    [options.returnCallback]       Return onBack callback or no.
 *
 * @return {callback}
 */

const useBackButton = (options = {}) => {
  const {
    callback,
    goBack = true,
    showBackButton = true,
    returnCallback = true
  } = options
  const { state, dispatch } = useUI()

  // [ADDITIONAL_HOOKS]
  const history = useHistory()

  // [USE_EFFECTS]
  useEffect(() => {
    const onBack = () => {
      callback?.()
      goBack && history.goBack()
    }
    dispatch({
      type: 'SET_WITH_BACK_BUTTON',
      payload: showBackButton && onBack
    })

    return () => {
      dispatch({ type: 'SET_WITH_BACK_BUTTON', payload: false })
    }
  }, [callback, dispatch, goBack, showBackButton, history])
  return returnCallback && state.onBack
}

export default useBackButton
