import { COLLECTIONS } from 'app/constants'
import { getReferenceChild } from '~/services/Firebase/database'
import firebase from '~/services/Firebase/init'

/**
 * @info logLastActivity (06 Jul 2021) // CREATION DATE
 *
 * @since 06 Jul 2021 ( v.0.0.1 ) // LAST-EDIT DATE
 *
 * @comment Function that set last user time activity in RTDB.
 *
 * @param   {string}     uid       Current user id.
 * @returns {void}
 */

const logLastActivity = async (uid) => {
  await getReferenceChild(
    `${COLLECTIONS.CHATS}/${COLLECTIONS.PARTICIPANTS}/${uid}`
  ).update({ lastSeen: firebase.database.ServerValue.TIMESTAMP })
}
export default logLastActivity
