import { COLLECTIONS } from 'app/constants'
import { getReferenceChild } from '~/services/Firebase/database'
import firebase from '~/services/Firebase/init'
import ROUTE_PATHS from 'domains/allRoutePath'
import { generatePath } from 'react-router-dom'
import { createParticipantData } from 'domains/Chat/helpers'

const createChatWithOneParticipant = async (state, userData, history) => {
  const chatId = state.id.slice(0, 15).concat('_', userData?.id?.slice(0, 15))

  /**
   * checking if data about participant is in database
   */
  !userData?.isParticipant &&
    (await createParticipantData(userData, chatId, false, state?.id))

  !state?.isParticipant &&
    (await createParticipantData(state, chatId, false, userData?.id))

  /**
   * set chat`s participants to new chat
   */
  await getReferenceChild(
    `${COLLECTIONS.CHATS}/${COLLECTIONS.CHATS_BY_ID}/${chatId}`
  ).set({
    [COLLECTIONS.LAST_MESSAGE_DATA]: {
      text: 'no messages',
      createdAt: firebase.database.ServerValue.TIMESTAMP
    },
    [COLLECTIONS.CHAT_DETAILS]: {
      isGroup: false,
      chatId,
      chatCreatedBy: state?.id
    },
    [COLLECTIONS.CHAT_PARTICIPANTS]: {
      [state.id]: 0,
      [userData?.id]: 0
    }
  })

  await getReferenceChild(
    `${COLLECTIONS.CHATS}/${COLLECTIONS.PARTICIPANTS}/${userData?.id}/${COLLECTIONS.CHATS_BY_PARTICIPANTS}`
  ).update({
    [state.id]: chatId
  })
  await getReferenceChild(
    `${COLLECTIONS.CHATS}/${COLLECTIONS.PARTICIPANTS}/${state.id}/${COLLECTIONS.CHATS_BY_PARTICIPANTS}`
  ).update({
    [userData?.id]: chatId
  })

  history.push(
    generatePath(ROUTE_PATHS.CHAT_SHOW, {
      id: chatId
    })
  )
}
export default createChatWithOneParticipant
