import WebViewProvider from 'app/contexts/WebViewContext/WebViewProvider'
import authorizedRoutes, { unauthorizedRoutes } from 'domains/allRoutes'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import App from './App'
import SessionProvider from './app/contexts/Session/SessionProvider'
import UserProvider from 'app/contexts/User/UserProvider'
import { UIProvider } from 'app/contexts/UI/components'
import { TranslationsProvider } from 'app/contexts/Translation/components'
import { MessengerProvider } from 'app/contexts/Messenger/components'
import theme from './styles/theme'
import '@qonsoll/react-design/dist/styles/styles.css'
import './styles/styles.css'
import { APIRequestProvider } from 'app/contexts/APIRequest/components'
import {
  BuyingCaseCacheProvider,
  OfferCacheProvider
} from './app/contexts/Cache/components'

ReactDOM.render(
  <Router>
    <ThemeProvider theme={theme}>
      <TranslationsProvider>
        <WebViewProvider>
          <SessionProvider>
            {({ isSessionExist }) =>
              isSessionExist ? (
                <UserProvider>
                  {(userData) => (
                    <UIProvider>
                      <MessengerProvider>
                        <APIRequestProvider>
                          <OfferCacheProvider>
                            <BuyingCaseCacheProvider>
                              <App
                                routes={authorizedRoutes}
                                userData={userData}
                              />
                            </BuyingCaseCacheProvider>
                          </OfferCacheProvider>
                        </APIRequestProvider>
                      </MessengerProvider>
                    </UIProvider>
                  )}
                </UserProvider>
              ) : (
                <UIProvider>
                  <App routes={unauthorizedRoutes} />
                </UIProvider>
              )
            }
          </SessionProvider>
        </WebViewProvider>
      </TranslationsProvider>
    </ThemeProvider>
  </Router>,
  document.getElementById('root')
)
