import {
  ForgotPassword,
  Login,
  Signup,
  ResetPassword,
  SetPassword,
  EmailConfirm
} from '../routes'
import ROUTE_PATHS from 'domains/allRoutePath'

export default [
  {
    name: 'ForgotPassword',
    path: ROUTE_PATHS.FORGOT_PASSWORD,
    exact: true,
    component: ForgotPassword
  },
  {
    name: 'Login',
    path: ROUTE_PATHS.LOGIN,
    exact: true,
    component: Login
  },
  {
    name: 'Signup',
    path: ROUTE_PATHS.SIGNUP,
    exact: true,
    component: Signup
  },
  {
    user: true,
    name: 'ResetPassword',
    path: ROUTE_PATHS.RESET_PASSWORD,
    exact: true,
    component: ResetPassword
  },
  {
    user: true,
    name: 'SetPassword',
    path: ROUTE_PATHS.SET_PASSWORD,
    exact: true,
    component: SetPassword
  },
  {
    user: true,
    name: 'EmailConfirm',
    path: ROUTE_PATHS.EMAIL_CONFIRM,
    exact: true,
    component: EmailConfirm
  }
]
