import { useContext } from 'react'
import RequestContext from '../context'

const useAPIRequestContext = () => {
  const context = useContext(RequestContext)

  if (context === undefined) {
    throw new Error(
      'useAPIRequestContext must be used within a useAPIRequestContext.Provider'
    )
  }

  return context
}

export default useAPIRequestContext
