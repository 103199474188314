import { Link } from '@qonsoll/react-design'
import { Modal } from 'antd'
function PDFViewer(props) {
  const { pdf, onCancel, visible } = props

  return (
    <Modal
      destroyOnClose
      visible={visible}
      onCancel={onCancel}
      style={{ top: 20 }}
      width="80%"
      footer={null}>
      <object data={pdf} type="application/pdf" width="100%" height="500px">
        <Link href={pdf} target="_blank"></Link>
      </object>
    </Modal>
  )
}

export default PDFViewer
