import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Select } from 'antd'
import { Col, Row, Input, TextArea, Button, Text } from '@qonsoll/react-design'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { useTranslations } from '~/app/contexts/Translation/hooks'

function BuyingCaseEditForm(props) {
  const {
    buyingCaseTypes,
    onFinish,
    initialValues,
    initialType,
    wizardProcessing
  } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const [type, setType] = useState(initialType || {})

  // [CLEAN FUNCTIONS]
  const onChangeSelectType = (_, object) => {
    setType(object.key)
  }
  return (
    <Form
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
      layout="vertical"
      requiredMark={false}
      onFinish={(data) => onFinish(data, type)}
      initialValues={initialValues}>
      <Form.Item
        label={t('Buying case name')}
        name="name"
        rules={[
          { required: true, message: t('Enter name please') },
          {
            max: 200,
            message: t('Name should be less than 200 character')
          }
        ]}>
        <Input autoFocus placeholder={t('Enter case name')} />
      </Form.Item>
      <Form.Item
        label={t('Description')}
        name="description"
        rules={[
          {
            max: 2500,
            message: t('Description should be less than 2500 character')
          }
        ]}>
        <TextArea
          placeholder={t('Enter case description')}
          autoSize={{ minRows: 2 }}
        />
      </Form.Item>
      <Form.Item
        label={t('Buying case type')}
        name="type"
        style={{ width: '100%' }}
        rules={[
          {
            required: true,
            message: t('Choose type, please')
          }
        ]}>
        <Select
          disabled={!!wizardProcessing?.length}
          onChange={onChangeSelectType}
          placeholder={t('Type')}
          options={buyingCaseTypes?.map((type) => ({
            value: type?.name,
            key: type,
            label: _.capitalize(t(type?.name))
          }))}
        />
      </Form.Item>
      <Form.List name="links">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Row noGutters v="center" mb="24px">
                <Col cw="auto">
                  <Text style={{ marginRight: 'var(--margin-md' }} type={type}>
                    {t('Link')}
                  </Text>
                </Col>
                <Col mr="4px">
                  <Form.Item
                    {...restField}
                    name={[name]}
                    fieldKey={[fieldKey]}
                    style={{ marginBottom: 0 }}>
                    <Input placeholder={t('Enter link')} />
                  </Form.Item>
                </Col>
                <Col cw="auto">
                  <Button
                    type="text"
                    icon={<MinusCircleOutlined />}
                    onClick={() => remove(name)}
                  />
                </Col>
              </Row>
            ))}
            <Button onClick={() => add()} block icon={<PlusOutlined />} mb={2}>
              {t('Add link')}
            </Button>
          </>
        )}
      </Form.List>
      <Button block htmlType="submit" type="primary" mt="auto">
        {t('Save changes')}
      </Button>
    </Form>
  )
}

BuyingCaseEditForm.propTypes = {
  buyingCaseTypes: PropTypes.array.isRequired,
  initialType: PropTypes.object.isRequired,
  initialValues: PropTypes.array.isRequired,
  onFinish: PropTypes.func.isRequired
}

export default BuyingCaseEditForm
