import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Tooltip } from 'antd'
import { Row, Col, Box, Avatar, Card, Text } from '@qonsoll/react-design'
import { UserOutlined } from '@ant-design/icons'
import { useHistory, generatePath } from 'react-router-dom'
import ROUTE_PATHS from 'domains/allRoutePath'

function BuyersListWithSelect(props) {
  const { buyersData, setCountSelectedParticipant, style, ...rest } = props

  // [ADDITIONAL_HOOKS]
  const history = useHistory()

  // [CLEAN_FUNCTIONS]
  const onChange = (isChecked) =>
    setCountSelectedParticipant?.((prevNumber) =>
      isChecked ? prevNumber + 1 : prevNumber - 1
    )

  const onBuyerClick = (id) =>
    history.push(generatePath(ROUTE_PATHS.USER_SHOW, { id }))

  return (
    <Box style={style}>
      <Checkbox.Group style={{ width: '-webkit-fill-available' }} {...rest}>
        {buyersData?.map((data, index) => (
          <Row noGutters minWidth={0}>
            <Col cw={12} minWidth={0}>
              <Card
                key={index}
                mb={3}
                size="small"
                bordered={false}
                shadowless
                style={{ cursor: 'pointer' }}
                width="100%">
                <Row noGutters h="center" v="center">
                  <Col cw="auto" mr={3} onClick={() => onBuyerClick(data?.id)}>
                    <Box
                      height="fit-content"
                      border="var(--outline-width) var(--ql-border-style-default) var(--avatar-border-color)"
                      borderRadius="var(--ql-border-radius-full)">
                      <Avatar
                        m="2px"
                        icon={<UserOutlined />}
                        src={data?.avatarURL}
                        alt="avatar"
                      />
                    </Box>
                  </Col>
                  <Col minWidth={0}>
                    <Row minWidth={0} flexDirection="column" noGutters>
                      <Col
                        cw={12}
                        minWidth={0}
                        onClick={() => onBuyerClick(data?.id)}>
                        <Text level={5} isEllipsis>{`${data?.firstName ?? ''} ${
                          data?.secondName ?? ''
                        }`}</Text>
                      </Col>
                      <Col cw={12}>
                        <Tooltip title={data?.aboutMe?.slice(0, 250)}>
                          <Text type="secondary" width="fit-content" isEllipsis>
                            {data?.aboutMe}
                          </Text>
                        </Tooltip>
                      </Col>
                    </Row>
                  </Col>
                  <Col cw="auto" ml={3}>
                    <Checkbox
                      value={data?.id}
                      onChange={(event) => onChange(event?.target?.checked)}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        ))}
      </Checkbox.Group>
    </Box>
  )
}

BuyersListWithSelect.propTypes = {
  buyersData: PropTypes.array.isRequired,
  setCountSelectedParticipant: PropTypes.func
}

export default BuyersListWithSelect
