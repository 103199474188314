import { useContext } from 'react'
import MessengerContext from '../context'

const useMessengerContext = () => {
  const context = useContext(MessengerContext)

  if (context === undefined) {
    throw new Error(
      'useMessengerContext must be used within a MessengerProvider'
    )
  }

  return context
}

export default useMessengerContext
