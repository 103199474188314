import { useEffect } from 'react'
import {
  Switch,
  Route,
  useHistory,
  Redirect,
  useLocation
} from 'react-router-dom'
import { GDPR } from 'domains/App/components'
import { unauthorizedRoutes } from 'app/domains/allRoutes'
import { useGetChatsDetail } from './app/contexts/Messenger/hooks'
import { useSessionContext } from 'app/contexts/Session/hooks'
import ROUTE_PATHS from 'domains/allRoutePath'

function App(props) {
  const { routes, userData } = props
  // [ADDITIONAL_HOOKS]
  const history = useHistory()
  const { pathname } = useLocation()
  const { isSessionExist, emailVerified } = useSessionContext()

  // Extracting chat data to show amount of unread messages
  useGetChatsDetail(userData)

  useEffect(() => {
    if (isSessionExist) {
      if (
        pathname === ROUTE_PATHS.EMAIL_CONFIRM ||
        pathname === ROUTE_PATHS.USER_CREATE
      ) {
        /*
         * If activated user go to email confirm or user create route
         * redirect him to default route.
         */
        return (
          userData.isActivated && history.push(ROUTE_PATHS.BUYING_CASES_ALL)
        )
      } else if (!emailVerified) {
        /*
         * If user doesn't confirm his email redirect it to confirm page
         * until he confirm it
         */
        return history.push(ROUTE_PATHS.EMAIL_CONFIRM)
      }
      if (
        ![ROUTE_PATHS.INTRO_PAGE, ROUTE_PATHS.USER_CREATE].includes(pathname) &&
        !userData.isActivated
      ) {
        console.log('in')
        history.push(ROUTE_PATHS.INTRO_PAGE)
      } else if (
        unauthorizedRoutes
          .map(({ path }) => path)
          .join('/')
          .includes(pathname)
      ) {
        /*
         * If authorize user decide go to login or sign up route - redirect
         * him to default route.
         */
        history.push(ROUTE_PATHS.BUYING_CASES_ALL)
      }
    } else {
      /*
       * If no session and user not in unauthorized routes, redirect him to
       * login.
       */
      if (
        !unauthorizedRoutes
          .map(({ path }) => path)
          .join('/')
          .includes(pathname)
      ) {
        history.push(ROUTE_PATHS.LOGIN)
      }
    }
  }, [isSessionExist, userData, pathname, history])

  return (
    <>
      <Switch>
        {routes.map((route) => (
          <Route
            key={route.name}
            exact={route.exact}
            path={route.path}
            component={route.component}
          />
        ))}
        <Redirect to={ROUTE_PATHS.BUYING_CASES_ALL} />
      </Switch>
      <GDPR />
    </>
  )
}

export default App
