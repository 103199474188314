import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, useHistory } from 'react-router-dom'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { Avatar, Tooltip } from 'antd'
import { PlusOutlined, UserOutlined } from '@ant-design/icons'
import { getCollectionRef } from '~/services/Firebase/firestore'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import { COLLECTIONS } from 'app/constants'
import ROUTE_PATHS from 'domains/allRoutePath'

function BuyingCaseAttendeesList(props) {
  const { data, size, maxCount, onEditUser } = props

  // [ADDITIONAL_HOOKS]
  const history = useHistory()
  const [usersData, loading] = useCollectionData(
    data?.length &&
      getCollectionRef(COLLECTIONS.USERS)
        .where('email', 'in', data)
        .where('isActivated', '==', true)
  )
  const { t } = useTranslations()
  // [HELPER_FUNCTIONS]
  const goToUserProfile = (id, e) => {
    e.stopPropagation()
    history.push(generatePath(ROUTE_PATHS.USER_SHOW, { id }))
  }

  return loading ? (
    <Avatar.Group>
      {data.map(() => (
        <Avatar size={size || 'small'} icon={<UserOutlined />} />
      ))}
    </Avatar.Group>
  ) : (
    <Avatar.Group maxCount={maxCount || 5}>
      {usersData?.length > 0 &&
        usersData?.map((user) => (
          <Tooltip
            title={`${user?.firstName} ${user?.secondName}`}
            placement="bottom">
            <Avatar
              key={user.id}
              size={size || 'small'}
              alt={t('Buying case attendee')}
              style={{ cursor: 'pointer' }}
              onClick={(e) => goToUserProfile(user.id, e)}
              src={user?.avatarURL}
              icon={<UserOutlined />}
            />
          </Tooltip>
        ))}
      {onEditUser && (
        <Tooltip title={t('Edit')} placement="bottom">
          <Avatar
            size={size || 'small'}
            alt={t('Buying case attendee')}
            icon={<PlusOutlined />}
            style={{ cursor: 'pointer' }}
            onClick={onEditUser}
          />
        </Tooltip>
      )}
    </Avatar.Group>
  )
}

BuyingCaseAttendeesList.propTypes = {
  size: PropTypes.oneOf(['large', 'small']),
  data: PropTypes.array.isRequired
}

export default BuyingCaseAttendeesList
