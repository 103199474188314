import { useAuthState } from 'react-firebase-hooks/auth'
import { useHistory } from 'react-router-dom'
import { Tooltip } from 'antd'
import { RedoOutlined } from '@ant-design/icons'
import {
  Box,
  Col,
  Row,
  Button,
  Text,
  PageWrapper,
  Divider
} from '@qonsoll/react-design'
import auth, { sendVerifyEmail } from '~/services/Firebase/auth'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { useShowActions } from 'app/contexts/UI/hooks'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useUserContext } from 'app/contexts/User/hooks'

const EmailConfirm = () => {
  // [ADDITIONAL_HOOKS]
  useShowActions()
  const [user] = useAuthState(auth)
  const state = useUserContext()
  const { t } = useTranslations()
  const history = useHistory()
  const titleSizes = useResponsiveBreakpoints({
    sm: 3,
    md: 2,
    lg: 2,
    xl: 2,
    xxl: 2
  })

  return (
    <PageWrapper
      alignMiddle
      headingProps={{
        title: t('Confirm your email address'),
        titleSize: titleSizes,
        textAlign: 'center',
        marginBottom: '24px'
      }}
      contentWidth={['100%', '100%', 450]}>
      <Row h="center" pb={24}>
        <Col cw={12} mb={12} h="center">
          <Text>
            {t('We have send email to')}: <strong>{user?.email}</strong>
          </Text>
        </Col>
        <Col cw="auto" mb={12}>
          <Text variant="body2">
            <Button
              type="link"
              size="small"
              onClick={() => window.location.reload()}>
              {t('Reload')}
            </Button>
            {t('page after confirmation')}.
          </Text>
        </Col>
      </Row>
      <Tooltip
        title={!user?.emailVerified && t('Confirm your email to continue')}>
        <Button
          variant="white"
          onClick={() =>
            history.push(
              state ? ROUTE_PATHS.BUYING_CASES_ALL : ROUTE_PATHS.USER_CREATE
            )
          }
          block
          disabled={!user?.emailVerified}>
          {t('Continue')}
        </Button>
      </Tooltip>
      <Box py={24}>
        <Divider>
          <Text
            variant="body2"
            textTransform="uppercase"
            style={{ color: 'var(--text-color-secondary-dark)' }}>
            Or
          </Text>
        </Divider>
      </Box>
      <Button variant="white" onClick={() => auth.signOut()} block>
        {t('Go to Login')}
      </Button>
      <Row h="center" pt={24}>
        <Col cw="auto" mb={12}>
          <Text type="secondary">{t("Don't get a confirmation email?")} </Text>
        </Col>
        <Col cw="auto">
          <Text type="secondary">{t('Check your spam or')} </Text>
          <Button
            type="link"
            icon={<RedoOutlined />}
            size="small"
            onClick={() => sendVerifyEmail()}>
            {t('Send again')}
          </Button>
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default EmailConfirm
