import {
  AccessDenied,
  NotFound,
  Settings,
  GDPRPage,
  IntroPage
} from '../routes'
import ROUTE_PATHS from 'domains/allRoutePath'

export default [
  {
    name: 'Settings',
    path: ROUTE_PATHS.SETTINGS,
    exact: true,
    component: Settings
  },
  {
    name: '404',
    path: ROUTE_PATHS[404],
    exact: true,
    component: NotFound
  },
  {
    name: 'AccessDenied',
    path: ROUTE_PATHS.ACCESS_DENIED,
    exact: true,
    component: AccessDenied
  },
  {
    name: 'GDPRPage',
    path: ROUTE_PATHS.GDPR_PAGE,
    exact: true,
    component: GDPRPage
  },
  {
    name: 'IntroPage',
    path: ROUTE_PATHS.INTRO_PAGE,
    exact: true,
    component: IntroPage
  }
]
