import PropTypes from 'prop-types'
import { Row, Col, Box } from '@qonsoll/react-design'
import { BuyingCaseAttendeesList } from 'domains/BuyingCase/components'
import { STATUSES } from 'app/constants'
import { useUserContext } from 'app/contexts/User/hooks'

function BuyingCaseView(props) {
  const { data, onEditUser, wizardProcessingData } = props

  // [ADDITIONAL HOOKS]
  const state = useUserContext()

  return (
    <Row noOuterGutters v={'center'}>
      <Box px={2} pb={2}>
        <Col cw="auto" style={{ textAlign: 'initial' }}>
          {data?.name}
        </Col>
      </Box>
      {data?.attendees && (
        <Col cw={'auto'}>
          <BuyingCaseAttendeesList
            size="large"
            data={data?.attendees}
            onEditUser={
              wizardProcessingData?.status !== STATUSES.AGREEMENT_CREATED &&
              data?.wasCreatedId === state.id &&
              onEditUser
            }
          />
        </Col>
      )}
    </Row>
  )
}

BuyingCaseView.propTypes = {
  wizardProcessingData: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onEditUser: PropTypes.func.isRequired
}

export default BuyingCaseView
