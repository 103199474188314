import React from 'react'
import PropTypes from 'prop-types'
import { Menu, Dropdown } from 'antd'

const CustomDropdown = (props) => {
  const { menuItems, style, children, trigger = 'click', ...rest } = props

  const menu = (
    <Menu style={{ ...style }}>
      {menuItems?.map(({ name, Component, onItemClick }) => (
        <Menu.Item key={name} onClick={onItemClick}>
          {Component}
        </Menu.Item>
      ))}
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={[trigger]} {...rest}>
      {children}
    </Dropdown>
  )
}

CustomDropdown.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      onItemClick: PropTypes.func.isRequired,
      Component: PropTypes.node.isRequired
    })
  ).isRequired,
  placement: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  trigger: PropTypes.string
}

export default CustomDropdown
