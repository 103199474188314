import { useEffect, useState } from 'react'
import { Col, Container, Row, PageWrapper } from '@qonsoll/react-design'
import _ from 'lodash'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import { PageLoading, Spinner } from '~/app/components'
import { COLLECTIONS, STATUSES } from '~/app/constants'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import {
  useBackButton,
  useResponsiveBreakpoints
} from '~/app/contexts/UI/hooks'
import {
  deleteDocument,
  getCollectionRef,
  getDocumentData,
  updateDocument
} from '~/services/Firebase/firestore'
import {
  WizardMainUserDialog,
  WizardMainUserVoting,
  WizardSameLocationDialog
} from '../../components'
import ROUTE_PATHS from 'domains/allRoutePath'
import {
  useCollectionData,
  useCollectionDataOnce,
  useDocumentData,
  useDocumentDataOnce
} from 'react-firebase-hooks/firestore'
import { useUserContext } from 'app/contexts/User/hooks'

const TITLE_SIZE_CONFIG = {
  sm: 4,
  md: 3,
  lg: 3,
  xl: 3,
  xxl: 3
}

function WizardInit() {
  // [ADDITIONAL HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const history = useHistory()
  const { id } = useParams()
  const state = useUserContext()
  const titleSizes = useResponsiveBreakpoints(TITLE_SIZE_CONFIG)
  const { t } = useTranslations()

  //[STATE HOOKS]
  const [header, setHeader] = useState(t('Buying case wizard'))

  const [buyingCaseData, buyingCaseDataLoading] = useDocumentDataOnce(
    getCollectionRef(COLLECTIONS.BUYING_CASES).doc(id)
  )
  const [usersData, usersDataLoading] = useCollectionDataOnce(
    buyingCaseData &&
      getCollectionRef(COLLECTIONS.USERS).where(
        'email',
        'in',
        buyingCaseData?.attendees
      )
  )
  const [wizardData, wizardLoading] = useDocumentData(
    getCollectionRef(COLLECTIONS.WIZARD_PROCESSING).doc(id)
  )

  const [form, formLoading] = useCollectionData(
    buyingCaseData &&
      getCollectionRef(COLLECTIONS.FORM).where(
        'buyingTypeId',
        '==',
        buyingCaseData?.buyingTypeId
      )
  )
  const [votedData, votingLoading] = useDocumentData(
    getCollectionRef(COLLECTIONS.VOTING_DATA).doc(id)
  )

  // [CLEAN FUNCTIONS]
  const onSameLocationYesClick = async () => {
    await updateDocument(COLLECTIONS.WIZARD_PROCESSING, id, {
      isSameLocation: true
    })
  }

  /*
    If someone not in the same location and select "No", redirect him/her to typeform,
    and do the same for anyone who want "start wizard" after.
   */
  const onSameLocationNoClick = async () => {
    await updateDocument(COLLECTIONS.WIZARD_PROCESSING, id, {
      isSameLocation: false
    })
    const votingBuyers = await getCollectionRef(COLLECTIONS.USERS)
      .where('id', 'in', wizardData?.buyerIds)
      .get()

    // Prepare all data of another user to work with.
    const votingBuyersShortInfo = votingBuyers.docs.map((item) => {
      return {
        answer: null,
        id: item.id,
        voted: null
      }
    })
    const votingDataInit = {
      id,
      voterUsersInfo: votingBuyersShortInfo
    }
    await updateDocument(COLLECTIONS.VOTING_DATA, id, votingDataInit, {
      withoutUndef: false
    })
    await onSurveyStart()
  }

  /*
    If buyer want to be main user and select "Yes".
   */
  const onMainUserChooseYesClick = async () => {
    await updateDocument(COLLECTIONS.WIZARD_PROCESSING, id, {
      mainUserId: state.id
    })
    const votingBuyers = await getCollectionRef(COLLECTIONS.USERS)
      .where('id', 'in', wizardData?.buyerIds)
      .get()
    const buyersWithoutMainUser = votingBuyers.docs.filter(
      (item) => item.id !== state.id
    )

    // Prepare all data of another user to work with.
    const votingBuyersShortInfo = buyersWithoutMainUser.map((item) => {
      return {
        id: item.id,
        wantBeMainUser: { answer: false, voted: false },
        agreeWithMainUser: { answer: false, voted: false }
      }
    })

    await updateDocument(COLLECTIONS.VOTING_DATA, id, {
      id,
      mainUserInfo: {
        id: state.id,
        name: `${state.firstName} ${state.secondName}`
      },
      voterUsersInfo: votingBuyersShortInfo
    })
  }

  /*
    If buyer don't want to be main user and select "No".
   */
  const onMainUserChooseNoClick = async () => {
    const votingData = await getDocumentData(COLLECTIONS.VOTING_DATA, id)
    // Fill info about current voting user, and set his vote for 'wantBeMainUser' field
    const votedUserInfoItem = {
      id: state.id,
      wantBeMainUser: { answer: false, voted: true },
      agreeWithMainUser: { answer: false, voted: false }
    }

    // If there are another users voted add they info
    let voterUsersInfo = votingData?.voterUsersInfo
      ? _.uniqBy([...votingData.voterUsersInfo, votedUserInfoItem], 'id')
      : [votedUserInfoItem]
    // Counter for main user approve
    const isVotingApprove =
      votingData?.voterUsersInfo?.length !== 0 &&
      votingData?.voterUsersInfo
        ?.map((item) => item?.wantBeMainUser?.answer)
        ?.reduce((acc, curr) => acc && curr)

    //  If no one want to be main user, start voting from the beginning.
    if (
      voterUsersInfo?.length === wizardData?.buyerIds?.length &&
      !isVotingApprove
    ) {
      voterUsersInfo = []
    }

    await updateDocument(COLLECTIONS.VOTING_DATA, id, {
      id,
      mainUserInfo: votingData?.mainUserInfo ?? null,
      voterUsersInfo
    })
  }

  /*
    If buyer agree with selected main user, get his/her answer and write to DB.
   */
  const onWizardMainUserVotingYesClick = async () => {
    const votingData = await getCollectionRef(COLLECTIONS.VOTING_DATA)
      .doc(id)
      .get()

    const editedData = votingData.data().voterUsersInfo.map((item) => {
      if (item.id === state.id) {
        item.agreeWithMainUser = { answer: true, voted: true }
      }
      return item
    })
    await updateDocument(
      COLLECTIONS.VOTING_DATA,
      id,
      { voterUsersInfo: editedData },
      { merge: true }
    )
  }

  /*
    If someone disagree with selected main user, start voting from the beginning.
   */
  const onWizardMainUserVotingNoClick = async () => {
    await deleteDocument(COLLECTIONS.VOTING_DATA, id)
    await updateDocument(
      COLLECTIONS.WIZARD_PROCESSING,
      id,
      { mainUserId: null },
      { merge: true, withoutUndef: false }
    )
  }

  /*
    After pass voting stage - start testing.
   */
  const onSurveyStart = async () => {
    await updateDocument(
      COLLECTIONS.WIZARD_PROCESSING,
      id,
      { status: STATUSES.IN_PROGRESS },
      { merge: true, withoutUndef: false }
    )
    history.replace(
      generatePath(ROUTE_PATHS.WIZARD_SHOW, {
        id: form?.[0]?.id
      }),

      { wizardProcessing: id }
    )
  }

  // [COMPUTED_PROPERTIES]
  const currentVotingUser = votedData?.voterUsersInfo?.find(
    ({ id }) => id === state.id
  )
  const loadingText =
    wizardData?.status === STATUSES.IN_PROGRESS
      ? 'Wait until main user finish test'
      : 'Wait while main user start wizard'

  // [USE_EFFECTS]
  useEffect(() => {
    if (wizardData?.status === STATUSES.FINISHED_TESTING) {
      history.replace(
        generatePath(ROUTE_PATHS.BUYING_CASE_SHOW_ANSWERS, {
          id
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardData?.status])

  useEffect(() => {
    if (
      typeof wizardData?.isSameLocation === 'boolean' &&
      !wizardData?.isSameLocation
    ) {
      history.replace(
        generatePath(ROUTE_PATHS.WIZARD_SHOW, {
          id: form?.[0]?.id
        }),
        { wizardProcessing: id }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardData?.isSameLocation])

  return (
    <PageLoading
      loading={
        wizardLoading ||
        votingLoading ||
        buyingCaseDataLoading ||
        usersDataLoading ||
        formLoading
      }
      height="inherit">
      <PageWrapper
        isBottomSticky
        headingProps={{
          title: header,
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}>
        <Container height="inherit" display="grid">
          <Row
            noGutters
            v={
              !(
                (wizardData?.isSameLocation &&
                  wizardData?.mainUserId !== null) ||
                currentVotingUser?.wantBeMainUser?.voted
              ) && 'center'
            }
            h="center"
            mt={-5}>
            <Col cw={[12, 8]} display="grid">
              {/*  First phase - define is they in one location.  */}
              {wizardData?.isSameLocation === null && (
                <WizardSameLocationDialog
                  wizardData={wizardData}
                  onYesClick={onSameLocationYesClick}
                  onNoClick={onSameLocationNoClick}
                />
              )}
              {/*  Second phase - find out who will be main user.  */}
              {wizardData?.isSameLocation &&
                wizardData?.mainUserId === null &&
                !currentVotingUser?.wantBeMainUser?.voted && (
                  <WizardMainUserDialog
                    setHeader={setHeader}
                    onYesClick={onMainUserChooseYesClick}
                    onNoClick={onMainUserChooseNoClick}
                  />
                )}
              {/*  Third phase - show voting result, and start testing.  */}
              {((wizardData?.isSameLocation &&
                wizardData?.mainUserId !== null) ||
                currentVotingUser?.wantBeMainUser?.voted) && (
                <WizardMainUserVoting
                  setHeader={setHeader}
                  votedData={votedData}
                  usersData={usersData}
                  onYesClick={onWizardMainUserVotingYesClick}
                  onNoClick={onWizardMainUserVotingNoClick}
                  onSurveyStart={onSurveyStart}
                />
              )}
            </Col>
            {currentVotingUser?.agreeWithMainUser?.answer && (
              <Spinner text={loadingText} />
            )}
          </Row>
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

WizardInit.propTypes = {}

export default WizardInit
