import SessionContext from 'app/contexts/Session/context'
import { unauthorizedRoutes } from 'domains/allRoutes'
import { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import { useLocation } from 'react-router-dom'

const SessionProvider = (props) => {
  const location = useLocation()
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const listener = firebase.auth().onAuthStateChanged((user) => {
      setUser(user)
      setLoading(false)
    })
    return () => listener()
  }, [firebase])

  // Disable loading on unauthorizedRoutes and wait session on authorized.
  const sessionLoading = unauthorizedRoutes
    .map(({ path }) => path)
    .join('/')
    .includes(location.pathname)
    ? false
    : loading

  return (
    <SessionContext.Provider
      value={{
        isSessionExist: Boolean(user),
        sessionLoading: sessionLoading && 'Session is loading',
        ...user
      }}>
      {props.children({ isSessionExist: Boolean(user) })}
    </SessionContext.Provider>
  )
}

export default SessionProvider
