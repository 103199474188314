import { WizardInit, WizardShow } from '../routes'
import ROUTE_PATHS from 'domains/allRoutePath'

export default [
  {
    name: 'WizardInit',
    path: ROUTE_PATHS.WIZARD_INIT,
    exact: true,
    component: WizardInit
  },
  {
    name: 'WizardShow',
    path: ROUTE_PATHS.WIZARD_SHOW,
    exact: true,
    component: WizardShow
  }
]
