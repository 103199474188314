import PropTypes from 'prop-types'
import APIRequestContext from '../../context'
import request from '../../request'

const APIRequestProvider = ({ children }) => {
  return (
    <APIRequestContext.Provider value={request}>
      {children}
    </APIRequestContext.Provider>
  )
}

APIRequestProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default APIRequestProvider
