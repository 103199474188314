import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { OfferView } from 'domains/Offer/components'
import { useHistory, generatePath } from 'react-router-dom'
import ROUTE_PATHS from 'domains/allRoutePath'
import { Col, Row } from '@qonsoll/react-design'

function OffersList(props) {
  const { data, horizontal } = props

  // [ADDITIONAL_HOOKS]
  const history = useHistory()

  // [CLEAN_FUNCTIONS]
  const onItemClick = (id) =>
    history.push(generatePath(ROUTE_PATHS.OFFER_SHOW, { id }))

  return (
    <Fragment>
      {horizontal ? (
        <Row noOuterGutters style={{ overflowX: 'scroll', flexWrap: 'nowrap' }}>
          {data?.map((item) => (
            <Col cw={[12, 5, 4, 3]}>
              <OfferView {...item} onCardClick={() => onItemClick(item.id)} />
            </Col>
          ))}
        </Row>
      ) : (
        <Row negativeBlockMargin>
          {data?.map((item) => (
            <Col cw={[12, 5, 4, 3]}>
              <OfferView {...item} onCardClick={() => onItemClick(item.id)} />
            </Col>
          ))}
        </Row>
      )}
    </Fragment>
  )
}
OffersList.propTypes = {
  data: PropTypes.array.isRequired,
  horizontal: PropTypes.bool
}
export default OffersList
