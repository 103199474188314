import React from 'react'
import PropTypes from 'prop-types'
import { Img } from '@qonsoll/react-design'

const ReadIcon = (props) => {
  const { isRead, size, color = 'dark', ...rest } = props
  return (
    <Img
      src={
        isRead
          ? `/icons/chat-message-seen-${color}.svg`
          : `/icons/chat-message-sent-${color}.svg`
      }
      alt="sent"
      height={size}
      {...rest}
    />
  )
}

ReadIcon.propTypes = {
  isRead: PropTypes.bool,
  size: PropTypes.string,
  color: PropTypes.oneOf(['light', 'dark'])
}

export default ReadIcon
