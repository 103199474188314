import React, { useState, useEffect } from 'react'
import { Box, Col, Row, Avatar, Text } from '@qonsoll/react-design'
import { MessageSimpleView } from 'domains/Chat/components'
import { useMessengerContext } from 'app/contexts/Messenger/hooks'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { UserOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { useUserContext } from 'app/contexts/User/hooks'

function MessageAdvancedView(props) {
  const { messageData, ...rest } = props
  const { chatId, messageId, createdBy, text } = messageData

  // [ADDITIONAL_HOOKS]
  const { messengerState } = useMessengerContext()
  const state = useUserContext()
  const { chatsData, chatsDetail } = messengerState
  const messagePadding = useResponsiveBreakpoints({
    sm: 52,
    md: 52,
    lg: 56,
    xl: 56,
    xxl: 56
  })
  // [COMPONENT_STATE_HOOKS]
  const [avatarURL, setAvatarURL] = useState(false)
  const [displayName, setDisplayName] = useState(false)

  // [USE_EFFECTS]
  useEffect(() => {
    let isComponentMounted = true

    const settingAdditionInfo = () => {
      const indexCurrentMessage = _.indexOf(
        Object.keys(chatsData?.[chatId]),
        messageId
      )
      const chatMessages = Object.values(chatsData?.[chatId])
      const prevMessageCreatedBy =
        chatMessages?.[indexCurrentMessage - 1]?.createdBy
      const nextMessageCreatedBy =
        chatMessages?.[indexCurrentMessage + 1]?.createdBy

      if (createdBy !== state?.id) {
        setDisplayName(
          prevMessageCreatedBy !== createdBy &&
            chatsDetail?.[chatId]?.participantsData?.[createdBy]?.displayName
        )

        setAvatarURL(
          nextMessageCreatedBy !== createdBy &&
            chatsDetail?.[chatId]?.participantsData?.[createdBy]?.avatarURL
        )
      } else {
        setAvatarURL(false)
        setDisplayName(false)
      }
    }

    isComponentMounted && settingAdditionInfo()

    return () => {
      isComponentMounted = false
    }
  }, [
    chatId,
    chatsData,
    chatsDetail,
    createdBy,
    messageData,
    messageId,
    state?.id,
    text
  ])

  if ((avatarURL === '' || !!avatarURL) && displayName)
    return (
      <Row mt={2} noOuterGutters>
        <Col
          cw="auto"
          style={{ flexDirection: 'column', justifyContent: 'flex-end' }}>
          <Box
            height="fit-content"
            border="var(--outline-width) var(--ql-border-style-default) var(--avatar-border-color)"
            borderRadius="var(--ql-border-radius-full)">
            <Avatar
              m="2px"
              icon={<UserOutlined />}
              src={avatarURL}
              //onClick={onAvatarClick}
            />
          </Box>
        </Col>
        <Col pl={0} pr={0}>
          <Row noOuterGutters>
            <Col cw={12} ml={1} mb={1}>
              <Text variant="caption1" color="var(--dark-2)">
                {displayName}
              </Text>
            </Col>
            <Col pl={0} cw={12}>
              <MessageSimpleView messageData={messageData} {...rest} />
            </Col>
          </Row>
        </Col>
      </Row>
    )

  if (avatarURL === '' || avatarURL)
    return (
      <Row noOuterGutters>
        <Col
          cw="auto"
          style={{ flexDirection: 'column', justifyContent: 'flex-end' }}>
          <Box
            height="fit-content"
            border="var(--outline-width) var(--ql-border-style-default) var(--avatar-border-color)"
            borderRadius="var(--ql-border-radius-full)">
            <Avatar
              m="2px"
              icon={<UserOutlined />}
              src={avatarURL}
              //onClick={onAvatarClick}
            />
          </Box>
        </Col>
        <Col pl={0} pr={0}>
          <MessageSimpleView messageData={messageData} {...rest} />
        </Col>
      </Row>
    )

  return (
    <Row ml={messagePadding}>
      {displayName && (
        <Col cw={12} ml={1} mb={1} pl={0}>
          <Text variant="caption1" color="var(--dark-2)">
            {displayName}
          </Text>
        </Col>
      )}
      <Col pl={0} pr={0}>
        <MessageSimpleView messageData={messageData} {...rest} />
      </Col>
    </Row>
  )
}

export default MessageAdvancedView
