import moment from 'moment'

const checkOnline = (lastSeen, setStatusOnline) => {
  if (lastSeen) {
    const now = moment(new Date())
    const last = moment(lastSeen)

    const startTime = moment(last)
    const endTime = moment(now)

    const daysDifference = endTime.diff(startTime, 'days')

    daysDifference > 5 &&
      setStatusOnline(`last seen ${moment(lastSeen).format('DD-MM')}`)

    if (daysDifference > 1 && daysDifference < 5)
      setStatusOnline(`last seen ${daysDifference} days ago`)

    const hoursDifference = endTime.diff(startTime, 'hours')

    if (hoursDifference >= 1 && hoursDifference < 24)
      setStatusOnline(`last seen ${hoursDifference} hours ago`)

    const minutesDifference = endTime.diff(startTime, 'minute')

    if ((minutesDifference >= 3) & (minutesDifference < 60))
      setStatusOnline(`last seen ${minutesDifference} minutes ago`)

    minutesDifference < 3 && setStatusOnline('online')
  }
}
export default checkOnline
