const UIReducer = (state, action) => {
  switch (action.type) {
    case 'SET_STATE': {
      return { ...state, ...action.payload }
    }
    case 'SET_WITH_BACK_BUTTON': {
      return { ...state, onBack: action.payload }
    }
    case 'CHAT_SHOW_PAGE': {
      return { ...state, isChatShowPage: action.payload }
    }
    case 'TOGGLE_SIDEBAR_COLLAPSE': {
      return { ...state, isSidebarCollapsed: action.payload }
    }
    case 'SHOW_ACTIONS': {
      return { ...state, showPageActions: action.payload }
    }
    case 'SET_THEME': {
      return { ...state, theme: action.payload }
    }
    case 'SET_WEB_VIEW_STATE': {
      return { ...state, ...action.payload }
    }
    default: {
      return state
    }
  }
}

export default UIReducer
