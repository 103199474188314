import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Box, Avatar, Card, Text } from '@qonsoll/react-design'
import { List } from 'antd'
import { UserOutlined, RightOutlined } from '@ant-design/icons'

function BuyersList(props) {
  const { data, onBuyerClick } = props

  // [CLEAN FUNCTIONS]
  const onChange = () => {}

  return (
    <List
      split={false}
      dataSource={data}
      onChange={onChange}
      renderItem={(item, index) => (
        <Card
          key={index}
          onClick={() => onBuyerClick?.(item?.id)}
          mb={3}
          size="small"
          bordered={false}
          shadowless
          style={{ cursor: 'pointer' }}>
          <Row noGutters h="center" v="center">
            <Col cw="auto" mr={3}>
              <Box
                height="fit-content"
                border="var(--outline-width) var(--ql-border-style-default) var(--avatar-border-color)"
                borderRadius="var(--ql-border-radius-full)">
                <Avatar
                  m="2px"
                  icon={<UserOutlined />}
                  src={item?.avatarURL}
                  alt="avatar"
                />
              </Box>
            </Col>
            <Col minWidth={0}>
              <Text isEllipsis>{`${item?.firstName ?? ''} ${
                item?.secondName ?? ''
              }`}</Text>
            </Col>
            <Col cw="auto" ml={3}>
              <RightOutlined
                style={{
                  fontSize: 'var(--ql-font-size-h3)',
                  color: 'var(--text-color-secondary)'
                }}
              />
            </Col>
          </Row>
        </Card>
      )}
    />
  )
}

BuyersList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      secondName: PropTypes.string,
      avatarURL: PropTypes.string
    })
  ).isRequired,
  onBuyerClick: PropTypes.func
}

export default BuyersList
