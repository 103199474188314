import { Box, Col, Row, Text } from '@qonsoll/react-design'
import { Table } from 'antd'
import { useTranslations } from '~/app/contexts/Translation/hooks'

function WizardConflictAnswers(props) {
  const { data, columnNames } = props

  const { t } = useTranslations()

  const generateColumns = () => {
    let columns = [
      {
        title: t('Questions'),
        dataIndex: 'question',
        key: 'question'
      }
    ]
    columnNames.forEach((item, ind) => {
      columns.push({ title: item, dataIndex: `name${ind}`, key: `name${ind}` })
    })
    return columns
  }

  return (
    <Row noGutters h={'center'}>
      <Col cw={[12, 8]}>
        <Box
          display="flex"
          justifyContent="start"
          alignItems="center"
          flex={1}
          mb={2}>
          <Text variant="h5">{t('Conflict answers table')}</Text>
        </Box>
      </Col>
      <Col cw={[12, 8]}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flex={1}
          mb={2}>
          <Table
            style={{ width: '100%', borderRadius: '50px' }}
            columns={generateColumns()}
            dataSource={data}
            pagination={false}
          />
        </Box>
      </Col>
    </Row>
  )
}

export default WizardConflictAnswers
