import { message } from 'antd'
import { COLLECTIONS } from 'app/constants'
import { useSessionContext } from 'app/contexts/Session/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import UserContext from 'app/contexts/User/context'
import { useEffect, useReducer } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useDeviceId } from '~/hooks/notifications'
import auth from '~/services/Firebase/auth'
import { getCollectionRef, updateDocument } from '~/services/Firebase/firestore'

const initialState = { isUserDataLoaded: true }

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA': {
      return {
        ...action.payload,
        isUserDataLoaded: false
      }
    }
    default: {
      return initialState
    }
  }
}

const UserProvider = (props) => {
  // [COMPONENT_STATE_HOOKS]
  const [state, dispatch] = useReducer(reducer, initialState)

  // [ADDITIONAL_HOOKS]
  const user = useSessionContext()
  const { t } = useTranslations()

  const [userData, loading] = useDocumentData(
    user && getCollectionRef(COLLECTIONS.USERS).doc(user.uid)
  )

  useDeviceId(userData) // Adding device id to database for logged-in users to manage notifications

  // [USE_EFFECTS]
  useEffect(() => {
    if (userData && userData?.role !== 'YHB') {
      auth.signOut()
      dispatch({ type: 'SET' }) // trigger default switch case to remove loading
      message.error(
        t('Wrong credential or missing access rights to application')
      )
    } else {
      if (!loading) {
        dispatch({
          type: 'SET_DATA',
          payload: userData && Object.keys(userData).length ? userData : null
        })

        // TODO test & refactor condition
        if (user && !userData && !localStorage.getItem('form')) {
          //registration flow
          return auth.getRedirectResult().then(async ({ user }) => {
            if (user) {
              await updateDocument(COLLECTIONS.USERS, user?.uid, {
                id: user?.uid,
                role: 'YHB',
                email: user.email,
                isActivated: false,
                themeMode: 'light'
              })
            } else {
              /* This case need to close session if user delete himself, or
               * somehow user will be delete from firestore. */
              auth.signOut()
            }
          })
        }
      }
    }
  }, [user, userData, loading])

  return (
    <UserContext.Provider
      value={{
        ...state,
        isUserDataLoaded: state.isUserDataLoaded && 'User data is loading',
        dispatch
      }}>
      {props.children(state)}
    </UserContext.Provider>
  )
}

export default UserProvider
