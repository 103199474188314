import React, { useState } from 'react'
import { Form } from 'antd'
import { Col, Container, Row, Button, PageWrapper } from '@qonsoll/react-design'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { ChatGroupFormCreate } from 'domains/Chat/components'
import { useLocation, useHistory } from 'react-router-dom'
import { createGroupChat } from 'domains/Chat/helpers'
import { useUserContext } from 'app/contexts/User/hooks'

const ChatGroupCreate = () => {
  // [ADDITIONAL_HOOKS]
  const [form] = Form.useForm()
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslations()
  const state = useUserContext()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })

  // [COMPONENT_STATE_HOOKS]
  const [loading, setLoading] = useState(false)

  return (
    <PageWrapper
      headingProps={{
        title: t('Create group'),
        titleSize: titleSizes,
        marginBottom: '24px'
      }}
      onBack={goBack}
      isBottomSticky>
      <Container height="inherit" display="grid">
        <Row noGutters h="center">
          <Col cw={[12, 10, 8, 7, 6]}>
            <ChatGroupFormCreate
              form={form}
              onFinish={(values) =>
                createGroupChat(values, setLoading, state, history, location)
              }
            />
            <Button
              mt="auto"
              loading={loading}
              htmlType="submit"
              block
              type="primary"
              onClick={() => form.submit()}>
              {t('Save')}
            </Button>
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  )
}

export default ChatGroupCreate
