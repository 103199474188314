import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ParticipantItem } from 'domains/Chat/components'
import { PageLoading } from '~/app/components'

function ParticipantsList(props) {
  const { participantsData, chatParticipants, chatCreatedBy } = props

  // [COMPONENT_STATE_HOOKS]
  const [filteredParticipantData, setFilteredParticipantData] = useState([])
  const [loading, setLoading] = useState(false)

  // [USE_EFFECTS]
  useEffect(() => {
    let isComponentMounted = true

    const filteringParticipantsData = () => {
      setLoading(true)
      const participantsDataTemp = []

      participantsData.forEach((participant) => {
        /**
         * set participant`s data to array if participant won`t deleted
         * if participant is admin in group - add appropriated value
         */
        if (chatParticipants?.[participant?.userId] !== 'isDeleted')
          participantsDataTemp.push({
            ...participant,
            isAdmin: chatCreatedBy === participant?.userId
          })
      })

      setFilteredParticipantData(participantsDataTemp)

      setLoading(false)
    }

    isComponentMounted && filteringParticipantsData()

    return () => {
      isComponentMounted = false
    }
  }, [participantsData, chatParticipants])

  return (
    <PageLoading loading={loading}>
      {filteredParticipantData?.map((participant) => (
        <ParticipantItem {...participant} />
      ))}
    </PageLoading>
  )
}

ParticipantsList.propTypes = {
  chatCreatedBy: PropTypes.string.isRequired,
  participantsData: PropTypes.arrayOf({
    avatarURL: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    'group-chat-ids': PropTypes.shape.isRequired,
    'chats-by-participant': PropTypes.shape
  }),
  chatParticipants: PropTypes.shape.isRequired
}

export default ParticipantsList
