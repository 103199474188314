import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Select } from 'antd'
import {
  Row,
  Col,
  Input,
  TextArea,
  Button,
  Text,
  Box
} from '@qonsoll/react-design'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { useTranslations } from '~/app/contexts/Translation/hooks'

function BuyingCaseForm(props) {
  const { onFinish, skipAction, buyingCaseTypes } = props

  //[STATE_HOOKS]
  const [type, setType] = useState({})

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  // [CLEAN FUNCTIONS]
  const onChangeSelectType = (_, object) => {
    setType(object.key)
  }
  return (
    <Form
      onFinish={(data) => onFinish(data, type)}
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
      requiredMark={false}
      layout="vertical">
      <Box>
        <Form.Item
          label={t('Buying case name')}
          name="name"
          rules={[
            { required: true, message: t('Enter name please') },
            {
              max: 200,
              message: t('Name should be less than 200 character')
            }
          ]}>
          <Input autoFocus placeholder={t('Enter case name')} />
        </Form.Item>
        <Form.Item
          label={t('Description')}
          name="description"
          rules={[
            {
              max: 2500,
              message: t('Description should be less than 200 character')
            }
          ]}>
          <TextArea placeholder={t('Enter case description')} />
        </Form.Item>

        <Form.Item
          label={t('Type')}
          name="type"
          style={{ width: '100%' }}
          rules={[
            {
              required: true,
              message: t('Choose type, please')
            }
          ]}>
          <Select
            onChange={onChangeSelectType}
            placeholder={t('Type')}
            options={buyingCaseTypes?.map((type) => ({
              value: type?.name,
              key: type,
              label: _.capitalize(t(type?.name))
            }))}
          />
        </Form.Item>
      </Box>
      <Form.List name="links">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Row noGutters v="center" mb="24px">
                <Col cw="auto">
                  <Text style={{ marginRight: 'var(--margin-md' }} type={type}>
                    {t('Link')}
                  </Text>
                </Col>
                <Col mr="4px">
                  <Form.Item
                    {...restField}
                    name={[name]}
                    fieldKey={[fieldKey]}
                    style={{ marginBottom: 0 }}>
                    <Input placeholder={t('Enter link')} />
                  </Form.Item>
                </Col>
                <Col cw="auto">
                  <Button
                    type="text"
                    icon={<MinusCircleOutlined />}
                    onClick={() => remove(name)}
                  />
                </Col>
              </Row>
            ))}
            <Button onClick={() => add()} block icon={<PlusOutlined />} mb={2}>
              {t('Add link')}
            </Button>
          </>
        )}
      </Form.List>
      <Row mt="auto">
        <Col cw={12}>
          <Button block htmlType="submit" type="primary">
            {t('Create')}
          </Button>
        </Col>
        {skipAction && <Col cw={12}>{skipAction}</Col>}
      </Row>
    </Form>
  )
}

BuyingCaseForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  skipAction: PropTypes.node,
  buyingCaseTypes: PropTypes.array.isRequired
}

export default BuyingCaseForm
