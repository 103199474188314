import { useEffect, useState } from 'react'
import { PageWrapper } from '@qonsoll/react-design'
import { PageLoading } from '~/app/components'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import { WizardAnswerList } from '~/app/domains/Wizard/components'
import {
  useBackButton,
  useResponsiveBreakpoints
} from '~/app/contexts/UI/hooks'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import {
  useCollectionData,
  useCollectionDataOnce,
  useDocumentData
} from 'react-firebase-hooks/firestore'
import { getCollectionRef } from '~/services/Firebase/firestore'
import { COLLECTIONS } from '~/app/constants'
import ROUTE_PATHS from 'domains/allRoutePath'
import { message } from 'antd'

function BuyingCaseShowBuyerAnswers() {
  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const { t } = useTranslations()
  const { id, buyerId } = useParams()
  const history = useHistory()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })
  const [wizardProcessing, loadingWizardData] = useDocumentData(
    getCollectionRef(`${COLLECTIONS.WIZARD_PROCESSING}`).doc(id)
  )
  const [form, formLoading] = useCollectionDataOnce(
    wizardProcessing &&
      getCollectionRef(COLLECTIONS.FORM).where(
        'buyingTypeId',
        '==',
        wizardProcessing?.buyingTypeId
      )
  )
  const [questions, loadingQuestions] = useCollectionData(
    form?.[0]?.id &&
      getCollectionRef(COLLECTIONS.QUESTIONS).where(
        'formId',
        '==',
        form?.[0]?.id
      )
  )
  // [COMPONENT_STATE_HOOKS]
  const [loading, setLoading] = useState(false)
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([])

  // [USE_EFFECTS]
  useEffect(() => {
    let isComponentMount = true

    const getQuestionsAndAnswers = () => {
      setLoading(true)
      let questionsText = {}
      questions?.forEach((question) => {
        questionsText[question?.id] = question?.title
      })

      const answersText = wizardProcessing?.answers?.[buyerId]

      let resultArray = []
      for (let qa in answersText)
        resultArray.push({
          answer: answersText?.[qa].value,
          question: questionsText?.[qa]
        })

      setQuestionsAndAnswers(resultArray)
      setLoading(false)
    }

    isComponentMount &&
      !loadingWizardData &&
      !formLoading &&
      !loadingQuestions &&
      getQuestionsAndAnswers()

    return () => {
      isComponentMount = false
    }
  }, [loadingWizardData, wizardProcessing, questions, buyerId])

  useEffect(() => {
    if (!wizardProcessing && !loadingWizardData) {
      message.info(t('Progress wizard was reset'))
      history.replace(
        generatePath(ROUTE_PATHS.BUYING_CASE_SHOW, {
          id
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardProcessing])
  return (
    <PageLoading
      loading={loadingWizardData || loading || formLoading || loadingQuestions}
      height="inherit">
      <PageWrapper
        isBottomSticky
        headingProps={{
          title: t('Buyer`s answers'),
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}>
        <WizardAnswerList data={questionsAndAnswers} />
      </PageWrapper>
    </PageLoading>
  )
}

export default BuyingCaseShowBuyerAnswers
