import { useEffect, useMemo, useRef, useState } from 'react'
import { Box, Button, PageWrapper } from '@qonsoll/react-design'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import firebase from 'firebase'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import {
  getDocumentData,
  updateDocument,
  getCollectionRef
} from '~/services/Firebase/firestore'
import { COLLECTIONS, STATUSES, qFormTranslations } from '~/app/constants'
import ROUTE_PATHS from '~/app/domains/allRoutePath'
import { notificationForFormAnswer } from 'domains/Wizard/helpers'
import {
  AnswersContextProvider,
  FormShow
} from '~/modules/feedback-typeform-app/src'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { PageLoading } from 'app/components'
import { useUserContext } from 'app/contexts/User/hooks'
import { ReloadOutlined } from '@ant-design/icons'

function WizardShow() {
  // [ADDITIONAL HOOKS]
  const wrapperOffsets = useResponsiveBreakpoints({
    sm: 0,
    md: 56,
    lg: 56,
    xl: 56,
    xxl: 56
  })
  const { id } = useParams()
  const { t } = useTranslations()
  const goBack = useBackButton({ returnCallback: true })
  const translations = useMemo(() => {
    qFormTranslations(t)
  }, [t])
  const state = useUserContext()
  const history = useHistory()

  // [COMPONENT_STATE_HOOKS]
  const wrapperRef = useRef(null)
  const [submitLoading, setSubmitLoading] = useState(false)

  const [wizardProcessing, wizardProcessingLoading] = useDocumentData(
    getCollectionRef(COLLECTIONS.WIZARD_PROCESSING).doc(
      history.location.state?.wizardProcessing
    )
  )
  const buyingCaseId = history.location.state?.wizardProcessing
  // [CLEAN_FUNCTIONS]
  const inSameLocation = async ({ answers }) => {
    let formattedAnswers = {}
    const wizardProcessingId = history.location.state?.wizardProcessing
    for (let ans in answers)
      formattedAnswers[ans] = {
        ...answers[ans].answer,
        questionTitle: answers[ans]?.question?.title
      }
    await updateDocument(COLLECTIONS.WIZARD_PROCESSING, wizardProcessingId, {
      answers: formattedAnswers,
      status: STATUSES.FINISHED_TESTING
    })
    const votingData = await getDocumentData(
      COLLECTIONS.VOTING_DATA,
      wizardProcessingId
    )
    votingData.voterAnswersInfo = votingData?.voterUsersInfo?.map((user) => {
      return {
        answer: null,
        id: user?.id
      }
    })
    votingData?.voterAnswersInfo?.unshift({
      ...votingData?.mainUserInfo,
      answer: true
    })

    await updateDocument(
      COLLECTIONS.VOTING_DATA,
      wizardProcessingId,
      votingData
    )
  }

  const inDiffLocation = async ({ answers }) => {
    const wizardData = await getDocumentData(
      COLLECTIONS.WIZARD_PROCESSING,
      history.location.state?.wizardProcessing
    )
    let formattedAnswers = {}
    for (let ans in answers)
      formattedAnswers[ans] = {
        ...answers[ans]?.answer,
        questionTitle: answers[ans]?.question?.title
      }
    await updateDocument(
      COLLECTIONS.WIZARD_PROCESSING,
      history.location.state?.wizardProcessing,
      {
        answers: { ...wizardData?.answers, [state.id]: formattedAnswers }
      }
    )
    const votingData = await getCollectionRef(COLLECTIONS.VOTING_DATA)
      .doc(wizardData?.id)
      .get()

    const editedData = votingData?.data()?.voterUsersInfo?.map((item) => {
      item.answer = null
      item.voted = null

      return item
    })
    await updateDocument(
      COLLECTIONS.VOTING_DATA,
      wizardData?.id,
      { voterUsersInfo: editedData },
      { merge: true }
    )
    const buyingCaseData = await getDocumentData(
      COLLECTIONS.BUYING_CASES,
      history.location.state?.wizardProcessing
    )

    const usersData =
      buyingCaseData &&
      (await getCollectionRef(COLLECTIONS.USERS)
        .where('email', 'in', buyingCaseData?.attendees)
        .get())
    const users = usersData?.docs?.map((user) => user?.data())
    await notificationForFormAnswer(users, state, buyingCaseData)
  }
  const onFinish = async (answers) => {
    setSubmitLoading(true)

    const wizardProcessing = await getDocumentData(
      COLLECTIONS.WIZARD_PROCESSING,
      history.location.state?.wizardProcessing
    )

    if (wizardProcessing?.isSameLocation) {
      await inSameLocation(answers)
      return history.replace(
        generatePath(ROUTE_PATHS.BUYING_CASE_SHOW_ANSWERS, {
          id: history.location.state?.wizardProcessing
        }),
        { formId: id }
      )
    } else {
      await inDiffLocation(answers)
      return history.replace(
        generatePath(ROUTE_PATHS.BUYING_CASE_SHOW_WIZARD_RESULT, {
          id: history.location.state?.wizardProcessing
        }),
        { formId: id }
      )
    }
  }

  const onRestart = () => {
    window.location.reload()
  }

  // [USE_EFFECTS]
  useEffect(() => {
    if (!wizardProcessing && !wizardProcessingLoading) {
      history.replace(
        generatePath(ROUTE_PATHS.BUYING_CASE_SHOW, {
          id: buyingCaseId
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardProcessing])

  return (
    <PageLoading loading={wizardProcessingLoading}>
      <PageWrapper
        onBack={goBack}
        height="inherit"
        action={[
          <Button type="text" onClick={onRestart} icon={<ReloadOutlined />}>
            {t('Restart')}
          </Button>
        ]}>
        <Box ref={wrapperRef} height="inherit">
          <AnswersContextProvider>
            <FormShow
              firebase={firebase}
              id={id}
              actions={{ onFinish }}
              submitLoading={submitLoading}
              translate={t}
              translations={translations}
              wrapperPaddings={0}
              wrapperHeight={`${wrapperRef?.current?.offsetHeight}px`}
              wrapperOffset={wrapperOffsets}
            />
          </AnswersContextProvider>
        </Box>
      </PageWrapper>
    </PageLoading>
  )
}

export default WizardShow
