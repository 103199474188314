import { useEffect, useState } from 'react'
import { Row, Col, Box, Container, PageWrapper } from '@qonsoll/react-design'
import { PageLoading } from 'app/components'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { WizardUserVotingDiffLocation } from 'domains/Wizard/components'
import {
  useCollectionData,
  useDocumentData
} from 'react-firebase-hooks/firestore'
import { getCollectionRef, updateDocument } from '~/services/Firebase/firestore'
import { COLLECTIONS, STATUSES } from '~/app/constants'
import ROUTE_PATHS from '~/app/domains/allRoutePath'
import WizardConflictAnswers from '~/app/domains/Wizard/components/WizardConflictAnswers'
import { notificationForCreatedAgreement } from 'domains/BuyingCase/helpers'
import formatDataForTable from 'domains/BuyingCase/helpers/formatDataForTable'
import { Alert, message } from 'antd'
import Firebase from '~/services/Firebase'
import { useUserContext } from 'app/contexts/User/hooks'

function BuyingCaseShowWizardResult() {
  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const history = useHistory()
  const { id } = useParams()
  const { t } = useTranslations()
  const state = useUserContext()

  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })
  const [buyingCaseData, buyingCaseLoading] = useDocumentData(
    getCollectionRef(COLLECTIONS.BUYING_CASES).doc(id)
  )
  const [usersData, userDataLoading] = useCollectionData(
    buyingCaseData &&
      getCollectionRef(COLLECTIONS.USERS).where(
        'email',
        'in',
        buyingCaseData?.attendees
      )
  )
  const [votingData, votingDataLoading] = useDocumentData(
    getCollectionRef(COLLECTIONS.VOTING_DATA).doc(id)
  )
  const [wizardProcessing, wizardProcessingLoading] = useDocumentData(
    getCollectionRef(COLLECTIONS.WIZARD_PROCESSING).doc(id)
  )
  const [form, formLoading] = useCollectionData(
    buyingCaseData &&
      getCollectionRef(COLLECTIONS.FORM).where(
        'buyingTypeId',
        '==',
        buyingCaseData?.buyingTypeId
      )
  )

  // [COMPONENT_STATE_HOOKS]
  const [createAgreementLoading, setCreateAgreementLoading] = useState(false)
  const [tableColumns, setTableColumns] = useState(null)
  const [tableData, setTableData] = useState(null)
  const [tableLoading, setTableLoading] = useState(false)

  // TODO change to new structure
  const onCreateAgreement = async () => {
    setCreateAgreementLoading(true)

    const answersObj = Object.values(wizardProcessing.answers)[0]
    const docContent = {
      content: []
    }

    const templateAnswers = await getCollectionRef(
      COLLECTIONS.AGREEMENT_TEMPLATES
    )
      .where('buyingTypeId', '==', wizardProcessing?.buyingTypeId)
      .get()
    const templateAnswersData = templateAnswers.docs.map(
      (item) => item.data()?.questions
    )[0]

    templateAnswersData.forEach((item) => {
      const chosenAnswer = answersObj?.[item.id]?.value
      const legalText = item?.answers?.find(
        (current) => current?.answerOption === chosenAnswer
      )
      docContent?.content.push({
        text: legalText?.legalText,
        margin: [0, 0, 0, 5]
      })
    })

    try {
      const func = Firebase.functions().httpsCallable('createAgreement', {
        timeout: 0
      })

      const agreementDoc = await func({
        docName: `${buyingCaseData?.name}${buyingCaseData?.id}`,
        templateId: wizardProcessing?.buyingTypeId,
        content: docContent
      })
      const agreementDocData = agreementDoc?.data
      await updateDocument(COLLECTIONS.WIZARD_PROCESSING, id, {
        status: STATUSES.AGREEMENT_CREATED,
        agreementURL: agreementDocData?.sourceURL,
        agreementName: agreementDocData?.filename
      })
      await notificationForCreatedAgreement(usersData, state, buyingCaseData)
      message.success(t('Agreement was successfully created'))

      history.replace(
        generatePath(ROUTE_PATHS.BUYING_CASE_SHOW, {
          id
        })
      )
    } catch (err) {
      console.log(err)
      message.error(t('Error occurred during agreement document creation'))
    }
    setCreateAgreementLoading(false)
  }
  const onResetAnswers = async () => {
    delete wizardProcessing?.answers?.[state.id]
    await updateDocument(
      COLLECTIONS.WIZARD_PROCESSING,
      id,
      { answers: wizardProcessing?.answers },
      { merge: false }
    )
    await onResetVoting()
    history.replace(
      generatePath(ROUTE_PATHS.WIZARD_SHOW, {
        id: form?.[0]?.id
      }),
      { wizardProcessing: id }
    )
  }

  const onResetVoting = async () => {
    const votingData = await getCollectionRef(COLLECTIONS.VOTING_DATA)
      .doc(id)
      .get()

    const editedData = votingData.data().voterUsersInfo.map((item) => {
      item.answer = null
      item.voted = null

      return item
    })
    await updateDocument(
      COLLECTIONS.VOTING_DATA,
      id,
      { voterUsersInfo: editedData },
      { merge: true }
    )
  }
  const onAgreeWithAnswers = async () => {
    const votingData = await getCollectionRef(COLLECTIONS.VOTING_DATA)
      .doc(id)
      .get()

    const editedData = votingData.data().voterUsersInfo.map((item) => {
      if (item?.id === state.id) {
        item.answer = true
        item.voted = true
      }
      return item
    })
    await updateDocument(
      COLLECTIONS.VOTING_DATA,
      id,
      { voterUsersInfo: editedData },
      { merge: true }
    )
  }
  const onDisagreeWithAnswers = async () => {
    const votingData = await getCollectionRef(COLLECTIONS.VOTING_DATA)
      .doc(id)
      .get()

    const editedData = votingData.data().voterUsersInfo.map((item) => {
      if (item?.id === state.id) {
        item.answer = false
        item.voted = true
      }
      return item
    })
    await updateDocument(
      COLLECTIONS.VOTING_DATA,
      id,
      { voterUsersInfo: editedData },
      { merge: true }
    )
  }

  const onBuyerClick = (buyerId = id) => {
    history.push(
      generatePath(ROUTE_PATHS.BUYING_CASE_SHOW_BUYER_ANSWERS, {
        id,
        buyerId: buyerId
      })
    )
  }

  // [USE_EFFECTS]
  useEffect(() => {
    wizardProcessing?.answers &&
      form &&
      Object.values(wizardProcessing?.answers)?.length &&
      formatDataForTable(
        wizardProcessing?.answers,
        form?.[0]?.id,
        setTableLoading,
        setTableColumns,
        setTableData
      )
  }, [wizardProcessing, form])

  useEffect(() => {
    if (wizardProcessing?.status === STATUSES.AGREEMENT_CREATED) {
      message.success(t('Agreement was created successfully'))
      history.replace(
        generatePath(ROUTE_PATHS.BUYING_CASE_SHOW, {
          id
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardProcessing?.status])

  useEffect(() => {
    if (wizardProcessing?.answers === null) {
      history.replace(
        generatePath(ROUTE_PATHS.BUYING_CASE_SHOW, {
          id
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardProcessing?.answers])

  useEffect(() => {
    if (!wizardProcessing && !wizardProcessingLoading) {
      message.info(t('Progress wizard was reset'))
      history.replace(
        generatePath(ROUTE_PATHS.BUYING_CASE_SHOW, {
          id
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardProcessing])

  // Counter of approved answers
  const isVotingEnded =
    votingData &&
    votingData?.voterUsersInfo?.length &&
    votingData?.voterUsersInfo
      ?.map((item) => item?.answer)
      ?.reduce((acc, curr) => acc && curr)

  return (
    <PageLoading
      loading={
        createAgreementLoading ||
        wizardProcessingLoading ||
        tableLoading ||
        userDataLoading ||
        buyingCaseLoading ||
        votingDataLoading ||
        formLoading
      }
      loadingText={t('Loading')}
      height="inherit">
      <PageWrapper
        isBottomSticky
        headingProps={{
          title: t('Survey answers'),
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}>
        <Container
          height="inherit"
          display="flex"
          style={{
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
          {tableData?.length > 0 && (
            <Row noGutters v={'center'} h={'center'} pb={3}>
              <Col cw={'auto'} maxWidth="fit-content">
                <Alert
                  message={t(
                    "You can't create agreement, because you have conflict in answers"
                  )}
                  type="error"
                />
              </Col>
            </Row>
          )}
          {!isVotingEnded && (
            <Row noGutters v={'center'} h={'center'} pb={3}>
              <Col cw={'auto'} maxWidth="fit-content">
                <Alert
                  message={t(
                    "You can't create agreement, because voting is not ended"
                  )}
                  type="error"
                />
              </Col>
            </Row>
          )}
          <Box pb={3}>
            {tableColumns && tableData && (
              <WizardConflictAnswers
                columnNames={tableColumns}
                data={tableData}
              />
            )}
          </Box>
          <WizardUserVotingDiffLocation
            tableData={tableData}
            votingData={votingData}
            usersData={usersData}
            onBuyerClick={onBuyerClick}
            onCreateAgreement={onCreateAgreement}
            onResetAnswers={onResetAnswers}
            onResetVoting={onResetVoting}
            onAgreeWithAnswers={onAgreeWithAnswers}
            onDisagreeWithAnswers={onDisagreeWithAnswers}
          />
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default BuyingCaseShowWizardResult
