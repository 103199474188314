import { Box, Col, Row, Button, Text } from '@qonsoll/react-design'

import { useTranslations } from '~/app/contexts/Translation/hooks'
import { useEffect } from 'react'

function WizardMainUserDialog(props) {
  const { onYesClick, onNoClick, setHeader } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  // [USE_EFFECTS]
  useEffect(
    () => {
      setHeader(t('Choose main user'))
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <Row noGutters v="center">
      <Col cw={12} mb={3}>
        <Box display="flex" flex={1} justifyContent="center">
          <Text>{t('Do you want to continue as a main user?')}</Text>
        </Box>
      </Col>
      <Col cw={12}>
        <Box display="flex" flex={1} justifyContent="center">
          <Col cw="auto">
            <Button type="primary" onClick={onYesClick}>
              {t('Yes')}
            </Button>
          </Col>
          <Col cw="auto">
            <Button onClick={onNoClick}>{t('No')}</Button>
          </Col>
        </Box>
      </Col>
    </Row>
  )
}

export default WizardMainUserDialog
