import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Button } from 'antd'
import { Row, Col, Link } from '@qonsoll/react-design'
import { useTranslations } from '~/app/contexts/Translation/hooks'

function LoginForm(props) {
  const { onForgotPassword, ...rest } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  return (
    <Form {...rest}>
      <Form.Item
        name="email"
        rules={[
          {
            type: 'email',
            required: true,
            message: t('Please enter valid email')
          }
        ]}>
        <Input autoFocus placeholder={t('Email')} />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          { required: true, message: t('Incorrect password') },
          { min: 6, message: t('At least 6 symbols') }
        ]}>
        <Input.Password placeholder={t('Password')} />
      </Form.Item>
      <Row noGutters v="center">
        <Col>
          <Link onClick={onForgotPassword}>{t('Forgot password?')}</Link>
        </Col>
        <Col>
          <Button block type="primary" htmlType="submit">
            {t('Login')}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

LoginForm.propTypes = {
  onFinish: PropTypes.func,
  onForgotPassword: PropTypes.func
}

export default LoginForm
