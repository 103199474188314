import { useReducer } from 'react'
import PropTypes from 'prop-types'
import messengerReducer from '../../MessengerReducer'
import MessengerContext from '../../context'

const MessengerProvider = (props) => {
  const { children, ...rest } = props

  // [COMPONENT_STATE_HOOKS]
  const [messengerState, messengerDispatch] = useReducer(messengerReducer, {
    chatsData: {},
    currentChatId: false,
    chatsDetail: {},
    editMessage: false
  })

  return (
    <MessengerContext.Provider
      value={{
        messengerState: {
          ...messengerState
        },
        messengerLoading: false,
        messengerDispatch
      }}
      {...rest}>
      {children}
    </MessengerContext.Provider>
  )
}

MessengerProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default MessengerProvider
