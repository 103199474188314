import React, { useEffect, useState } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'
import {
  useCollectionData,
  useCollectionDataOnce,
  useDocumentData
} from 'react-firebase-hooks/firestore'
import { message, List, Button, Space, Popconfirm } from 'antd'
import {
  Row,
  Col,
  Box,
  Edit,
  PageWrapper,
  Title,
  Link,
  Text,
  Paragraph
} from '@qonsoll/react-design'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import {
  deleteDocument,
  getCollectionRef,
  getDocumentData,
  updateDocument
} from '~/services/Firebase/firestore'
import { PageLoading, PDFViewer, Remove } from 'app/components'
import { BuyingCaseView, SSNCodeModalForm } from 'domains/BuyingCase/components'
import { OffersList } from 'domains/Offer/components'
import ROUTE_PATHS from 'domains/allRoutePath'
import { COLLECTIONS, STATUSES } from 'app/constants'
import { RedoOutlined } from '@ant-design/icons'
import { BuyingCaseShowStatuses } from 'domains/BuyingCase/components/BuyingCaseShowStatuses'
import { useUserContext } from 'app/contexts/User/hooks'
import { useBuyingCaseCache } from '~/app/contexts/Cache/hooks'
import { POSTRequestOptions } from '../../__constants__/requestOptions'
import { useAPIRequestContext } from '~/app/contexts/APIRequest/hooks'
import auth from '~/services/Firebase/auth'
import { generateAgreementDocument } from '~/app/domains/Wizard/helpers'

const TITLE_SIZE_CONFIG = {
  sm: 4,
  md: 3,
  lg: 3,
  xl: 3,
  xxl: 3
}

function BuyingCaseShow() {
  // [ADDITIONAL HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const buttonsGutters = useResponsiveBreakpoints({
    sm: true,
    md: false,
    lg: false,
    xl: false,
    xxl: false
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const { id } = useParams()
  const history = useHistory()
  const { t } = useTranslations()
  const state = useUserContext()
  const [buyingCaseData, buyingCaseDataLoading] = useDocumentData(
    getCollectionRef(COLLECTIONS.BUYING_CASES).doc(id)
  )
  const [offersData, offersDataLoading] = useCollectionDataOnce(
    getCollectionRef(COLLECTIONS.OFFERS)
  )
  const [usersData, usersDataLoading] = useCollectionData(
    buyingCaseData &&
      getCollectionRef(COLLECTIONS.USERS).where(
        'email',
        'in',
        buyingCaseData?.attendees
      )
  )
  const [wizardProcessingData, wizardProcessingLoading] = useDocumentData(
    buyingCaseData && getCollectionRef(COLLECTIONS.WIZARD_PROCESSING).doc(id)
  )
  const [form, formLoading] = useCollectionData(
    buyingCaseData &&
      getCollectionRef(COLLECTIONS.FORM).where(
        'buyingTypeId',
        '==',
        buyingCaseData?.buyingTypeId
      )
  )
  const [agreementTemplate, agreementTemplateLoading] = useCollectionData(
    buyingCaseData &&
      getCollectionRef(COLLECTIONS.AGREEMENT_TEMPLATES).where(
        'buyingTypeId',
        '==',
        buyingCaseData?.buyingTypeId
      )
  )
  const titleSizes = useResponsiveBreakpoints(TITLE_SIZE_CONFIG)

  const buyingCaseCache = useBuyingCaseCache()

  const requester = useAPIRequestContext()

  // [COMPONENT STATE HOOKS]
  const [showAgreement, setShowAgreement] = useState(false)
  const [agreementSigningVisibility, setAgreementSigningVisibility] =
    useState(false)
  const [wasCreatedId, setWasCreatedId] = useState('')

  // [CLEAN FUNCTIONS]
  const onRemove = async () => {
    try {
      //TODO remove agreement from collection documents and from storage too
      await deleteDocument(COLLECTIONS.BUYING_CASES, id)
      await deleteDocument(COLLECTIONS.WIZARD_PROCESSING, id)
      await deleteDocument(COLLECTIONS.VOTING_DATA, id)
      message.success(t('Buying case was deleted successfully'))
      history.push(ROUTE_PATHS.BUYING_CASES_ALL)
      buyingCaseCache.dispatch({ type: 'REMOVE_DOC', payload: id })
    } catch (error) {
      message.error(t('Buying case not was deleted'))
    }
  }
  const onEditBuyingCase = () => {
    const editPath = generatePath(ROUTE_PATHS.BUYING_CASE_EDIT, { id })
    history.push(editPath)
  }

  const onEditUser = () => {
    const editAttendees = generatePath(
      ROUTE_PATHS.BUYING_CASE_INVITE_ATTENDEES_EDIT,
      { id }
    )
    history.push(editAttendees)
  }
  //  const onShare = () => {}

  // const createAgreement = async () => {
  //   const signers = await getCollectionRef(COLLECTIONS.USERS)
  //     .where('email', 'in', data?.attendees)
  //     .get()
  //   const signersIDs = signers.docs.map((item) => item.data().id)
  //   await generateAgreementDocument(state.id, signersIDs)
  // }

  const onWizardStart = async () => {
    const wizardData = await getDocumentData(COLLECTIONS.WIZARD_PROCESSING, id)
    if (!wizardData || (!wizardData.mainUserId && wizardData.isSameLocation)) {
      const buyers = await getCollectionRef(COLLECTIONS.USERS)
        .where('email', 'in', buyingCaseData?.attendees)
        .get()
      const buyerIds = buyers.docs.map((item) => item?.id)
      await updateDocument(
        COLLECTIONS.WIZARD_PROCESSING,
        id,
        {
          id,
          status: STATUSES.INIT,
          isSameLocation: wizardData?.isSameLocation || null,
          buyerIds,
          mainUserId: null,
          buyingTypeId: buyingCaseData?.buyingTypeId,
          answers: null
        },
        { withoutUndef: false }
      )
    } else if (wizardData.isSameLocation === false) {
      /*
        If users already passed test
        */
      if (wizardData?.answers?.[state.id]) {
        return history.push(
          generatePath(ROUTE_PATHS.BUYING_CASE_SHOW_WIZARD_RESULT, { id })
        )
      }

      /*
       Case where buyers go through test individual.
       Redirect them directly to typeform.
       */
      return history.push(
        generatePath(ROUTE_PATHS.WIZARD_SHOW, {
          id: form?.[0]?.id
        }),
        { wizardProcessing: id }
      )
    }
    //Redirect buyers to initial voting.
    history.push(generatePath(ROUTE_PATHS.WIZARD_INIT, { id }))
  }

  // const onAgreementShow = () => {
  //   window.open(wizardProcessingData?.agreementURL)
  // }

  const onOkConfirmReset = async () => {
    try {
      await deleteDocument(COLLECTIONS.WIZARD_PROCESSING, id)
      await deleteDocument(COLLECTIONS.VOTING_DATA, id)
      message.success(t('Progress was successfully reset'))
    } catch (error) {
      message.error(t('Progress was not reset'))
    }
  }

  // Adding ssn to sign agreement
  const onAgreementSign = async (values) => {
    try {
      const token = await auth.currentUser.getIdToken()
      const myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/json')
      myHeaders.append('Authorization', token)
      myHeaders.append('Accept', '*')
      const body = JSON.stringify({ id: state.id, data: values?.code })
      const response = await requester(
        `${process.env.REACT_APP_REST_API_URL}/rest/data/encrypt`,
        POSTRequestOptions(myHeaders, body)
      )
      const responseData = await response?.text()
      await updateDocument(
        COLLECTIONS.WIZARD_PROCESSING,
        wizardProcessingData?.id,
        { codes: { ...wizardProcessingData?.codes, [state.id]: responseData } }
      )
    } catch (e) {
      console.log(e)
      message.error(e.message)
    }
    setAgreementSigningVisibility(false)
  }

  // Request on API triggering signing service
  const sendAgreementOnSigningService = async () => {
    try {
      const agreement = await generateAgreementDocument(
        state.id,
        wizardProcessingData.buyerIds,
        `${wizardProcessingData?.agreementName}.pdf`,
        wizardProcessingData?.agreementURL
      )
      await updateDocument(COLLECTIONS.BUYING_CASES, id, {
        agreementId: agreement?.id
      })
      message.success(t('Successfully sent agreement to signing service'))
    } catch (e) {
      message.error(t('Error occurred during signing agreement'))
      console.log(e.message)
    }
  }
  const showMessage = (wasCreatedId) => {
    if (state?.id !== wasCreatedId) {
      return message.info(t('Your buying case was deleted'))
    }
  }
  // [USE_EFFECTS]
  useEffect(() => {
    if (buyingCaseData === undefined && !buyingCaseDataLoading) {
      showMessage(wasCreatedId)
      history.push(ROUTE_PATHS.BUYING_CASES_ALL)
    }
  }, [buyingCaseData, buyingCaseDataLoading])

  useEffect(() => {
    setWasCreatedId(buyingCaseData?.wasCreatedId)
  }, [buyingCaseData])

  // [COMPUTED PROPERTIES]

  // Property to check whether agreement is ready to be sent to signing service
  // Agreement is ready to be sent to signing service only
  // if all buyers have entered their ssn codes
  const isReadyToBeSigned =
    wizardProcessingData &&
    !_.difference(
      wizardProcessingData?.buyerIds,
      wizardProcessingData?.codes && Object.keys(wizardProcessingData?.codes)
    ).length

  return (
    <PageLoading
      loading={
        buyingCaseDataLoading ||
        offersDataLoading ||
        formLoading ||
        usersDataLoading ||
        wizardProcessingLoading ||
        agreementTemplateLoading
      }
      height="inherit">
      <PageWrapper
        headingProps={{
          title: (
            <BuyingCaseView
              wizardProcessingData={wizardProcessingData}
              data={buyingCaseData}
              onEditUser={onEditUser}
            />
          ),
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}
        action={[
          // <Button type="text" icon={<ShareAltOutlined />} onClick={onShare} />,
          <Space>
            {buyingCaseData?.wasCreatedId === state.id &&
              wizardProcessingData?.status !== STATUSES.AGREEMENT_CREATED && (
                <Edit
                  type="text"
                  tooltip={t('Edit')}
                  onClick={onEditBuyingCase}
                  icon
                />
              )}
            {buyingCaseData?.wasCreatedId === state.id &&
              wizardProcessingData?.status !== STATUSES.AGREEMENT_CREATED && (
                <Popconfirm
                  title={t('Are you sure to reset progress on buying case?')}
                  onConfirm={onOkConfirmReset}
                  okText={t('Yes, reset progress')}
                  cancelText={t('No, keep case')}>
                  <Edit
                    type="text"
                    tooltip={t('Reset')}
                    icon={<RedoOutlined />}
                  />
                </Popconfirm>
              )}
            {buyingCaseData?.wasCreatedId === state.id && (
              <Remove
                type="text"
                shape={'default'}
                onSubmit={onRemove}
                popconfirmPlacement={'bottomRight'}
                itemName={t('case')}
                icon
              />
            )}
          </Space>
        ]}>
        <Row noGutters>
          <Col cw={12} mb={3}>
            <BuyingCaseShowStatuses
              wizardProcessingData={wizardProcessingData}
              usersData={usersData}
              agreementTemplate={agreementTemplate}
              buyingCaseData={buyingCaseData}
              form={form}
            />
          </Col>
          <Col cw={12} mb={3}>
            <Paragraph whiteSpace="pre-line">
              {buyingCaseData?.description}
            </Paragraph>
          </Col>
          {!!buyingCaseData?.links?.length && (
            <Col cw={12} display="block" mb={3}>
              <List
                dataSource={buyingCaseData?.links}
                renderItem={(link) => (
                  <Box
                    overflow="hidden"
                    style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <Link href={link} target="_blank">
                      <Text color="var(--ql-color-accent1)">{link}</Text>
                    </Link>
                  </Box>
                )}
                split={false}
              />
            </Col>
          )}
          {wizardProcessingData?.status === STATUSES.AGREEMENT_CREATED &&
            !isReadyToBeSigned && (
              <Row
                display="flex"
                flex={1}
                noGutters={buttonsGutters}
                noOuterGutters={!buttonsGutters}
                mb={3}>
                <Col cw={[12, 6]} mb={buttonsGutters && 3}>
                  <Button
                    size="large"
                    block
                    type="default"
                    onClick={() => setShowAgreement(true)}>
                    {t('Show agreement')}
                  </Button>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center">
                    <PDFViewer
                      pdf={wizardProcessingData?.agreementURL}
                      onCancel={() => setShowAgreement(false)}
                      visible={showAgreement}
                    />
                  </Box>
                </Col>
                <Col cw={[12, 6]}>
                  <Button
                    disabled={wizardProcessingData?.codes?.[state?.id]}
                    size="large"
                    block
                    type="primary"
                    onClick={() => setAgreementSigningVisibility(true)}>
                    {t('Add signature')}
                  </Button>
                  <Box display="flex">
                    <SSNCodeModalForm
                      visible={agreementSigningVisibility}
                      onFinish={onAgreementSign}
                      onCancel={() => setAgreementSigningVisibility(false)}
                    />
                  </Box>
                </Col>
              </Row>
            )}
          {isReadyToBeSigned && buyingCaseData?.agreementId?.length === 1 && (
            <Col cw={12} mb={3}>
              <Button
                size="large"
                block
                type="primary"
                onClick={sendAgreementOnSigningService}>
                {t('Send agreement on signing service')}
              </Button>
            </Col>
          )}
          {buyingCaseData?.attendees?.length > 1 &&
            agreementTemplate?.length === 1 &&
            form?.length === 1 &&
            usersData?.length === buyingCaseData?.attendees?.length &&
            wizardProcessingData?.status !== STATUSES.AGREEMENT_CREATED && (
              <Box py={24} display="flex" flex={1}>
                <Button
                  size="large"
                  block
                  type="primary"
                  onClick={onWizardStart}>
                  {t('Start wizard')}
                </Button>
              </Box>
            )}
        </Row>
        {!!offersData?.length && (
          <Row noGutters>
            <Col cw={12} mb={2}>
              <Title level={4}>{t('Offers (suggested)')}</Title>
            </Col>
            <Col cw={12}>
              <OffersList data={offersData} horizontal />
            </Col>
          </Row>
        )}
      </PageWrapper>
    </PageLoading>
  )
}
export default BuyingCaseShow
