import React, { useEffect, useRef, useState } from 'react'
import { Form, message } from 'antd'
import { Col, Row, Text } from '@qonsoll/react-design'
import { Spinner } from 'app/components'
import { MessageForm, MessagesList } from 'app/domains/Chat/components'
import { editMessageFinish, onEditClick } from 'domains/Chat/helpers'
import {
  useGetMessages,
  useMessengerContext
} from '~/app/contexts/Messenger/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { TYPES } from '~/app/contexts/Messenger/constants'
import { getReferenceChild } from '~/services/Firebase/database'
import { COLLECTIONS } from '~/app/constants'
import moment from 'moment'
import firebase from '~/services/Firebase/init'
import { createDocument } from 'services/Firebase/firestore'
import logLastActivity from '../../helpers/logLastActivity'
import { useUserContext } from 'app/contexts/User/hooks'

function GroupChat(props) {
  const { currentChatDetail } = props

  const { chatId, chatParticipants, name, avatarURL } = currentChatDetail

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const messagesLoading = useGetMessages(chatId)
  const state = useUserContext()
  const [form] = Form.useForm()
  const inputRef = useRef(null)
  const { messengerState, messengerLoading, messengerDispatch } =
    useMessengerContext()

  // [COMPONENT_STATE_HOOKS]
  const [participantsData, setParticipantsData] = useState({})

  // [COMPUTED_PROPERTIES]
  const { chatsData, chatsDetail } = messengerState

  // [CLEAN_FUNCTIONS]
  const onDeleteFinish = async (messageData) => {
    await messengerDispatch({
      type: TYPES.DELETE_MESSAGE,
      payload: messageData
    })

    const {
      messageId,
      chatId,
      isLastMessageInList,
      prevMessageData,
      seenByCurrentMessage
    } = messageData
    const chatsByIdRef = `${COLLECTIONS.CHATS}/${COLLECTIONS.CHATS_BY_ID}/${chatId}`

    try {
      /**
       * deleting message by id from collection
       */

      getReferenceChild(
        `${COLLECTIONS.CHATS}/${COLLECTIONS.MESSAGES_BY_CHAT}/${chatId}/${messageId}`
      ).remove()

      let participantUnreadMessage = {}

      Object.keys(seenByCurrentMessage).forEach((id) => {
        if (id !== state?.id && !seenByCurrentMessage[id]) {
          participantUnreadMessage[id] =
            typeof chatParticipants[id] === 'number'
              ? chatParticipants[id] - 1
              : 'isDeleted'
        }
      })

      await getReferenceChild(
        `${chatsByIdRef}/${COLLECTIONS.CHAT_PARTICIPANTS}`
      ).update({ ...participantUnreadMessage })

      if (isLastMessageInList) {
        /**
         *  if message is last in list
         *  message has maximum index
         */
        if (prevMessageData) {
          /**
           *  if message is no first element in array
           */
          await getReferenceChild(
            `${chatsByIdRef}/${COLLECTIONS.LAST_MESSAGE_DATA}`
          ).update({
            createdAt: prevMessageData?.createdAt,
            createdBy: prevMessageData?.createdBy,
            messageId: prevMessageData?.messageId,
            text: prevMessageData?.text,
            date: prevMessageData?.date,
            isRead:
              prevMessageData?.seenBy &&
              Object.values(prevMessageData.seenBy)?.includes(true)
          })
          message.success(t('Message was deleted successfully'))
        }
        if (
          !prevMessageData &&
          Object.keys(chatsData?.[chatId])[0] === messageId &&
          Object.keys(chatsData?.[chatId]).length < 2
        ) {
          /**
           * if message is last in list (list has only it message)
           */
          await getReferenceChild(
            `${chatsByIdRef}/${COLLECTIONS.LAST_MESSAGE_DATA}`
          ).update({
            text: 'no messages'
          })
          message.success(t('Message was deleted successfully'))
        }
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  const onFinishSend = async (values) => {
    if (values?.text && values?.text.split(' ').join('') !== '') {
      localStorage.setItem(chatId, '')
      /**
       * get message id
       */
      const refCurrentChat = `${COLLECTIONS.CHATS}/${COLLECTIONS.MESSAGES_BY_CHAT}/${chatId}`
      const messageId = getReferenceChild(`${refCurrentChat}`).push().key
      const lastMessageData = {
        chatId: chatId,
        messageId,
        text: values.text,
        createdBy: state.id,
        date: moment().format('DD-MM-YYYY')
      }
      /**
       * set message to chat context
       */
      messengerDispatch({
        type: TYPES.SEND_MESSAGE,
        payload: lastMessageData
      })

      try {
        /**
         * set online for current user
         */
        await logLastActivity(state?.id)
        /**
         * set chat-details
         */
        await getReferenceChild(
          `${COLLECTIONS.CHATS}/${COLLECTIONS.CHATS_BY_ID}/${chatId}/${COLLECTIONS.LAST_MESSAGE_DATA}`
        ).update({
          ...lastMessageData,
          createdAt: firebase.database.ServerValue.TIMESTAMP,
          isRead: false
        })
        /**
         * setting new count unread messages for participants
         */
        let countUnreadMessagesForParticipants = {}

        Object.values(participantsData).forEach((data) => {
          countUnreadMessagesForParticipants[data.userId] =
            data.userId === state?.id ||
            typeof chatParticipants[data.userId] === 'string'
              ? chatParticipants[data.userId]
              : chatParticipants[data.userId] + 1
        })
        await getReferenceChild(
          `${COLLECTIONS.CHATS}/${COLLECTIONS.CHATS_BY_ID}/${chatId}/${COLLECTIONS.CHAT_PARTICIPANTS}`
        ).update(countUnreadMessagesForParticipants)

        /**
         * set message to collection messages-by-chat
         */
        let isReadMessageForParticipants = {}

        Object.values(participantsData).forEach((data) => {
          isReadMessageForParticipants[data.userId] = false
        })
        await getReferenceChild(`${refCurrentChat}/${messageId}`).set({
          ...lastMessageData,
          createdAt: firebase.database.ServerValue.TIMESTAMP,
          seenBy: isReadMessageForParticipants
        })

        for (const participantId of Object.keys(chatParticipants)) {
          if (participantId !== state?.id) {
            const notificationData = {
              text: values.text,
              title: name,
              senderAvatarUrl: avatarURL || ' ',
              userId: participantId
            }
            await createDocument(COLLECTIONS.NOTIFICATIONS, notificationData)
          }
        }
      } catch (error) {
        message.error(error.message)
        /**
         * changing message`s status if error was occurred
         */
        messengerDispatch({
          type: TYPES.CHANGE_MESSAGE_STATUS,
          payload: {
            chatId: chatId,
            messageId: messageId,
            status: 'error'
          }
        })
      }
    }
  }
  const setRead = async (chatId, messageId) => {
    const messageByChatRef = `${COLLECTIONS.CHATS}/${COLLECTIONS.MESSAGES_BY_CHAT}/${chatId}/${messageId}`
    const chatByIdRef = `${COLLECTIONS.CHATS}/${COLLECTIONS.CHATS_BY_ID}/${chatId}`

    // Set read status to true for current user.
    await getReferenceChild(`${messageByChatRef}/seenBy`).update({
      [state?.id]: true
    })

    // Change unread message counter
    await getReferenceChild(
      `${chatByIdRef}/${COLLECTIONS.CHAT_PARTICIPANTS}`
    ).update({ [state?.id]: 0 })

    await getReferenceChild(
      `${chatByIdRef}/${COLLECTIONS.LAST_MESSAGE_DATA}`
    ).update({ isRead: true })

    await logLastActivity(state?.id)
  }

  // [USE_EFFECTS]
  useEffect(() => {
    // setting participants data
    let isComponentMounted = true

    const getParticipantsData = () => {
      const data = {}
      const participantIds = Object.keys(chatParticipants)

      const getParticipantData = async (participantId) => {
        await getReferenceChild(
          `${COLLECTIONS.CHATS}/${COLLECTIONS.PARTICIPANTS}/${participantId}`
        ).on('value', (snapshot) => {
          data[participantId] = snapshot.val()
          setParticipantsData({ ...data, [participantId]: snapshot.val() })
        })
      }

      participantIds.forEach((participantId) =>
        getParticipantData(participantId)
      )
    }

    isComponentMounted && getParticipantsData()

    return () => {
      isComponentMounted = false
    }
  }, [chatId, chatParticipants, state?.id])

  useEffect(() => {
    let isComponentMounted = true
    /**
     * setting participantsData to messenger state if got every participant data
     */
    isComponentMounted &&
      Object.keys(participantsData).length ===
        Object.keys(chatParticipants).length &&
      !chatsDetail?.[chatId]?.participantsData &&
      messengerDispatch({
        type: TYPES.SET_PARTICIPANTS_DATA_TO_CHAT_DETAIL,
        payload: { chatId, participantsData }
      })

    return () => {
      isComponentMounted = false
    }
  }, [
    chatId,
    chatsDetail,
    chatParticipants,
    messengerDispatch,
    participantsData
  ])

  return (
    <>
      {messagesLoading || !chatsData[chatId] ? (
        <Row height="100%" h="center" v="center">
          <Col cw="auto">
            {messagesLoading ? (
              <Spinner />
            ) : (
              <Text
                color="var(--message-color)"
                fontSize="var(--ql-font-size-h5)">
                {t('Start a chat')}
              </Text>
            )}
          </Col>
        </Row>
      ) : (
        <MessagesList
          setRead={setRead}
          chatId={chatId}
          onEditClick={(messageData) =>
            onEditClick(messageData, messengerDispatch, inputRef)
          }
          onDeleteFinish={onDeleteFinish}
          isGroup
        />
      )}

      <MessageForm
        disabled={messagesLoading && chatsData[chatId]}
        onEditFinish={(values) =>
          editMessageFinish(values, chatId, messengerState, messengerDispatch)
        }
        onFinishSend={onFinishSend}
        chatId={chatId}
        form={form}
        inputRef={inputRef}
        loading={messengerLoading}
      />
    </>
  )
}

export default GroupChat
