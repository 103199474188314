import React from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input } from 'antd'
import { useTranslations } from '~/app/contexts/Translation/hooks'

const SignupForm = (props) => {
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  return (
    <Form {...props}>
      <Form.Item
        name="email"
        rules={[
          {
            type: 'email',
            required: true,
            message: t('Please enter valid email')
          }
        ]}>
        <Input autoFocus placeholder={t('Email')} />
      </Form.Item>
      <Form.Item
        name="password"
        hasFeedback
        rules={[
          { required: true, message: t('Incorrect password') },
          { min: 6, message: t('At least 6 symbols') }
        ]}>
        <Input.Password placeholder={t('Password')} />
      </Form.Item>
      <Form.Item
        name="confirm"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: t('Please confirm your password!')
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error(t('The two passwords that you entered do not match!'))
              )
            }
          })
        ]}>
        <Input.Password placeholder={t('Confirm password')} />
      </Form.Item>
      <Button type="primary" htmlType="submit" block>
        {t('Sign up')}
      </Button>
    </Form>
  )
}

SignupForm.propTypes = {
  onFinish: PropTypes.func.isRequired
}

export default SignupForm
