import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'antd'
import { Row, Col, Card, Text } from '@qonsoll/react-design'
import { useHistory, generatePath } from 'react-router-dom'
import ROUTE_PATHS from 'domains/allRoutePath'
import { BuyingCaseAttendeesList } from 'domains/BuyingCase/components'

function BuyingCasesList(props) {
  const { data } = props

  // [ADDITIONAL_HOOKS]
  const history = useHistory()

  // [CLEAN FUNCTIONS]
  const onItemSelected = () => {}
  const onItemClick = (id) => {
    const itemPath = generatePath(ROUTE_PATHS.BUYING_CASE_SHOW, { id })
    history.push(itemPath)
  }

  const onChange = () => {}

  return (
    <List
      dataSource={data}
      onChange={onChange}
      renderItem={(item, index) => (
        <Card
          style={{ cursor: 'pointer' }}
          key={index}
          onClick={() => onItemClick(item?.id)}
          onSelect={onItemSelected}
          mb={3}
          size="small"
          bordered={false}
          shadowless>
          <Row noGutters h="center" v="center">
            <Col minWidth={0}>
              <Text isEllipsis>{item?.name}</Text>
            </Col>
            <Col cw="auto">
              <BuyingCaseAttendeesList data={item?.attendees} size="large" />
            </Col>
          </Row>
        </Card>
      )}
    />
  )
}

BuyingCasesList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      attendees: PropTypes.arrayOf(PropTypes.string)
    })
  )
}

export default BuyingCasesList
