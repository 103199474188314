import React, { useEffect, useState } from 'react'
import { List, Empty } from 'antd'
import { Row, Col } from '@qonsoll/react-design'
import { ChatView } from '~/app/domains/Chat/components'
import { useMessengerContext } from '~/app/contexts/Messenger/hooks'
import _ from 'lodash'
import { useUserContext } from 'app/contexts/User/hooks'

function ChatsList() {
  // [ADDITIONAL HOOKS]
  const { messengerState } = useMessengerContext()
  const state = useUserContext()

  // [COMPONENT_STATE_HOOKS]
  const [sortedChatDetail, setSortedChatDetail] = useState([])

  // [COMPUTED_PROPERTIES]
  const { chatsDetail } = messengerState

  // [USE_EFFECTS]
  useEffect(() => {
    let isComponentMounted = true

    const sortChatDetails = () => {
      /**
       * get only active chats, that have messages
       */
      const activeChats = Object.values(chatsDetail).filter(
        (detail) =>
          (detail?.messageId || detail?.chatCreatedBy === state?.id) && detail
      )

      setSortedChatDetail(_.orderBy(activeChats, 'createdAt', 'desc'))
    }

    isComponentMounted && sortChatDetails()

    return () => {
      isComponentMounted = false
    }
  }, [chatsDetail, state?.id])

  return sortedChatDetail?.length ? (
    <List
      dataSource={sortedChatDetail}
      renderItem={(chat, index) => <ChatView chatData={chat} key={index} />}
      style={{
        width: '100%'
      }}
    />
  ) : (
    <Row v="center" height="100%">
      <Col h="center">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Col>
    </Row>
  )
}

export default React.memo(ChatsList)
