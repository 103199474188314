import React, { useState } from 'react'
import { Button, message } from 'antd'
import { Row, Col, Container, PageWrapper } from '@qonsoll/react-design'
import { BuyingCaseForm } from 'domains/BuyingCase/components'
import { generatePath, useHistory } from 'react-router-dom'
import ROUTE_PATHS from 'domains/allRoutePath'
import { createDocument, getCollectionRef } from '~/services/Firebase/firestore'
import { COLLECTIONS } from 'app/constants'
import { PageLoading } from 'app/components'
import { useTranslations } from 'app/contexts/Translation/hooks'
import _ from 'lodash'
import { useCollectionData } from '~/hooks/firestore'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { useUserContext } from 'app/contexts/User/hooks'

function BuyingCaseCreate() {
  // [CUSTOM HOOKS]
  const state = useUserContext()

  // [ADDITIONAL HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const history = useHistory()
  const { t } = useTranslations()
  const isCreate = history.location.state.isCreate
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  const [buyingCaseTypes, loadingBuyingCaseTypes] = useCollectionData(
    getCollectionRef(COLLECTIONS.BUYING_CASE_TYPES)
  )

  //[STATE HOOKS]
  const [loading, setLoading] = useState(false)

  // [CLEAN FUNCTIONS]
  const onFinish = async (values, type) => {
    setLoading(true)
    try {
      const dataToDB = {
        ...values,
        description: values?.description?.trim(),
        attendees: [state.email],
        wasCreatedId: state.id,
        wasCreatedEmail: state.email,
        buyingTypeId: type?.id,
        agreementId: ' ',
        links: _.without(values?.links, undefined)
      }
      const buyingCase = await createDocument(
        COLLECTIONS.BUYING_CASES,
        dataToDB
      )
      const toBuyingCaseAttendeesInvite = generatePath(
        ROUTE_PATHS.BUYING_CASE_INVITE_ATTENDEES,
        { id: buyingCase.id }
      )
      message.success(t(`Buying case was created`))
      history.push(toBuyingCaseAttendeesInvite)
    } catch (error) {
      console.log(error)
      message.error(t(`Can't create Buying case`))
    }
    setLoading(false)
  }

  const onSkip = () => history.push(ROUTE_PATHS.BUYING_CASES_ALL)
  return (
    <PageLoading loading={loading || loadingBuyingCaseTypes}>
      <PageWrapper
        isBottomSticky
        headingProps={{
          title: t('Create buying case'),
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}>
        <Container height="inherit" display="grid">
          <Row noGutters h="center">
            <Col cw={[12, 10, 8, 7, 6]} display="grid">
              <BuyingCaseForm
                onFinish={onFinish}
                buyingCaseTypes={buyingCaseTypes}
                skipAction={
                  isCreate && (
                    <Button block type="text" onClick={onSkip}>
                      {t('Skip')}
                    </Button>
                  )
                }
              />
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default BuyingCaseCreate
