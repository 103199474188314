import { useEffect } from 'react'
import { useUI } from '../hooks'

/**
 * @info useShowActions (27 Jun 2021) // CREATION DATE
 *
 * @comment useShowActions - hook that set page layout only with logo on the top.
 *
 * @since 27 Jun 2021 ( v.0.0.1 ) // LAST-EDIT DATE
 *
 * @param {boolean}    [show]
 *
 * @return {void}
 */

const useShowActions = (show = false) => {
  const { dispatch } = useUI()
  useEffect(() => {
    let isComponentMount = true
    isComponentMount && dispatch({ type: 'SHOW_ACTIONS', payload: show })
    return () => {
      isComponentMount = false
      dispatch({ type: 'SHOW_ACTIONS', payload: !show })
    }
  }, [])
}

export default useShowActions
