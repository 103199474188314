import { useUserContext } from 'app/contexts/User/hooks'
import { useWebViewContext } from 'app/contexts/WebViewContext/hooks'
import { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { AppWrapper } from '@qonsoll/react-design'
import {
  AppHeader,
  BottomNavigation,
  PageLoading,
  SideNavigation
} from 'app/components'
import { useSessionContext } from 'app/contexts/Session/hooks'
import UIContext from '../../context'
import UIReducer from '../../UIReducer'
import { useResponsiveBreakpoints } from '../../hooks'

const DEFAULT_UI_STATE = {
  onBack: false,
  isSidebarCollapsed: false,
  showPageActions: true
}

const NAVIGATION_BREAKPOINTS = {
  sm: false,
  md: true,
  lg: true,
  xl: true,
  xxl: true
}

const UIProvider = (props) => {
  const { UI = DEFAULT_UI_STATE, children, ...rest } = props

  // [ADDITIONAL HOOKS]
  const session = useUserContext()
  const { isSessionExist, sessionLoading, ...restSessionData } =
    useSessionContext()
  const { safeAreaInsets } = useWebViewContext()
  const responsive = useResponsiveBreakpoints(NAVIGATION_BREAKPOINTS)

  // [COMPONENT_STATE_HOOKS]
  const [state, dispatch] = useReducer(UIReducer, { ...UI })

  useEffect(() => {
    dispatch({ type: 'SET_WEB_VIEW_STATE', payload: { safeAreaInsets } })
  }, [safeAreaInsets])

  useEffect(() => {
    let theme = ''
    if (session?.id) {
      theme = session?.themeMode || 'light'
      localStorage.setItem('theme', theme)
    } else {
      theme = localStorage.getItem('theme') || 'light'
    }
    dispatch({ type: 'SET_THEME', payload: theme })
    document.documentElement.className = theme
  }, [session])

  const appLoading = sessionLoading || session?.isUserDataLoaded

  return (
    <UIContext.Provider value={{ state, dispatch }} {...rest}>
      <AppWrapper
        backgroundImage="var(--layout-body-background)"
        appHeader={
          !state?.isChatShowPage && <AppHeader appLoading={appLoading} />
        }
        bottomNavbar={
          state.showPageActions &&
          isSessionExist &&
          !appLoading &&
          session.id &&
          !responsive &&
          !state.isChatShowPage && (
            <BottomNavigation isIphone={state.isDeviceIphone} />
          )
        }
        horizontal={responsive && state.showPageActions}
        appNavbar={
          isSessionExist &&
          !appLoading &&
          session.id &&
          responsive && <SideNavigation session={restSessionData} />
        }
        contentProps={{
          py: 24
        }}>
        <PageLoading loading={appLoading} loadingText={appLoading}>
          {children}
        </PageLoading>
      </AppWrapper>
    </UIContext.Provider>
  )
}

UIProvider.propTypes = {
  children: PropTypes.element.isRequired,
  UI: PropTypes.object
}

export default UIProvider
