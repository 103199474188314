import React, { useState } from 'react'
import { message } from 'antd'
import { Col, Container, Row, PageWrapper } from '@qonsoll/react-design'
import { BuyingCaseInviteAttendeesForm } from 'domains/BuyingCase/components'
import { useHistory, useParams } from 'react-router-dom'
import ROUTE_PATHS from 'domains/allRoutePath'
import { updateDocument } from '~/services/Firebase/firestore'
import { COLLECTIONS } from 'app/constants'
import { emailsForSendInvite, inviteBuyer } from 'domains/BuyingCase/helpers'
import { PageLoading } from 'app/components'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import _ from 'lodash'
import { useUserContext } from 'app/contexts/User/hooks'

function BuyingCaseInviteAttendees() {
  // [CUSTOM HOOKS]
  const state = useUserContext()

  //[STATE HOOKS]
  const [loading, setLoading] = useState(false)

  // [ADDITIONAL HOOKS]
  const history = useHistory()
  const { id } = useParams()
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  // [CLEAN FUNCTIONS]
  const onFinish = async (values) => {
    const { emails } = values

    //only uniq emails from form
    const uniqEmails = _.uniqBy(emails, 'email')

    const { emailsArray, emailsForInvite } = emailsForSendInvite(uniqEmails, [
      ...state.email
    ])
    const filteredEmails = emailsArray?.filter((email) => email !== state.email)
    const filteredEmailsForInvite = emailsForInvite.filter(
      (email) => email !== state.email
    )

    setLoading(true)
    try {
      const allAttendeesWithMainUser = [state.email, ...filteredEmails]
      await updateDocument(COLLECTIONS.BUYING_CASES, id, {
        attendees: allAttendeesWithMainUser
      })
      if (emailsForInvite.length > 0) {
        for (const email of filteredEmailsForInvite) await inviteBuyer(email, t)
      }
      message.success(t('Users was invited'))
      history.push(ROUTE_PATHS.BUYING_CASES_ALL)
    } catch (error) {
      console.log(error.message)
      message.error(t('Error operation'))
    }
    setLoading(false)
  }
  const onSkip = () => history.push(ROUTE_PATHS.BUYING_CASES_ALL)

  return (
    <PageLoading loading={loading}>
      <PageWrapper
        isBottomSticky
        headingProps={{
          title: t('Invite users'),
          titleSize: titleSizes,
          marginBottom: '24px'
        }}>
        <Container height="inherit" display="grid">
          <Row noGutters h="center">
            <Col cw={[12, 10, 8, 7, 6]} display="grid">
              <BuyingCaseInviteAttendeesForm
                onFinish={onFinish}
                onSkip={onSkip}
              />
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default BuyingCaseInviteAttendees
