import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import { Input } from '@qonsoll/react-design'
import { TeamOutlined } from '@ant-design/icons'
import { AvatarUploader } from 'app/components'
import { useTranslations } from 'app/contexts/Translation/hooks'

const ChatGroupFormEdit = (props) => {
  const { initialValues, ...rest } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  return (
    <Form
      initialValues={initialValues}
      layout="vertical"
      requiredMark={false}
      style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      {...rest}>
      <Form.Item name="avatarURL">
        <AvatarUploader
          size="large"
          imageUrl={initialValues?.avatarURL}
          Icon={TeamOutlined}
        />
      </Form.Item>
      <Form.Item
        label={t('Name of group')}
        name="name"
        rules={[
          { required: true, message: t('Enter name please') },
          {
            max: 75,
            message: t('Name should be less than 75 character')
          }
        ]}
        style={{ width: '100%', marginBottom: '16px' }}>
        <Input autoFocus placeholder={t('Enter name of group')} width="100%" />
      </Form.Item>
    </Form>
  )
}

ChatGroupFormEdit.propTypes = {
  onEditFinish: PropTypes.func,
  onFinishCreate: PropTypes.func
}

export default ChatGroupFormEdit
