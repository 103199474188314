import { createDocument } from '~/services/Firebase/firestore'
import { COLLECTIONS } from 'app/constants'

const notificationForFormAnswer = async (
  usersForSendNotification,
  userWhichFinishedAnswers,
  buyingCaseData
) => {
  for (const user of usersForSendNotification) {
    if (user?.id !== userWhichFinishedAnswers?.id) {
      const bodyForNotification = {
        userId: user?.id,
        text: `${userWhichFinishedAnswers?.firstName} ${userWhichFinishedAnswers?.secondName} finish wizard in ${buyingCaseData.name} buying case`,
        senderAvatarURL: ' ',
        title: 'Wizard result'
      }
      await createDocument(COLLECTIONS.NOTIFICATIONS, bodyForNotification)
    }
  }
}
export default notificationForFormAnswer
