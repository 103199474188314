import { useContext } from 'react'
import WebViewContext from '../context'

const useWebViewContext = () => {
  const context = useContext(WebViewContext)

  if (context === undefined) {
    throw new Error(
      'useSessionContext must be used within a WebViewContext.Provider'
    )
  }

  return context
}

export default useWebViewContext
