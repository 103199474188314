import { COLLECTIONS } from 'app/constants'
import { createChatWithOneParticipant } from 'domains/Chat/helpers'
import { generatePath } from 'react-router-dom'
import ROUTE_PATHS from 'domains/allRoutePath'
import { getReferenceChild } from '~/services/Firebase/database'

const goToChat = async (sessionState, userData, setLoading, history) => {
  setLoading(true)

  let participantAndChatIds = {}

  await getReferenceChild(
    `${COLLECTIONS.CHATS}/${COLLECTIONS.PARTICIPANTS}/${sessionState.id}/${COLLECTIONS.CHATS_BY_PARTICIPANTS}`
  ).on('value', (snapshot) => {
    participantAndChatIds = snapshot?.val() || {}
  })

  const hasParticipant = await Object.keys(participantAndChatIds).includes(
    userData?.id
  )

  if (hasParticipant)
    history.push(
      generatePath(ROUTE_PATHS.CHAT_SHOW, {
        id: participantAndChatIds[userData?.id]
      })
    )
  else await createChatWithOneParticipant(sessionState, userData, history)

  setLoading(false)
}
export default goToChat
