// import PropTypes from 'prop-types'

import { Col, Row, Card, Text } from '@qonsoll/react-design'
import { List } from 'antd'

function WizardAnswerList(props) {
  const { data } = props

  return (
    <List
      style={{ flex: 1 }}
      dataSource={data}
      renderItem={(item, index) => (
        <Card
          style={{ cursor: 'pointer' }}
          key={index}
          mb={3}
          size="small"
          bordered={false}
          shadowless>
          <Row noGutters h="center" v="center">
            <Col minWidth={0}>
              <Text isEllipsis>{item?.question}</Text>
            </Col>
            <Col cw="auto">
              <Text isEllipsis>{item?.answer}</Text>
            </Col>
          </Row>
        </Card>
      )}
    />
  )
}

WizardAnswerList.propTypes = {}

export default WizardAnswerList
