import { useEffect } from 'react'
import useUI from './useUI'

/**
 * @info useChatShowPage (21 May 2021) // CREATION DATE
 *
 * @comment useChatShowPage - hook that add new layout for chat page
 *
 * @since 21 May 2021 ( v.0.0.1 ) // LAST-EDIT DATE
 *
 * @return {void}
 */

const useChatShowPage = () => {
  // [ADDITIONAL_HOOKS]
  const { dispatch } = useUI()

  // [USE_EFFECTS]
  useEffect(() => {
    dispatch({ type: 'CHAT_SHOW_PAGE', payload: true })

    return () => {
      dispatch({ type: 'CHAT_SHOW_PAGE', payload: false })
    }
  }, [dispatch])
}

export default useChatShowPage
