import { useUserContext } from 'app/contexts/User/hooks'
import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import { Container, Row, Col, Box, Title } from '@qonsoll/react-design'
import { UserForm } from 'domains/User/components'
import { updateDocument, getCollectionRef } from '~/services/Firebase/firestore'
import { COLLECTIONS } from '~/app/constants'
import { useSessionContext } from '~/app/contexts/Session/hooks'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useHistory } from 'react-router-dom'
import { PageLoading } from '~/app/components/PageLoading'
import auth from '~/services/Firebase/auth'
import { useTranslations } from 'app/contexts/Translation/hooks'
import {
  useResponsiveBreakpoints,
  useShowActions,
  useUI
} from 'app/contexts/UI/hooks'
import { useCollectionData } from 'react-firebase-hooks/firestore'
// import { POSTRequestOptions } from '../../__constants__/requestOptions'
// import { useAPIRequestContext } from '~/app/contexts/APIRequest/hooks'
import { createDocument } from 'services/Firebase/firestore'

function UserCreate() {
  // [COMPONENT STATE HOOKS]
  useShowActions()
  const UI = useUI()
  const user = useSessionContext()
  const [loading, setLoading] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [inviteeIds, setInviteeIds] = useState([])

  // [ADDITIONAL HOOKS]
  const state = useUserContext()
  const history = useHistory()
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })
  const [locationsData, loadingLocations] = useCollectionData(
    getCollectionRef(COLLECTIONS.LOCATIONS)
  )
  const [buyingCaseTypes, loadingBuyingCaseTypes] = useCollectionData(
    getCollectionRef(COLLECTIONS.BUYING_CASE_TYPES)
  )
  const [buyingCases, loadingBuyingCases] = useCollectionData(
    state?.id &&
      getCollectionRef(COLLECTIONS.BUYING_CASES).where(
        'attendees',
        'array-contains',
        state?.email
      )
  )

  // const requester = useAPIRequestContext()
  // [CLEAN FUNCTIONS]
  const onUserFormSave = async (values) => {
    setLoading(true)
    try {
      // const token = await auth.currentUser.getIdToken()
      // const myHeaders = new Headers()
      // myHeaders.append('Content-Type', 'application/json')
      // myHeaders.append('Authorization', token)
      // myHeaders.append('Accept', '*')
      // const body = JSON.stringify({ id: state.id, data: values.ssn })
      // const response = await requester(
      //   `${process.env.REACT_APP_REST_API_URL}/rest/data/encrypt`,
      //   POSTRequestOptions(myHeaders, body)
      // )
      // const responseData = await response.text()
      await updateDocument(COLLECTIONS.USERS, state?.id, {
        ...values,
        avatarURL: values?.avatarURL || '',
        // code: responseData,
        isActivated: true,
        phone: `+${values?.phone}`,
        isParticipant: false,
        aboutMe: values?.aboutMe?.trim()
      })

      for (const id of inviteeIds) {
        /**
         * object for notification
         */
        const notificationData = {
          text: `by ${values?.firstName} ${values?.secondName}`,
          title: 'Accepted invitation',
          senderAvatarUrl: values?.avatarURL || ' ',
          userId: id
        }
        /**
         * creating document for notification
         */
        await createDocument(COLLECTIONS.NOTIFICATIONS, notificationData)
      }

      message.success(t('User successfully updated!'))
      history.push(ROUTE_PATHS.BUYING_CASE_CREATE, { isCreate: true })
    } catch (err) {
      console.log(err)
      message.error(t('Error occurred during user info creation'))
    }
    setLoading(false)
  }

  const onUserFormCancel = () => {
    auth.signOut()
  }

  useEffect(() => {
    let isComponentMount = true

    const ids = []
    const setInviteesIds = () => {
      for (const buyingCase of buyingCases) {
        ids.push(buyingCase?.wasCreatedId)
      }

      setInviteeIds(ids)
    }

    isComponentMount &&
      !loadingBuyingCases &&
      buyingCases?.length &&
      setInviteesIds()

    return () => {
      isComponentMount = false
    }
  }, [loadingBuyingCases, buyingCases])
  return (
    <PageLoading
      loading={loading || loadingLocations || loadingBuyingCaseTypes}
      height="inherit">
      <Box
        height="inherit"
        display="flex"
        flexDirection="column"
        alignItems="center">
        <Title mb={24} level={titleSizes}>
          {t('Personal info')}
        </Title>

        <Container height="inherit" display="grid">
          <Row noGutters h="center" pb={UI?.state?.safeAreaInsets?.bottom}>
            <Col cw={[12, 10, 8, 7, 6]} display="grid">
              <UserForm
                locationsData={locationsData}
                buyingCaseTypes={buyingCaseTypes}
                initialValues={
                  user && {
                    avatarURL: user?.photoURL,
                    phoneNumber: user?.phoneNumber,
                    firstName: user?.displayName?.split(' ')[0],
                    secondName: user?.displayName?.split(' ')?.slice(1)[0]
                  }
                }
                onFinish={onUserFormSave}
                onCancel={onUserFormCancel}
                loading={formLoading}
                setFormLoading={setFormLoading}
              />
            </Col>
          </Row>
        </Container>
      </Box>
    </PageLoading>
  )
}

export default UserCreate
