import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Switch } from 'antd'
import {
  Row,
  Col,
  Input,
  Button,
  PhoneInput,
  TextArea,
  Text
} from '@qonsoll/react-design'
import { AvatarUploader } from 'app/components'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import { LocationSelect } from 'app/domains/Location/components'
import { BuyingCaseTypeSelect } from 'domains/BuyingCaseType/components'

function UserForm(props) {
  const {
    isEdit,
    loading,
    setFormLoading,
    initialValues,
    locationsData,
    buyingCaseTypes,
    onCancel,
    onFinish,
    form,
    diffImage = [],
    setDiffImage,
    ...rest
  } = props

  // [COMPONENT STATE HOOKS]
  const [showLocations, setShowLocations] = useState(
    initialValues?.isVisible || false
  )
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  // [CLEAN_FUNCTIONS]
  const onKeyPress = (event) => {
    if (event.code === 'Enter' && !event.shiftKey && !loading) form.submit()
  }

  const onValuesChange = (data) => {
    if ('avatarURL' in data) {
      setDiffImage?.([...diffImage, data?.avatarURL])
    }
  }

  const onChangeVisible = () => {
    setShowLocations(!showLocations)
  }
  return (
    <Form
      onValuesChange={onValuesChange}
      initialValues={{
        isVisible: initialValues?.isVisible || false,
        buyingTypeId: buyingCaseTypes[1]?.id,
        locationId: locationsData[0]?.id,
        ...initialValues
      }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '-webkit-fill-available'
      }}
      form={form}
      onFinish={onFinish}
      layout="vertical"
      requiredMark={false}
      {...rest}>
      <Form.Item name="avatarURL" style={{ marginBottom: -24 }}>
        <AvatarUploader
          shape="user"
          imageUrl={initialValues?.avatarURL}
          size="large"
          setLoading={setFormLoading}
        />
      </Form.Item>
      <Row>
        <Col
          cw={12}
          style={{ alignItems: 'baseline' }}
          v="center"
          flexDirection="row">
          <Text style={{ marginRight: '12px' }}> {t('Visible profile')}</Text>
          <Form.Item name="isVisible" valuePropName="checked">
            <Switch onChange={onChangeVisible} />
          </Form.Item>
        </Col>
      </Row>
      {showLocations ? (
        <Row negativeBlockMargin>
          <Col cw={[12, 6, 12, 6]} ml="auto" display="block">
            <Form.Item
              label={t('Location')}
              name="locationId"
              style={{ width: '100%' }}
              rules={[
                { required: true, message: t('Please, select location') }
              ]}>
              <LocationSelect locations={locationsData} />
            </Form.Item>
          </Col>
          <Col cw={[12, 6, 12, 6]} ml="auto" display="block">
            <Form.Item
              label={t('Buying case type')}
              name="buyingTypeId"
              style={{ width: '100%' }}
              rules={[
                {
                  required: true,
                  message: t('Please, select buying case type')
                }
              ]}>
              <BuyingCaseTypeSelect buyingTypes={buyingCaseTypes} />
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <Form.Item
          label={t('Buying case type')}
          name="buyingTypeId"
          style={{ width: '100%' }}
          rules={[
            { required: true, message: t('Please, select buying case type') }
          ]}>
          <BuyingCaseTypeSelect buyingTypes={buyingCaseTypes} />
        </Form.Item>
      )}
      <Form.Item
        label={t('First name')}
        name="firstName"
        rules={[
          { required: true, message: t('Please, input first name') },
          {
            max: 75,
            message: t('First name should be less than 75 character')
          }
        ]}>
        <Input autoFocus placeholder={t('First name')} />
      </Form.Item>
      <Form.Item
        label={t('Last name')}
        name="secondName"
        rules={[
          { required: true, message: t('Please, input last name') },
          {
            max: 75,
            message: t('Second name should be less than 75 character')
          }
        ]}>
        <Input placeholder={t('Second name')} />
      </Form.Item>
      <Form.Item
        label={t('Phone')}
        name="phone"
        rules={[{ required: true, message: t('Please, input phone number') }]}>
        <PhoneInput placeholder={t('Phone')} country="no" />
      </Form.Item>
      <Form.Item
        label={t('About me')}
        name="aboutMe"
        rules={[
          {
            max: 2500,
            message: 'Description should be less than 2500 character'
          }
        ]}>
        <TextArea
          placeholder={t('About me')}
          rows={4}
          onKeyPress={onKeyPress}
        />
      </Form.Item>

      {/*{!isEdit && (*/}
      {/*  <Form.Item*/}
      {/*    label={t('SSN')}*/}
      {/*    name="ssn"*/}
      {/*    rules={[*/}
      {/*      { required: true, message: t('Please, input your SSN') },*/}
      {/*      {*/}
      {/*        max: 200,*/}
      {/*        message: t('SSN should be less than 200 character')*/}
      {/*      }*/}
      {/*    ]}>*/}
      {/*    <Input placeholder={t('SSN')} />*/}
      {/*  </Form.Item>*/}
      {/*)}*/}
      <Row noGutters mt="auto">
        <Col cw={12} mb={!isEdit && 2}>
          <Button htmlType="submit" block type="primary" loading={loading}>
            {isEdit ? t('Save changes') : t('Create')}
          </Button>
        </Col>
        {!isEdit && (
          <Col cw={12} mb={2}>
            <Button htmlType="submit" block onClick={onCancel}>
              {t('Cancel')}
            </Button>
          </Col>
        )}
      </Row>
    </Form>
  )
}

UserForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  isEdit: PropTypes.bool,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  setFormLoading: PropTypes.func,
  locationsData: PropTypes.array.isRequired,
  buyingCaseTypes: PropTypes.array.isRequired
}

export default UserForm
