import { useUserContext } from 'app/contexts/User/hooks'
import React, { useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { message } from 'antd'
import {
  Container,
  Row,
  Col,
  Title,
  Remove,
  PageWrapper,
  Button,
  Avatar,
  Text,
  Link,
  Box
} from '@qonsoll/react-design'
import {
  UserOutlined,
  EditOutlined,
  KeyOutlined,
  PhoneOutlined,
  MailOutlined,
  SendOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import { Backdrop, PageLoading } from '~/app/components'
import { COLLECTIONS } from 'app/constants'
import firebase from '~/services/Firebase/init'
import auth from '~/services/Firebase/auth'
import { getCollectionRef } from '~/services/Firebase/firestore'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import ROUTE_PATHS from 'domains/allRoutePath'
import { goToChat } from 'domains/Chat/helpers'

function UserShow() {
  // [ADDITIONAL_HOOKS]
  const [user] = useAuthState(auth)
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const { id } = useParams()
  const state = useUserContext()
  const history = useHistory()
  const { t } = useTranslations()
  // getting data from database
  const [userData, loading] = useDocumentData(
    getCollectionRef(COLLECTIONS.USERS).doc(id)
  )
  const [locationData, loadingLocation] = useDocumentData(
    userData?.locationId &&
      getCollectionRef(COLLECTIONS.LOCATIONS).doc(userData.locationId)
  )
  const [buyingCaseTypeData, loadingBuyingCaseTypeData] = useDocumentData(
    userData?.buyingTypeId &&
      getCollectionRef(COLLECTIONS.BUYING_CASE_TYPES).doc(userData.buyingTypeId)
  )

  // [COMPONENT_STATE_HOOKS]
  const [loadingCheckIsNewChat, setLoadingCheckIsNewChat] = useState()
  const [deleteUserLoading, setDeleteUserLoading] = useState(false)
  const [isShowMore, setIsShowMore] = useState(false)

  // [CLEAN_FUNCTIONS]
  const onEditClick = () =>
    history.push(generatePath(ROUTE_PATHS.USER_EDIT, { id }))

  // [COMPUTED PROPERTIES]
  const currentUser = state.id === id

  const onDeleteAccount = async () => {
    setDeleteUserLoading(true)
    const func = firebase
      .functions()
      .httpsCallable('deleteUser', { timeout: 0 })
    try {
      await func({ userForDelete: state })
      message.success(
        `${t('Your profile was successfully deleted from application')}.`
      )
    } catch (e) {
      console.log(e)
      message.error(`${t('Error occurred during user deleting')}.`)
    }
  }
  const onResetPassword = () => history.push(ROUTE_PATHS.RESET_PASSWORD)
  const onSetPassword = () => history.push(ROUTE_PATHS.SET_PASSWORD)
  const onClickOnLink = () => setIsShowMore((prevStatus) => !prevStatus)

  return (
    <PageLoading
      loading={loading || loadingLocation || loadingBuyingCaseTypeData}
      height="inherit">
      <PageWrapper isBottomSticky onBack={goBack} divided={false}>
        <Container
          height="inherit"
          display="flex"
          paddingTop="5%"
          style={{
            flexDirection: 'column',
            justifyContent: currentUser ? 'space-between' : 'center'
          }}>
          {deleteUserLoading && <Backdrop spinnerText={t('Deleting')} />}
          <Row h="center" noGutters>
            <Col cw={[12, 9, 8, 7]} flexDirection="column">
              <Box
                width="100%"
                margin="0 auto"
                display="flex"
                bg="var(--card-user-show-bg)"
                borderRadius="var(--ql-border-radius-12)"
                pb={2}
                mb={3}>
                <Row width="100%">
                  <Col cw={12} h="center" mb={2}>
                    <Avatar
                      style={{ margin: '0 auto', marginTop: '-60px' }}
                      src={userData?.avatarURL}
                      alt={t('User')}
                      icon={<UserOutlined />}
                      size={128}
                    />
                  </Col>
                  <Col cw={12} h="center" mb={3}>
                    <Title
                      textAlign="center"
                      level={
                        3
                      }>{`${userData?.firstName} ${userData?.secondName}`}</Title>
                  </Col>
                  {id === state.id && (
                    <Col cw={12} h="center" mb={3}>
                      <Text type="secondary">
                        {userData?.isVisible
                          ? t('Visible profile')
                          : t('Invisible profile')}
                      </Text>
                    </Col>
                  )}
                  {!currentUser && userData?.isVisible && (
                    <Col cw={12} h="center">
                      <Row h="center" mb={2}>
                        {!!userData?.phone?.length && (
                          <Col cw="auto">
                            <Button
                              href={`tel:${userData.phone}`}
                              icon={
                                <PhoneOutlined
                                  style={{ fontSize: 'var(--ql-font-size-h3)' }}
                                />
                              }
                            />
                          </Col>
                        )}
                        <Col cw="auto">
                          <Button
                            href={`mailto:${userData?.email}`}
                            icon={
                              <MailOutlined
                                style={{ fontSize: 'var(--ql-font-size-h3)' }}
                              />
                            }
                          />
                        </Col>
                        <Col cw="auto">
                          <Button
                            icon={<SendOutlined />}
                            onClick={() =>
                              goToChat(
                                state,
                                userData,
                                setLoadingCheckIsNewChat,
                                history
                              )
                            }
                            loading={loadingCheckIsNewChat}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Box>
              <Row mb={2}>
                <Col cw={[12, 6, 6, 6]} flexDirection="column" mb={2}>
                  <Text type="secondary" fontSize="var(--ql-font-size-body2)">
                    {t('Email')}
                  </Text>
                  <Text isEllipsis fontWeight="var(--ql-font-weight-medium)">
                    {userData?.email}
                  </Text>
                </Col>

                <Col cw={[12, 6, 6, 6]} flexDirection="column" mb={2}>
                  <Text type="secondary" fontSize="var(--ql-font-size-body2)">
                    {t('Phone')}
                  </Text>
                  <Text fontWeight="var(--ql-font-weight-medium)">
                    {userData?.phone}
                  </Text>
                </Col>
              </Row>
              <Row mb={2}>
                {locationData?.name && (
                  <Col cw={[12, 6, 6, 6]} flexDirection="column" mb={2}>
                    <Text type="secondary" fontSize="var(--ql-font-size-body2)">
                      {t('Location')}
                    </Text>
                    <Text
                      isEllipsis
                      fontWeight="var(--ql-font-weight-medium)">{`${
                      locationData?.name
                    }, ${t('Norway')}`}</Text>
                  </Col>
                )}
                {buyingCaseTypeData?.name && (
                  <Col cw={[12, 6, 6, 6]} flexDirection="column" mb={2}>
                    <Text type="secondary" fontSize="var(--ql-font-size-body2)">
                      {t('Buying case type')}
                    </Text>
                    <Text isEllipsis fontWeight="var(--ql-font-weight-medium)">
                      {buyingCaseTypeData?.name}
                    </Text>
                  </Col>
                )}
              </Row>

              {userData?.aboutMe && (
                <Row
                  h="center"
                  display="flex"
                  mb={4}
                  style={{ flexDirection: 'column' }}>
                  <Col cw="auto">
                    <Text type="secondary" fontSize="var(--ql-font-size-body2)">
                      {t('About me')}
                    </Text>
                  </Col>
                  <Col cw="auto">
                    <Text
                      whiteSpace="pre-line"
                      fontWeight="var(--ql-font-weight-medium)">
                      {userData?.aboutMe?.length > 200 && !isShowMore
                        ? userData.aboutMe.slice(0, 200).concat('...')
                        : userData.aboutMe}
                      {userData?.aboutMe?.length > 200 && (
                        <Link onClick={onClickOnLink}>
                          {!isShowMore && t(' show more')}
                        </Link>
                      )}
                    </Text>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          {currentUser && (
            <Row noGutters>
              <Col cw={12} mb={2}>
                <Button icon={<EditOutlined />} block onClick={onEditClick}>
                  {t('Edit profile')}
                </Button>
              </Col>
              {user.providerData.some(
                ({ providerId }) => providerId === 'google.com'
              ) && user.providerData.length === 1 ? (
                <Col cw={12} mb={2}>
                  <Button icon={<KeyOutlined />} block onClick={onSetPassword}>
                    {t('Set password')}
                  </Button>
                </Col>
              ) : (
                <Col cw={12} mb={2}>
                  <Button
                    icon={<KeyOutlined />}
                    block
                    onClick={onResetPassword}>
                    {t('Change password')}
                  </Button>
                </Col>
              )}
              <Col cw={12}>
                <Remove
                  type="primary"
                  danger
                  text={t('Delete account')}
                  icon={<DeleteOutlined />}
                  block
                  onSubmit={onDeleteAccount}
                  itemName="account"
                />
              </Col>
            </Row>
          )}
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default UserShow
