import React from 'react'
import { Form } from 'antd'
import { Col, Row, Input, Button } from '@qonsoll/react-design'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { useTranslations } from '~/app/contexts/Translation/hooks'

function BuyingCaseInviteAttendeesForm(props) {
  const { onFinish, onSkip } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  return (
    <Form
      onFinish={onFinish}
      autoComplete="off"
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Form.List name="emails">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Row noGutters>
                <Col mr={2}>
                  <Form.Item
                    {...restField}
                    name={[name, 'email']}
                    fieldKey={[fieldKey, 'email']}
                    style={{ width: '100%' }}
                    rules={[
                      {
                        required: true,
                        type: 'email',
                        message: t('Enter valid email!')
                      }
                    ]}>
                    <Input placeholder={t('Email')} autoComplete="off" />
                  </Form.Item>
                </Col>
                <Col cw="auto">
                  <Button
                    type={'text'}
                    block
                    icon={<MinusCircleOutlined />}
                    onClick={() => remove(name)}
                  />
                </Col>
              </Row>
            ))}
            {fields.length < 4 && (
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}>
                {t('Add one more user')}
              </Button>
            )}
          </>
        )}
      </Form.List>
      <Row noGutters mt="auto">
        <Col cw={12} mb={2}>
          <Button type="primary" block htmlType="submit" mt="auto">
            {t('Send invite')}
          </Button>
        </Col>
        <Col cw={12}>
          <Button block type="text" mt="auto" onClick={onSkip}>
            {t('Skip')}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

BuyingCaseInviteAttendeesForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired
}

export default BuyingCaseInviteAttendeesForm
