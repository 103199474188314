import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory, generatePath } from 'react-router-dom'
import moment from 'moment'
import { message } from 'antd'
import { Row, Col, Box, Text, Badge } from '@qonsoll/react-design'
import { Dropdown, AvatarWithOnline } from '~/app/components'
import { useMessengerContext } from '~/app/contexts/Messenger/hooks'
import { TYPES } from '~/app/contexts/Messenger/constants'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import { deleteChatFromDb, checkOnline } from 'domains/Chat/helpers'
import { ReadIcon } from 'domains/Chat/components/Lib'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useUserContext } from 'app/contexts/User/hooks'

function ChatView(props) {
  const { chatData } = props
  const {
    isRead,
    chatId,
    displayName,
    name,
    avatarURL,
    text,
    createdAt,
    createdBy,
    participantId,
    isGroup,
    chatParticipants,
    lastSeen,
    chatCreatedBy
  } = chatData

  // [ADDITIONAL HOOKS]
  const history = useHistory()
  const { t } = useTranslations()
  const state = useUserContext()
  const { messengerState, messengerDispatch } = useMessengerContext()

  // [COMPONENT_STATE_HOOKS]
  const [statusOnline, setStatusOnline] = useState()

  // [CLEAN_FUNCTIONS]
  const formattingCreatedAt = () => {
    const dayCreated = moment(createdAt).format('DD')
    const currentDay = moment(new Date()).format('DD')
    if (currentDay === dayCreated) return moment(createdAt).format('HH:mm')
    return moment(createdAt).format('DD.MM')
  }

  const onClickChat = () =>
    history.push(generatePath(ROUTE_PATHS.CHAT_SHOW, { id: chatId }))

  const onDeleteChatClick = async () => {
    try {
      messengerDispatch({
        type: TYPES.DELETE_CHAT,
        payload: chatId
      })

      history.push(ROUTE_PATHS.CHATS_ALL)

      localStorage.removeItem(chatId)

      await deleteChatFromDb({
        chatId,
        stateId: state?.id,
        isGroup,
        participantId,
        participantIds: isGroup ? Object.keys(chatParticipants) : []
      })

      message.success(t('Chat was deleted successfully'))
    } catch (error) {
      message.error(t('Error occurred during chat deletion'))
    }
  }

  // [COMPUTED_PROPERTIES]
  const currentUser = state.id === createdBy
  const isCurrentChat = chatId === messengerState?.currentChatId
  const formattedTime = formattingCreatedAt()
  const menu = [
    {
      name: 'delete',
      onItemClick: () => onDeleteChatClick(),
      Component: (
        <Text
          fontSize="var(--ql-font-size-caption1)"
          color="var(--ql-color-accent1)">
          {t('Delete chat')}
        </Text>
      )
    }
  ]

  // [USE_EFFECTS]
  useEffect(() => {
    checkOnline(lastSeen, setStatusOnline, t)

    const timer = setInterval(
      () => checkOnline(lastSeen, setStatusOnline, t),
      180000
    )
    return () => clearInterval(timer)
  }, [lastSeen])

  return (
    <Dropdown
      disabled={chatCreatedBy !== state?.id && isGroup}
      menuItems={menu}
      placement="bottomLeft"
      trigger="contextMenu">
      <Box
        onClick={onClickChat}
        mb={2}
        size="small"
        shadowless
        style={{
          cursor: 'pointer',
          padding: '12px',
          borderRadius: 'var(--ql-border-radius-16)'
        }}
        bg={isCurrentChat ? 'var(--chat-active-bg)' : 'var(--chat-bg)'}>
        <Row noGutters>
          <Col mr={1} minWidth={0}>
            <Row noOuterGutters minWidth={0}>
              <Col cw="auto" pr={2}>
                <AvatarWithOnline
                  isGroup={isGroup}
                  isOnline={statusOnline === 'online'}
                  avatarURL={avatarURL}>
                  <Text color={'var(--avatar-color)'}>{name?.[0]}</Text>
                </AvatarWithOnline>
              </Col>
              <Col flexDirection="column" v="between" minWidth={0}>
                <Text
                  isEllipsis
                  variant="body2"
                  fontWeight="var(--ql-font-weight-medium)">
                  {isGroup ? name : displayName}
                </Text>
                <Text color="var(--message-color)" variant="body2" isEllipsis>
                  {text}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col cw="auto" h="right" v="between">
            <Text color="var(--message-info-color)" variant="caption1">
              {formattedTime}
            </Text>

            {!currentUser && chatParticipants?.[state?.id] > 0 && (
              <Badge size="small" count={chatParticipants?.[state?.id]} />
            )}
            {currentUser && (
              <ReadIcon isRead={isRead} size="var(--ql-line-height-body2)" />
            )}
          </Col>
        </Row>
      </Box>
    </Dropdown>
  )
}

ChatView.propTypes = {
  chatData: PropTypes.shape({
    avatarURL: PropTypes.string.isRequired,
    chatId: PropTypes.string.isRequired,
    countUnreadMessage: PropTypes.number,
    createdAt: PropTypes.number.isRequired,
    createdBy: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    name: PropTypes.string,
    messageId: PropTypes.string.isRequired,
    'participant-ids': PropTypes.shape({
      participantId: PropTypes.string.isRequired
    }),
    participantId: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    chatCreatedBy: PropTypes.shape.isRequired
  }).isRequired
}

export default React.memo(ChatView)
