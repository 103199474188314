import { Box, Col, Row, Text } from '@qonsoll/react-design'
import { Button } from 'antd'
import { useTranslations } from '~/app/contexts/Translation/hooks'

function WizardSameLocationDialog(props) {
  const { onYesClick, onNoClick } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  return (
    <Row noGutters v="center" h="center">
      <Col cw={12} mb={3}>
        <Box display="flex" flex={1} justifyContent="center">
          <Text>{t('Are you at the same location?')}</Text>
        </Box>
      </Col>
      <Col cw={12}>
        <Box display="flex" flex={1} justifyContent="center">
          <Col cw="auto">
            <Button type="primary" onClick={onYesClick}>
              {t('Yes')}
            </Button>
          </Col>
          <Col cw="auto">
            <Button onClick={onNoClick}>{t('No')}</Button>
          </Col>
        </Box>
      </Col>
    </Row>
  )
}

export default WizardSameLocationDialog
