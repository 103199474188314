import React, { useEffect, useState } from 'react'
import { Popover } from '@qonsoll/react-design'
import {
  Row,
  Col,
  Box,
  AppHeaderWrapper,
  Avatar,
  Button,
  Text
} from '@qonsoll/react-design'
import { useHistory, generatePath, useParams } from 'react-router-dom'
import { Dropdown } from 'app/components'
import {
  UserOutlined,
  ArrowLeftOutlined,
  PhoneOutlined,
  EllipsisOutlined
} from '@ant-design/icons'
import { ParticipantsList } from 'app/domains/Chat/components'
import {
  useMessengerContext,
  useGetCountParticipantsInChat
} from 'app/contexts/Messenger/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { checkOnline } from 'domains/Chat/helpers'
import { useUI } from 'app/contexts/UI/hooks'
import ROUTE_PATHS from 'domains/allRoutePath'
import { getReferenceChild } from '~/services/Firebase/database'
import { COLLECTIONS } from '~/app/constants'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { useUserContext } from 'app/contexts/User/hooks'

const IS_SHOW_BORDER = {
  sm: false,
  md: true,
  lg: true,
  xl: true,
  xxl: true
}

const ChatHeader = () => {
  // [ADDITIONAL_HOOKS]
  const { id } = useParams()
  const state = useUserContext()
  const { t } = useTranslations()
  const history = useHistory()
  const { messengerState } = useMessengerContext()
  const { countParticipants } = useGetCountParticipantsInChat(id)
  const UI = useUI()
  const isShowBorder = useResponsiveBreakpoints(IS_SHOW_BORDER)

  // [COMPUTED_PROPERTIES]
  const { chatsDetail } = messengerState

  const menuGroupChat = [
    {
      name: 'edit',
      onItemClick: () =>
        history.push(generatePath(ROUTE_PATHS.CHAT_GROUP_EDIT, { id })),
      Component: (
        <Text fontSize="var(--ql-font-size-caption1)">{t('Edit')}</Text>
      )
    }
  ]

  // [COMPONENT_STATE_HOOKS]
  const [currentChatDetail, setCurrentChatDetail] = useState()
  const [statusOnline, setStatusOnline] = useState()
  const [participantsData, setParticipantsData] = useState({})

  // [CLEAN_FUNCTIONS]
  const onChatInfoClick = () =>
    !currentChatDetail?.isGroup &&
    history.push(
      generatePath(ROUTE_PATHS.USER_SHOW, {
        id: currentChatDetail?.participantId
      })
    )
  const onBack = () => history.push(ROUTE_PATHS.CHATS_ALL)

  // [USE_EFFECTS]
  useEffect(() => {
    // setting current chat detail
    let isComponentMounted = true

    isComponentMounted && setCurrentChatDetail(chatsDetail[id])

    return () => {
      isComponentMounted = false
    }
  }, [chatsDetail, id])

  useEffect(() => {
    // checking online
    checkOnline(currentChatDetail?.lastSeen, setStatusOnline, t)

    const timer = setInterval(
      () => checkOnline(currentChatDetail?.lastSeen, setStatusOnline, t),
      180000
    )

    return () => clearInterval(timer)
  }, [currentChatDetail?.lastSeen])

  useEffect(() => {
    // setting participants data
    let isComponentMounted = true

    const getParticipantsData = () => {
      const data = {}
      const participantIds = Object.keys(currentChatDetail?.chatParticipants)

      const getParticipantData = async (participantId) => {
        await getReferenceChild(
          `${COLLECTIONS.CHATS}/${COLLECTIONS.PARTICIPANTS}/${participantId}`
        ).on('value', (snapshot) => {
          data[participantId] = snapshot.val()
          setParticipantsData({ ...data, [participantId]: snapshot.val() })
        })
      }

      participantIds.forEach((participantId) =>
        getParticipantData(participantId)
      )
    }

    isComponentMounted &&
      currentChatDetail?.chatParticipants &&
      getParticipantsData()

    return () => {
      isComponentMounted = false
    }
  }, [
    currentChatDetail?.chatId,
    currentChatDetail?.chatParticipants,
    state?.id
  ])

  return (
    <AppHeaderWrapper
      mt={UI.state?.safeAreaInsets?.top || (!isShowBorder && '-12px')}
      minHeight={63}
      maxHeight={63}>
      <Row
        m="-12px"
        v="center"
        minHeight="inherit"
        p={isShowBorder ? '12px' : 0}
        borderBottom={
          isShowBorder &&
          `var(--ql-border-width-default) var(--ql-border-style-default) var(--ql-border-color-default)`
        }>
        {!isShowBorder && (
          <Col cw="auto" pl={0}>
            <Button type="text" icon={<ArrowLeftOutlined />} onClick={onBack} />
          </Col>
        )}
        <Col cw="auto" pl={0} pr="16px">
          <Box
            style={{ cursor: !currentChatDetail?.isGroup && 'pointer' }}
            height="fit-content"
            border="var(--outline-width) var(--ql-border-style-default) var(--avatar-border-color)"
            borderRadius="var(--ql-border-radius-full)"
            onClick={onChatInfoClick}>
            <Avatar
              m="2px"
              icon={!currentChatDetail?.isGroup && <UserOutlined />}
              alt="avatar"
              src={currentChatDetail?.avatarURL}
              size={36}>
              <Text color="var(--avatar-color)">
                {currentChatDetail?.isGroup && currentChatDetail.name?.[0]}
              </Text>
            </Avatar>
          </Box>
        </Col>
        <Col flexDirection="column" minWidth={0} pl={0}>
          {currentChatDetail?.isGroup && participantsData ? (
            <Row noGutters minWidth={0}>
              <Col minWidth={0} flexDirection="column">
                <Text isEllipsis fontSize="var(--ql-font-size-h5)">
                  {currentChatDetail?.name}
                </Text>
                <Popover
                  trigger="click"
                  content={
                    <ParticipantsList
                      chatCreatedBy={currentChatDetail?.chatCreatedBy}
                      chatParticipants={currentChatDetail?.chatParticipants}
                      participantsData={Object.values(participantsData)}
                    />
                  }>
                  <Text
                    width="fit-content"
                    cursor="pointer"
                    color="var(--ql-typography-text-color-secondary)"
                    fontSize="var(--ql-font-size-caption1)">
                    {countParticipants} {t('participants')}
                  </Text>
                </Popover>
              </Col>
            </Row>
          ) : (
            <>
              <Row noGutters>
                <Col minWidth={0}>
                  <Text isEllipsis fontSize="var(--ql-font-size-h5)">
                    {currentChatDetail?.displayName}
                  </Text>
                </Col>
              </Row>
              <Row noGutters>
                <Col cw="auto">
                  <Text
                    color="var(--ql-typography-text-color-secondary)"
                    fontSize="var(--ql-font-size-caption1)">
                    {statusOnline}
                  </Text>
                </Col>
              </Row>
            </>
          )}
        </Col>
        <Col cw="auto" ml="auto" pr={0}>
          {currentChatDetail?.isGroup && (
            <Dropdown color="red" trigger="click" menuItems={menuGroupChat}>
              <Button type="text" icon={<EllipsisOutlined />} />
            </Dropdown>
          )}
          {!currentChatDetail?.isGroup &&
            currentChatDetail?.chatCreatedBy === state?.id && (
              <Button
                type="text"
                href={`tel:${currentChatDetail?.phone}`}
                icon={<PhoneOutlined />}
              />
            )}
        </Col>
      </Row>
    </AppHeaderWrapper>
  )
}

export default ChatHeader
