import { COLLECTIONS } from 'app/constants'
import { getReferenceChild } from '~/services/Firebase/database'
import { updateDocument } from '~/services/Firebase/firestore'

const createParticipantData = async (
  userData,
  chatId,
  isGroup,
  participantId
) => {
  const participantData = {
    phone: userData.phone,
    avatarURL: userData?.avatarURL || '',
    displayName: `${userData.firstName} ${userData.secondName}`,
    userId: userData?.id,
    [COLLECTIONS.CHATS_BY_PARTICIPANTS]: isGroup
      ? null
      : {
          [participantId]: chatId
        },
    [COLLECTIONS.GROUP_CHAT_IDS]: isGroup ? { [chatId]: true } : null
  }

  await getReferenceChild(
    `${COLLECTIONS.CHATS}/${COLLECTIONS.PARTICIPANTS}/${userData?.id}`
  ).update(participantData)

  await updateDocument(COLLECTIONS.USERS, userData?.id, {
    isParticipant: true
  })
}

export default createParticipantData
