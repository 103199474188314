import React, { useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  PageWrapper,
  Text,
  Title,
  Button
} from '@qonsoll/react-design'
import { PlusOutlined } from '@ant-design/icons'
import { PageLoading } from '~/app/components'
import { ChatsList } from '~/app/domains/Chat/components'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useMessengerContext } from '~/app/contexts/Messenger/hooks'
import { useHistory } from 'react-router-dom'
import ROUTE_PATHS from '~/app/domains/allRoutePath'
import {
  useBackButton,
  useResponsiveBreakpoints
} from '~/app/contexts/UI/hooks'
import { TYPES } from '~/app/contexts/Messenger/constants'
import logLastActivity from '../../helpers/logLastActivity'
import { useUserContext } from 'app/contexts/User/hooks'

function ChatsAll() {
  // [ADDITIONAL_HOOKS]
  const history = useHistory()
  const { t } = useTranslations()
  const state = useUserContext()
  const screen = useResponsiveBreakpoints({
    sm: true,
    md: true,
    lg: false,
    xl: false,
    xxl: false
  })
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  useBackButton({
    showBackButton: screen
  })
  const { messengerDispatch, messengerState, messengerLoading } =
    useMessengerContext()
  const { chatsDetail } = messengerState
  const showChatList = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })

  // [CLEAN_FUNCTIONS]
  const onCreateChatClick = () => history.push(ROUTE_PATHS.CHAT_CREATE)

  // [USE_EFFECTS]
  useEffect(() => {
    let isComponentMounted = true

    isComponentMounted &&
      messengerDispatch({ type: TYPES.SET_CURRENT_CHAT_ID, payload: false })

    isComponentMounted && logLastActivity(state?.id)

    return () => {
      isComponentMounted = false
    }
  }, [messengerDispatch])

  return (
    <PageLoading loading={messengerLoading} height="inherit">
      <PageWrapper isBottomSticky firstLevelHidden>
        <Container height="inherit" display="grid">
          <Row noOuterGutters minWidth={0}>
            <Col cw={[12, 12, 6, 5, 5]} flexDirection="column" minWidth={0}>
              <Row noGutters h="between" marginBottom="24px">
                <Col v="center">
                  <Title level={titleSizes} style={{ marginBottom: 0 }}>
                    {t('Chats')}
                  </Title>
                </Col>
                <Col cw="auto">
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={onCreateChatClick}
                  />
                </Col>
              </Row>
              <ChatsList />
            </Col>
            {showChatList && (
              <Col
                h="center"
                v="center"
                py={2}
                borderRadius="var(--ql-border-radius-16)"
                bg={showChatList && 'var(--chat-bg)'}>
                <Text
                  color="var(--text-color-secondary)"
                  fontSize="var(--ql-font-size-h5)">
                  {Object.values(chatsDetail)?.length
                    ? t('Select a chat')
                    : t('You don`t have any chats')}
                </Text>
              </Col>
            )}
          </Row>
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default ChatsAll
