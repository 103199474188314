import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import { BuyersListWithSelect } from '~/app/domains/Buyer/components'

const ChatFormCreate = (props) => {
  const { buyersData, setCountSelectedParticipant, ...rest } = props

  return (
    <Form
      style={{
        minWidth: 0,
        flex: '1 1 auto',
        overflowY: 'auto'
      }}
      {...rest}>
      <Form.Item
        name="selectedParticipantIds"
        style={{
          minWidth: 0,
          height: 0,
          marginBottom: 0
        }}>
        <BuyersListWithSelect
          buyersData={buyersData}
          setCountSelectedParticipant={setCountSelectedParticipant}
        />
      </Form.Item>
    </Form>
  )
}

ChatFormCreate.propTypes = {
  onFinish: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  buyersData: PropTypes.object.isRequired,
  setCountSelectedParticipant: PropTypes.func.isRequired
}

export default ChatFormCreate
