import { createDocument, getTimestamp } from 'services/Firebase/firestore'
import { COLLECTIONS } from '~/app/constants'

const generateAgreementDocument = async (
  creatorId,
  signers,
  agreementName,
  agreementURL,
  availableDays = 7
) => {
  const AGREEMENT_STATUS = 'TO_SIGN'
  const CREATION_DATE = getTimestamp().fromDate(new Date())

  const document = await createDocument(
    COLLECTIONS.DOCUMENTS,
    {
      availableDays,
      cancellationUrl: null,
      createdAt: CREATION_DATE,
      deleteUrl: null,
      description: 'Please sign it',
      errorReason: null,
      fileName: agreementName,
      nextPermittedPollTime: null,
      pAdESUrl: null,
      reference: null,
      signatureJobId: null,
      signerStatuses: null,
      signers,
      sourceUrl: agreementURL,
      status: AGREEMENT_STATUS,
      statusChangedAt: null,
      statusPolledAt: null,
      userId: creatorId,
      type: 'PORTAL'
    },
    { withoutUndef: false }
  )
  return document
}

export default generateAgreementDocument
