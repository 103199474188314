import { COLLECTIONS } from '~/app/constants'
import { getReferenceChild } from 'services/Firebase/database'

const addGroupIdToParticipantData = async (participantId, chatId) => {
  await getReferenceChild(
    `${COLLECTIONS.CHATS}/${COLLECTIONS.PARTICIPANTS}/${participantId}/${COLLECTIONS.GROUP_CHAT_IDS}`
  ).update({ [chatId]: true })
}

export default addGroupIdToParticipantData
